.LogThought {

    box-sizing: border-box;

    font-size: 12.5pt;
    font-weight: 500;
    padding: 1em 10px 1.25em 24px;

    text-align: left;
    //text-align: center;
    line-height: 1.4em;

    color: darken(#0ea5e4, 2%);
    //color: #222;
    //color: #135ec4;
    border-radius: 12px;
    margin: 12px 12px 4px 0;

    background-color: lighten(#d1eff7, 10%);
    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

    transition: 0.25s;

    .thought-line {
        display: flex;
    }
    .thought {
        padding: 0 0 4px 0;
        color: darken(#878bec, 10%);
        font-size: 11.0pt;
    }
    .belief-rating {
        font-size: 10pt;
        //margin-left: auto;
        padding-left: 6px;
        padding-right: 16px;
        color: darken(#878bec, 10%);
        color: darken(#60e19a, 20%);
        opacity: 0.8;
    }

    .response {
        display: flex;
        display: flex;
        align-items: flex-start;
        padding-right: 10px;

        .checkmark {
            width: 10px;
            //align-self: center;

            margin: 6px 6px 0 0;
        }
        .label {
            //float: left;
            font-size: 10.0pt;

            padding: 0.2em 0px;
            padding-left: 0px;
            padding-top: 0;
            text-align: left;

            line-height: 1.5em;
            font-weight: 400;

            background: #63ba8c;
            background: linear-gradient(to right, #13ad6e 0%, #10c77b 50%, #13ad6e 100%);
            background: linear-gradient(to right, #0ea5e4 0%, #007bc2 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .challenge-button {
        display: none;
        margin-right: 8px;
        margin-top: 4px;
        margin-bottom: 12px;
        background-color: darken(#878bec, 5%);
        color: #fff;
        font-size: 10.5pt;
        font-weight: 600;
        padding: 0.5em;
        text-align: center;
        border-radius: 40px;
    }
}

.LogThought:hover {
    transform: scale(1.03);
    cursor: pointer;
    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 5%);
    .challenge-button {
        display: block;
    }
}

.LogThought.IsNew {
    animation: .3s 4 alternate flash;
}

@keyframes flash {
    from {
        background-color: lighten(#d1eff7, 10%);
    }
  
    to {
        background-color: lighten(#faf6dd, 4%);
    }
  }
@import './components/global';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.sun-img {
  position: fixed;
  top: 101px;
  left: 322px;
  width: 38px;
  opacity: 0;
}

html {}

body {
  // background-color: lighten(#d1eff7, 7%);
  // background-color: lighten(#e3e6f7, 4%);
  // background-color: $backgroundColor;
  //height: 98vh;
  //background-color: $backgroundColor !important;
  //background: linear-gradient(0deg, darken($creamColor, 1%) 0%, darken($creamColor, 2%) 50%, darken($creamColor, 0%)100%) no-repeat;
  background-color: darken($creamColor, 0.0%);
  background-color: lighten($creamColor, 9.5%);
  //background-color: #fff;
  //background-color: #fff;
  //background: url('../public/mountains.jpg');
  background-size: cover;
  //background-color: darken($creamColor, 3%) !important;
  //background-color: lighten(#fbf1ff, 1%) !important;

  //background-color: darken(#fefaf4, 2%);
  // background-color: #fafafa;
  // background-color: #f7f4ef;
  //background-color: #fffcf9;

  font-family: "Manrope", ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  //font-family: "Manrope";
  //font-family: "Mulish";
}

.logo-dimmed {
  position: fixed;
  top: 16px;
  //right: 52px;
  width: 31px;
  //opacity: .7;

  left: 18px;
  z-index: 5000;
}

.account-bubble {
  position: fixed;
  bottom: 20px;
  //right: 18px;
  left: 21.5px;
  z-index: 5000;

  width: 28px;
  height: 28px;
  border-radius: 100px;

  background-color: darken($creamColor, 80%);
  background-color: darken(desaturate(#a2ebf4, 24%), 4%);
  //box-shadow: 1px 1px 2px 1px rgba(#333, .05);

  transform: scale(1.175);

  .account-img {
    width: 15px;
    margin: 5.5px 0 0 0;
    opacity: 1;
  }
}

.Mast {
  background-color: lighten(#f7ece5, 6%);
  background-color: transparent;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  border-bottom: 2px solid darken(#d1eff7, 2%);

  //background-color: lighten(#ecddff, 5%);
  border-bottom: 0px solid darken(#f7ece5, 0%);

  text-align: left;

  //opacity: 0;
}


.sidebar {

  position: fixed;
  width: $sidebarWidth;
  height: 100%;
  display: flex;
  //display: none;
  flex-direction: column;
  top: 61px;
  top: 0;
  left: 0;
  //opacity: 0;
  background-color: lighten(#d9ebff, 10%);
  background-color: lighten(#fdf4f0, 1.5%);
  background-color: darken(#fffcf7, 0%);
  background-color: lighten($dropBackgroundColor, 30%);
  background-color: #fff;
  //background-color: darken($dropBackgroundColor, 13%);
  //border-right: 2.5px solid darken($dropBackgroundColor, 6%);
  //border-right: 0;
  //border-right: 4px solid #fff;
  //background-color: lighten(#e8f5f7, 2%);
  //border-right: 2px solid #eee;
  //border-right: 2px solid darken($dropBackgroundColor, 4%);
  //border-right: 3.5px solid lighten(#e1f1f4, 0%);
  //border-right: 1.5px solid darken(#fffcf7, 8%);
  //background-color: #fff;
  //background-color: #fff;
  //background-color: #fff;

  //box-shadow: 1px 2px 6px 1px darken(#d9ebff, 0%);
  //box-shadow: 1px 2px 4px 2px lighten(#d9ebff, 2%);
  // box-shadow: none;
  //border-right: 2px solid lighten(#d9ebff, 2%);
  //border-right: 4px solid #fff;

  z-index: 4001;

  //transform: scale(1.025);
  //zoom: 1.05;
  transform-origin: top left;

  border-right: 2px solid #fbe5b4;


  .logo {
    //background-color: #fff;
    margin-left: 0;
    text-align: left;
    padding-left: 12px;


  }
}

.logo {
  padding: 12px 24px;
  font-size: 12.5pt;
  font-weight: 500;
  color: #ea911c;

  margin-left: -12px;
  opacity: 0;

  //text-transform: uppercase;
  //letter-spacing: 1px;

  .gpt {
    color: #e06b14;
    font-weight: 700;
  }

  .logo-img {
    width: 58px;
    width: 96px;
    margin-top: 0px;
  }
}

.slam-floating-container {
  //opacity: 0;
  position: fixed;
  //bottom: 430px;
  // top: 88px;
  // top: 80px;
  // right: 16px;
  //width: 360px;
  //height: 60px;
  //background-color: #fff;
  //box-shadow: 1px 13px 3px 1px darken(#e3e6f7, 20%);

  border-radius: 100px;
  border-radius: 18px;
  zoom: 0.82;

  bottom: 22px;
  //top: 88px;
  //top: 80px;
  left: 22px;
  //box-shadow: none;
  z-index: 10;
}

.FinishingCard {
  position: fixed;
  width: 300px;
  height: 96px;

  // bottom: 488px;
  // left: 816px;
  // left: 796px;
  //left: 36px;
  z-index: -1;

  background-color: #fff;
  background-color: lighten(#d9ebff, 6%);
  border-radius: 28px;
  //border-radius: 0 28px 28px 0;



  padding-left: 20px;
  transition: all 0.4s;

  zoom: 0.9;
  transform: scale(0.85);
}

.good-phrase span span {
  position: relative;
  opacity: 0;
  animation: move-text 0.1s forwards;
}

@keyframes move-text {
  0% {
    left: 0.2em;
    bottom: -0.1em;
    opacity: 0;
  }

  50% {
    left: 0.1em;
    bottom: -0.1em;
    opacity: 0.5;
  }

  100% {
    left: 0;
    bottom: -0em;
    opacity: 1;
  }
}

.FinishingCard.IsAttached {
  padding-left: 60px !important;
  transform: scale(0.95) !important;
  box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
}


.ToastContent {

  .content {
    display: flex;
    padding: 8px 8px;

    align-items: center;
    transition: all;
  }

  .content-starting {
    padding-top: 8px;

    .target-starting {
      font-size: 13pt;
    }

    .spinner {
      margin-left: auto;
      margin-right: 12px;
      opacity: 0.5;
    }
  }

  .wrote-phrase {
    font-size: 11pt !important;
  }

  .good-phrase {
    font-size: 14.5pt;
    //font-family: 'Solitreo';
  }

  .julia-img {
    margin-top: -32px;
    margin-left: -240px;
    padding-bottom: 18px;
    width: 36px;
    text-align: left;
  }

  .julia-heading {
    margin-top: -30px;
    padding-bottom: 14px;
    text-align: left;
    padding-left: 10px;

    font-size: 11.5pt;
    font-weight: 700;
    color: #111;
    color: darken(#0ea5e4, 4%);
    opacity: 0;
  }

  .illustration-container {
    position: relative;
    width: 80px;
    max-width: 80px;
    min-width: 80px;

    .notebook-img {
      position: absolute;
      top: 10px;
      left: 0px;
      width: 52px;
      z-index: 10;

      width: 72px;
      left: -10px;
    }
  }

  .animation-container {
    position: relative;
    top: 20px;
    left: 14px;
    z-index: 20;

    animation-duration: 2.6s;
    animation-name: slideHand;
    animation-iteration-count: 5;
    animation-direction: normal;
    animation-timing-function: linear;

    padding-top: 6px;
    padding-right: 12px;

    .illustration-img {
      width: 46px;
      align-self: center;

      animation-duration: 0.2s;
      animation-name: wiggle;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear;
    }
  }

  .illustration {
    padding-top: 18px;
    animation-duration: 0.26s;
    animation-name: wiggleThinking;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;

    .illustration-img {
      width: 58px;
    }
  }

  padding-top: 0px;

  .content-writing {
    .illustration {
      padding-top: 18px;

      .illustration-img {
        width: 52px;
      }
    }

    padding-top: 0px;
  }

  .target {
    //width: 30%;
    padding-right: 12px;
    padding-left: 0px;
    padding-top: 18px;
    font-size: 12pt;
    font-weight: 600;
    color: darken(#0ea5e4, 1%);
    //color: #fff;
    flex-grow: 0;
    //color: lighten(#fd6f53, 14%);
  }

  .message {
    font-size: 10.5pt;
    font-weight: 400;
    text-align: left;

    .image-phase {
      height: 14px;
      align-self: center;
    }

    .level-phase {
      font-weight: 600;
      color: #111;
    }

    .Impact-phase {
      font-weight: 600;
      color: darken(#ff9f2e, 5%);
    }

    .Growth-phase {
      font-weight: 600;
      color: darken(#13c828, 5%);
    }

    .Joy-phase {
      font-weight: 600;
      color: darken(#cb4ce5, 2%);
    }
  }
}

.JuliaThinking {
  zoom: 0.9;

  .content {
    padding: 0 0 4px 12px;
  }

  .target {
    padding-top: 8px;
    padding-right: 20px;
  }

  .illustration-img {
    width: 26px !important;
    padding-right: 22px;
  }

  .spinner {
    margin-right: 0;
    margin-top: 12px;
    zoom: 0.7;
  }
}

.Wizard-placeholder {
  width: 100%;
  height: 0px;
  //background: #111;
  //display: none;
}

.WizardContent {
  padding: 16px;
  width: 244px;
  min-height: 200px;

  .intro {
    font-size: 11.75pt;
    font-weight: 500;
    line-height: 1.25em;
    color: #333;
    padding: 0 8px;

    p {
      padding: 0 0 0.5em 0;
    }

    em {
      font-style: normal;
      font-weight: 700;
      color: darken(#049ceb, 0.5%);
      color: darken($voiceColor, 1.5%) !important;
    }

    u {
      text-decoration: underline;
    }

    i {
      color: darken(#f6c47c, 16%);
      //font-size: 11.5pt;
      font-style: normal;
    }

    em.responseWord {
      color: $themeYellowMediumFontColor !important;
    }

    em.voiceWord {
      color: $voiceColor !important;
    }

    em.reinforceWord {
      color: $positiveAccentColor !important;
    }

    em.negativeWord {
      color: $negativeColor !important;
    }

    em.plainWord {
      color: #333 !important;
    }

  }

  .next-button {
    display: inline-block;
    background: $positiveAccentColor;
    margin-top: 12px;
    border-radius: 4px;
    padding: 0.75em 1.25em;
    font-size: 11.5pt;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
  }
}


.ai-face {
  z-index: 100;
  position: relative;
  transform: scale(.3);
  transform-origin: top left;
  top: -18px;
  left: -14px;
  height: 48px;
  //zoom: .5;
}

.face-top-img {
  position: relative;
  top: 81px;
  left: 62.5px;
  //animation: welcomeWiggle 2.5s ease-in-out infinite;

  width: 122px;


  animation-name: welcomeHighFloating;
  animation-delay: .0s;
  animation-duration: 2.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  z-index: 1000;
}

.face-bottom-img {
  position: relative;
  width: 122px;
  top: 88px;
  left: -58px;
  animation: welcomeWiggle 2.3s ease-in-out infinite;
  animation-delay: 2s;
  z-index: 1000;
}

.FadeIn {
  animation: contentFadeIn 0.6s ease 0s 1;
}

.FadeInDelay {
  animation-name: contentFadeIn;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-iteration-count: 1;
}

.FadeOut {
  transition: 0.5s all;
}

.FadingOut {
  .FadeOut {
    opacity: 0;
  }
}

@keyframes contentFadeIn {
  0% {
    //margin-top: 60px;
    opacity: 0;


  }

  100% {
    //margin-top: 10px;
    opacity: 1;
  }
}

.popover-arrow {
  //box-shadow: 1px 1px 4px 1px rgba(230, 219, 203, 0.7);
}
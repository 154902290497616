@import 'global';

$goalColor: lighten(#16b0ff, 4%);
$goalColor: darken($voiceColor, 2.5%);
//$goalColor: $positiveAccentColor;
//$goalColor: #58d1be;
//$goalColor: $voiceColor;

.AreaFilters,
.YourTherapy {

    margin-top: 69px;
    margin-top: 61px;
    margin-top: 1.5px;
    //margin-top: 59px;

    transform: scale(.95);
    transform-origin: top left;
    opacity: 1;

    .areas {
        padding-top: 3px;
        padding-right: 4px;
        //margin-left: -4px;

        opacity: 1;
    }

    .areas-subheading {
        padding: 3px 0 6px 22px;
        font-size: 8.5pt;
        //font-family: 'Merriweather';
        font-weight: 700;
        letter-spacing: 1.75px;
        text-transform: uppercase;
        color: darken($positiveAccentColor, 0%);
        color: lighten($positiveAccentColor, 8%);
        //color: darken($backgroundColor, 20%);
        //color: $negativeColor;
        //color: darken(#deeeff, 14%);
        text-align: left;

        //opacity: .75;
    }

    .category-heading {
        background-color: lighten(#d9f9e7, 2%);
        background-color: #ff8e42;
        background-color: darken(#ffba42, 13%);
        background-color: lighten($goalColor, 4%);
        background-color: transparent;
        //background-color: #fff;

        margin-top: 14px;
        // //margin-top: 8.5px;
        // color: darken(#ee9b69, 10%);
        // color: lighten($goalColor, 2.5%) !important;
        // color: lightsalmon !important;
        // font-size: 11.5pt;

        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 9.25pt;
        //color: $voiceColor !important;
        //background-color: $voiceColor;
        //background-color: darken(#58d174, 0%);
        //background-color: transparent;
        opacity: 0;
        //opacity: 0;
        //zoom: 1;

        // background-color: #fff;
        color: darken(lightsalmon, 0%) !important;
        //color: $positiveAccentColor !important;
        //color: lighten(#d15891, 15%) !important;
        opacity: 1;
        box-shadow: none;
    }

    .stage-head {

        width: 194px;
        padding: 17.5px 24px;

        margin: 6px 2px;
        background-color: #fff;
        background-color: lighten($backgroundColor, 2%);
        //background-color: darken(#fffdfa, 0.5%);
        //background-color: #fff;
        background-color: transparent;

        border-radius: 12px;
        //border-bottom: 2px solid darken($backgroundColor, 4%);

        cursor: pointer;
    }

    .stage-head:hover {
        background-color: lighten($voiceColor, 54.5%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

        .stage-title {
            //font-weight: 700;
            text-decoration: underline;
            text-decoration-color: lighten($voiceColor, 46%);
            text-decoration-thickness: 2px;

        }
    }

    .stage-title {
        padding: 0px 0 0 8px;
        //font-family: 'Merriweather';
        font-weight: 500;
        font-size: 11.5pt;
        font-size: 11.0pt;
        font-size: 10.5pt;
        font-weight: 600;
        line-height: 1.40em;
        color: $negativeColor;
        color: $positiveAccentColor;
        color: lighten($goalColor, 2%);
        color: lighten(lightsalmon, 0%);
        color: $positiveAccentColor;
        opacity: 1;
        //color: lighten($neutralColor, 18%);
        //color: $voiceColor;
        font-family: $serifFamily;
        font-weight: 400 !important;
        //color: #40b8e9;
        text-align: left;
    }

    .icon-img {
        width: 15px;
        margin: 3px 3px 1px 0px;
        align-self: center;
    }

    .progress-number {
        margin-left: auto;

        padding-right: 4px;
        font-size: 10.25pt;
        font-size: 10.75pt;
        font-weight: 500;
        //color: lighten(#16c797, 3%);
        color: darken($backgroundColor, 18%);
        //color: $positiveAccentColor;
        //color: lighten(#ebab00, 8%);

        text-align: left;

        letter-spacing: 0.5px;

        opacity: 0;

        .outof-phrase {
            display: none;
            color: lighten($positiveAccentColor, 35%);
        }

        //opacity: 0 !important;
    }

    .stats {
        display: flex;
        padding: 3.5px 4px 0 4px;

        .explore-count {
            display: flex;
            // min-width: 100px;
            // max-width: 100px;
            opacity: 0;
        }

        .goal-grade {
            display: flex;
            margin: 0 0 0 6px;
            // min-width: 100px;
            // max-width: 100px;
            margin-left: auto;
        }

        .number {
            background-color: lighten($neutralColor, 54%);
            color: darken($neutralColor, 10%);
            border-radius: 100px;
            padding: 2.5px 8px 1px 8px;
            font-size: 8.5pt;
        }

        .label {
            padding: 2px 0 3px 0px;
            font-size: 9pt;
            font-weight: 600;
            color: darken(lightsalmon, 8%);
            color: lighten($neutralColor, 0%);
            opacity: .4;
        }

        .label-low {
            color: darken(lightsalmon, 8%);
            opacity: .55;
        }

        .score {
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 100px;
            padding: 2px 8px;
            font-size: 9.0pt;
            font-weight: 600;
        }
    }

    .stage-progress {
        margin: 2px 28px 0px 32px;
    }

    .stage-progressbar {
        width: 87%;
        height: 7.5px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 35%);
        background-color: lighten($positiveAccentColor, 39%);

        margin-left: 22px;
    }

    .stage-fillbar {
        width: 60%;
        height: 7.5px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 20%);
        background-color: lighten($positiveAccentColor, 24%);
        transition: .5s;
    }

    .effort-head {
        .stage-title {
            color: lighten($reinforceColor, 10%);
            font-size: 9.5pt;
            font-weight: 600;
            font-family: "Manrope", ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
        }
    }

    .effort-progress {
        .stage-progressbar {
            //height: 4px;
            background-color: lighten($reinforceColor, 43%);
        }

        .stage-fillbar {
            width: 42%;
            //height: 4px;
            background-color: lighten($reinforceColor, 30%);
        }
    }

    .effort-head {
        padding-top: 10px;


        .progress-number {
            color: lighten($reinforceColor, 24%);
        }
    }

    .IsFirstMinor .waiting-head {
        margin-top: 2px;
        // margin-top: 8px !important;
        // border-top: 1.5px solid lighten($backgroundColor, 0%);
    }

    .waiting-head {
        //border-bottom: 1.5px solid #fdf4f0;
        margin: 0 28px 0 31px;
        margin-top: -2px;
        padding: 8px 0 0px 1px !important;

        .icon-img {
            width: 14px !important;
            //margin-left: 4px !important;
        }

        .stage-title {
            padding-left: 4px !important;
            font-size: 10pt !important;
            font-family: $sansFamily;
            font-weight: 600;
            opacity: 0.7;
        }

        .progress-number {
            padding-top: 1px !important;
            font-size: 9pt !important;
            opacity: 0.7;
        }
    }

    .waiting-progress {
        margin-top: 0px;
        margin-bottom: 2px;

        .stage-progressbar {
            height: 4.5px;
            background-color: darken($backgroundColor, 1%);
        }

        .stage-fillbar {
            width: 42%;
            height: 4.5px;
            background-color: darken($backgroundColor, 8%);
        }
    }

    .waiting-head-first {
        margin-top: 8px !important;
    }


}

.stage-progress {
    .stage-progressbar {
        //background-color: #fff !important;
    }
}

.waiting-progress {
    .stage-fillbar {
        //opacity: 0.5 !important;
    }

    .stage-progressbar {
        //background-color: #fff !important;
    }
}

.area-item.IsSelected {
    .stage-head {
        //opacity: .7;
        background-color: #fff !important;
        //background-color: lighten($voiceColor, 54.5%) !important;
        //background-color: transparent !important;
        cursor: default !important;
        //box-shadow: none !important;
        box-shadow: .75px .75px .5px .5px darken($backgroundColor, 3.5%);

        .stage-title {
            color: lighten($neutralColor, 8%);
            color: darken(lightsalmon, 8.5%);
            color: $positiveAccentColor;
            //font-weight: 700 !important;
            text-decoration: underline;
            text-decoration-color: lighten(lightsalmon, 31%);
            text-decoration-thickness: 2px;
            opacity: 1;
        }
    }
}
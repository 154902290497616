@import 'global';

$noteHeight: 142px; // was 142, 140, then 138, then 130. very hard

$cardFontSize: 10.75pt;
$cardFontSize: 11.25pt;

.category-content {
    display: flex;
}

.category-heading {
    //opacity: 0 !important;
    float: left;
    margin: 12px 0 0 0px;
    //margin: 12px auto;
    padding: 0.7em 0em;
    background-color: #eee;

    font-size: 12pt !important;
    font-weight: 800;
    border-radius: 100px;
    margin-left: -0px !important;

    background-color: #e2e3ff;
    color: lighten(#4447A1, 10%);

    background-color: lighten(#d7f3ff, 2%);
    color: darken(#29b0e7, 10%);

    background-color: darken(#29b0e7, 0%);
    color: #fff !important;
    zoom: 0.95;

    opacity: 0 !important;

    //box-shadow: .75px .75px .5px .5px darken($backgroundColor, 3.5%);


    // background-color: transparent !important;
    // padding-left: 14px !important;
    // font-size: 12pt !important;
    // padding-bottom: 4px !important;

    // background-color: lighten(#f5ebd1, 7%);
    // color: darken(#f2c353, 20%);
    //color: #fff;
}

.SuggestionsSheet {
    height: $noteHeight + 6px; // was 12px

    margin-top: 0px;

    padding-bottom: 0px;

    padding-left: 8px;

    //transform: scale(.975);

    .category-work-img {
        margin: 17.5px 12px 0 auto;
        width: 26px;
        align-self: center;
    }

    .options-loading-placeholder {
        min-width: 728px;
        max-width: 728px;
        height: 100px;
    }

    .options {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        //width: 1000px;

        padding-bottom: 24px;
        //padding-right: 100px;

        zoom: 0.95;
        zoom: 1.00;
        //zoom: .9;
    }

    .toolbar {
        display: flex;
        height: 40px;
        margin-top: -16px;
        zoom: 0.85;
        opacity: 1;
        opacity: 0;

        .empty-space {
            width: 25%;
            min-width: 25%;
            display: none;
        }


        .refresh-container2 {
            //margin-left: auto;
            //flex: 1;
            //background-color: red;
            margin-left: auto;
            margin-left: 6px;
            margin-right: 14px;
        }

        .RefreshButton {
            width: 54px;
            height: 54px;
            background-color: #fff;
            border-radius: 100px;
            transition: .2s;

            .refresh-icon-img {
                width: 24px;
                margin-top: 15px;
            }
        }

        .RefreshButton:hover {
            cursor: pointer !important;
            transform: scale(1.4);
            box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        }
    }

}

.SuggestionsSheet.Work {
    .category-heading {
        opacity: 1;
        // background-color: lighten(#d9f9e7, 2%);
        // background-color: darken(#33d8e2, 0%);
        // color: darken(#3bcb7b, 10%);
        background-color: darken(#29a6e7, 6%);
        background-color: darken(#58d174, 8%);
        //background-color: darken(#f09d59, 6%);
        //background-color: darken($negativeColor, 30%);
        background-color: darken(lightsalmon, 8%);

        //background-color: $voiceColor;
        //background-color: lighten($voiceColor, 2%);
        //background-color: lighten(#29a6e7, 10%);
        ;
        background-color: lighten($backgroundColor, 20%);
        color: #fff !important;

        color: lighten($positiveAccentColor, 0%) !important;
    }
}

.SuggestionsSheet.Personal {
    .category-heading {
        opacity: 1;
        background-color: lighten(#d9f9e7, 2%);
        background-color: lighten(#d15891, 17%);
        background-color: darken(lightsalmon, 6%);
        background-color: darken(#4fe1d8, 10%);


        //background-color: lighten($neutralColor, 6%);
        //background-color: $positiveAccentColor;
        //background-color: lighten(#ea9371, 4%);
        //background-color: darken(#23bba0, 0%);
        color: darken(#3bcb7b, 10%);

        background-color: #fff;

        color: darken(#3bcb7b, 0%) !important;
        color: lighten(#d15891, 15%) !important;
        //color: lighten($voiceColor, 1%) !important;
    }
}

$noteScale: 1.055;
$noteRotate: .75deg;

.SuggestionOption.Option1 {
    transform: scale($noteScale) rotate(-.25deg);
}

.SuggestionOption.Option2 {
    transform: scale($noteScale) rotate(0deg);
}

.SuggestionOption.Option3 {
    transform: scale($noteScale) rotate(.25deg);
}

.SuggestionOption.Option4 {
    transform: scale($noteScale) rotate(0deg);
}



.SuggestionLabel,
.SuggestionOption {

    width: 18%;
    height: $noteHeight + 10px;
    margin: 10px 6.5px -5px 0;
    //height: 118.5px;
    //height: 92px;
    box-sizing: border-box;

    padding: 1.1em 20px 1.1em 24px;
    padding-top: 0.95em;
    padding-top: .05em;
    padding-top: 22px;

    text-align: left;
    //text-align: center;
    line-height: 1.45em;

    color: darken(#0ea5e4, 1%);
    color: darken(#0ea5e4, 4%);
    color: #0ea2df;
    color: #04a8eb;
    //color: #222;
    //color: #135ec4;
    border-radius: 12px;
    border-radius: 2px;



    background-color: lighten(#d1eff7, 7%); // Was 10% for long time
    //background-color: #fff !important;
    box-shadow: 0.5px 1.5px 2px 0px rgba(darken(#c9b2d8, 8%), .35);
    //background-color: #fff;
    //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

    background-color: transparent !important;
    box-shadow: none !important;
    //background-image: url('../public/sticky-note.png');
    background-size: 176px;
    background-position: 0px -0px;
    background-repeat: no-repeat;
    transform: scale($noteScale);

    transition: 0.3s;
    transition: 0.175s;

    //background-color: #fff !importa;

    //zoom: 0.95;

    //background-color: transparent;

    .label {

        //zoom: 0.975;

        //opacity: .92;

        // Was 12.5pt
        font-size: 12.5pt;
        font-size: 12.25pt;
        font-size: 11.25pt;
        //font-size: 12pt;
        //font-size: 12.25pt;
        //font-size: 13pt;
        padding: 1.75em 60px;
        padding: 1.75em 50px;
        padding: 1.75em 30px;
        padding: 1.5em 20px 1.75em 20px;
        padding-right: 0px;

        padding: 0;

        line-height: 1.36em;
        box-sizing: border-box;

        //font-family: 'Merriweather';
        //color: #777;

        transition: .25s;

        text-align: left;
        //text-indent: -18px;
        //padding-left: 38px;
    }


    .label-content {
        // text-align: center;
        // vertical-align: middle;

        // display: flex;
        // justify-content: center;
        // /* Align horizontal */
        // align-items: center;
        // /* Align vertical */

        // height: 38px;

        //text-decoration: underline;
        text-decoration-thickness: 1.5px;
        //text-decoration-color: lighten($neutralColor, 60%);
        transition: .7s;
    }

    .original-label {
        padding-top: 8px;
        font-size: 11.0pt;
        text-decoration: line-through;
        color: $negativeColor;
        opacity: 0.75;
        line-height: 1.45em;

        opacity: 0;
    }


    .thought-checks {
        //zoom: 0.675 !important;

        //margin-top: auto;
        margin-top: 10px;
        //margin-top: auto;
        //margin-top: 22px;
        //margin-left: auto;

        height: 30px;
        //background-color: #ddd;

        display: flex;

        transition: .5s;

        .thought-check {
            width: 16px;
            height: 16px;
            border: 2px solid darken(#ffc45e, 0%);

            //background-color: #e75e47;

            opacity: 0.6;
            border-radius: 100px;
            margin: 0 8px 0 0;

            opacity: 0 !important;

            .checkmark-img {
                opacity: 0;
                width: 10px;
            }
        }

        .thought-check-big {
            transform: scale(1.2) !important;
            opacity: 0;
        }

        .thought-check.Completed {
            //@include: #0ea2df;
            //background-color: #e75e47;
            opacity: 1;
            //border: 2px solid darken(#ffc45e, 0%);

            .checkmark-container {
                margin-top: -7.5px;
                margin-top: -9.5px;
                margin-left: 3px;
            }

            .checkmark-img {
                opacity: 1;
                padding: 0;
                //margin-top: -40px !important;
            }
        }
    }
}

.SuggestionOption:nth-child(3) {
    //margin-left: 8px !important;
    margin-left: 2px !important;
}

.SuggestionOption {
    .flare {
        position: relative;
        display: none;
        transition: .25s;
    }

    .tape-img {
        position: absolute;
        top: -26px;
        left: 110px;
        width: 23px;
        transform: rotate(5deg);
    }

    .pin-img {
        position: absolute;
        top: -21px;
        left: 58px;
        width: 23px;
        opacity: .8;
        //transform: rotate(5deg);
    }
}

.SuggestionOption.Row1.Option1,
.SuggestionOption.Row2.Option3,
.SuggestionOption.Row4.Option4 {
    transform: scale($noteScale) rotate(-1deg);
}

.SuggestionOption.Row1.Option2,
.SuggestionOption.Row2.Option4,
.SuggestionOption.Row4.Option1 {
    transform: scale($noteScale) rotate(0deg);
}

.SuggestionOption.Row4.Option1,
.SuggestionOption.Row2.Option4 {
    box-shadow: 0.5px 1.5px 2px 0px rgba(darken(#c9b2d8, 8%), .25);

    .flare-tape {
        display: block !important;
    }
}

.SuggestionOption.Row2.Option1,
.SuggestionOption.Row4.Option100 {
    box-shadow: 0.5px 1.5px 2px 0px rgba(darken(#c9b2d8, 8%), .25);

    .flare-pin {
        display: block !important;
    }
}

.SuggestionLabel {
    border-radius: 20px;
    box-shadow: none !important;

    //border: 2px solid lighten($layoutLineColor, 8%);
    //border: 2px solid lighten(#a1dce9, 16%);

    margin-top: 8.0px;
    height: $noteHeight - 2px;

    margin-left: 8px;
    //margin-left: auto;
    transform: none !important;

    border: 2px solid #f3f3f3 !important;

    border: 2px solid darken($dropBackgroundColor, 10%) !important; // was 12%, might be better
    //opacity: 0;

    padding: 18.5px 20px 17px 20px;

    .label {
        margin-top: 0px;
        font-size: 13.5pt;
        //color: $positiveAccentColor;
        color: #078990;
        font-weight: 500;

        //opacity: .6;
    }

    .widget-heading {
        //display: flex;
        padding: 0;

        .icon {
            //opacity: .6;
            width: 20px;
            margin-top: 8px;
            align-self: center;
        }

        .label {
            //opacity: .6;
            padding: 3px 0 0 0px;
            font-size: 13.5pt;
            color: darken($voiceColor, 0%);
            color: lightsalmon;
            //color: lightsalmon;

            font-family: $sansFamily;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: none;
        }

        .score {
            display: none;
            background-color: lighten(lightsalmon, 24%);
            margin-left: auto;
            margin-right: 16px;
            padding: 3.5px 7px 0 7px;
            border-radius: 100px;
            color: lightsalmon;
            letter-spacing: 0.5px;
            transform: scale(.925);
        }
    }
}

.SuggestionLabel.Row1 {
    background-color: lighten($themeTealBackgroundColor, 2%);
    border: 2px solid desaturate(darken($themeTealBackgroundColor, 8%), 35%);

    .label {
        color: lighten($themeTealFontColor, 8%);
        color: desaturate(lighten($themeTealFontColor, 17%), 20%);
    }
}

.SuggestionLabel.Row2 {
    background-color: lighten($themeYellowBackgroundColor, 2%);
    border: 2px solid desaturate(darken($themeYellowBackgroundColor, 10%), 20%);

    .label {
        color: lighten($themeYellowFontColor, 12%);
        color: desaturate(lighten($themeYellowFontColor, 34%), 20%);
    }
}

.SuggestionLabel.Row3 {
    background-color: lighten($themePurpleBackgroundColor, 1%);
    border: 2px solid darken($themePurpleBackgroundColor, 2%);

    .label {
        color: lighten($themePurpleFontColor, 8%);
        color: desaturate(lighten($themePurpleFontColor, 30%), 40%);
    }
}

.SuggestionLabel.Row4 {
    background-color: lighten($themePinkBackgroundColor, .5%);
    border: 2px solid darken($themePinkBackgroundColor, 2%);

    .label {
        color: lighten($themePinkFontColor, 8%);
        color: desaturate(lighten($themePinkFontColor, 30%), 40%);
    }
}

.SuggestionOption {
    .thought-checks {
        margin-top: 13.5px;
        zoom: 0.775;
    }

    .thought-tags {
        display: none;
    }
}

.SuggestionOption.Neutral {
    .label {
        .label-content {
            padding-top: 0px;
            //color: darken($negativeColor, 14%) !important;
            opacity: 1;
            //color: darken(#bd37ef, 8%);
            //filter: $saturation;
            //color: #333 !important;
            line-height: 1.4em;
            font-size: $cardFontSize;
            font-family: 'Merriweather';
            font-weight: 400;
        }
    }
}

.SuggestionOption.Reinforce {
    //height: 236px;
    height: 155.5px;

    padding-bottom: 12px !important;
    padding-right: 28px !important;
    padding-left: 24px !important;

    display: flex;
    flex-direction: column;

    transition: 0.35s;

    .thought-checks {
        margin-top: auto;
        zoom: 0.85;
    }

    .label {
        //font-size: 12pt;
        //font-size: 14.0pt;
        // font-size: 13.75pt;
        // font-size: 13.0pt;

        // font-size: 13.25pt;
        // font-size: 12.5pt;
        font-size: 12.25pt;
    }

    .preview-content {
        display: flex;
        flex-direction: column;
        height: 206px;

        display: none;
    }

    .thought-set-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .area-icon {
        margin-left: auto;
        margin-top: -1px;
        margin-right: -12px;
    }

    .area-img {
        width: 23px;
    }
}

// Switch Reinforce card content based on if it is
// a Suggest Card or has already been clicked.
.SuggestionOption.Reinforce {
    .preview-content {
        display: flex;
    }

    .thought-set-content {
        display: none;
    }
}

.SuggestionOption.ReinforceInProgress {
    transition: .3s;

    .preview-content {
        display: none;
    }

    .thought-set-content {
        display: flex;
    }
}

.SuggestionOption.ReinforceInProgress.SlideAway {
    opacity: 0 !important;
    transition: .4s;
}

.SuggestionOption.Reinforce.IsEmpty {
    opacity: 0.5;

    .empty-label {
        text-align: center;
        padding: 80px 40px 0 40px;

        font-size: 10.5pt;
        line-height: 1.35em;
    }
}

.SuggestionOption.Reinforce.IsEmpty:hover {
    cursor: default;
    transform: none;
    box-shadow: none;
}

.SuggestionOption:hover {
    cursor: pointer;
    transform: scale(1.12) !important;
    z-index: 2000;
    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

    .thought-checks {
        //opacity: 0.5;
    }

    .label-content {
        text-decoration: underline;
        text-decoration-thickness: 1.5px;
        //text-decoration-color: lighten($neutralColor, 22%);
    }

    .flare {
        opacity: 0 !important;

    }
}

.SuggestionOption.Selected {
    opacity: 0 !important;
    //height: 0px !important;
}

.SuggestionOption.SelectedClone {
    padding-left: 22px !important;
    padding-top: 18.5px !important;

    .thought-checks {
        opacity: 0 !important;
    }

    .label-content {
        transform: scale(0.99) !important;
        transform-origin: left;
        line-height: 1.40em;
    }
}

.SuggestionOption.Reinforce.SessionThoughtExpanded {
    height: 146px;

    .label-content {
        padding-top: 3px;
        font-size: 14.5pt;
        color: $reinforceColor;
    }

    .thought-checks {
        margin-top: 2px;
        transform: scale(.875);
        transform-origin: left;
    }
}

// Shared
.thought-tags {
    display: flex;
    margin-top: -20px;
    margin-left: -0px;
    margin-top: auto;

    .thought-tag {
        //zoom: 0.95;
        //opacity: 0.8;
        padding: 2px 0px;
        border-radius: 100px;
        margin-right: 10px;
        font-size: 9.75pt;
        font-weight: 700;
        color: #fff;
    }

    .tag-self-esteem {
        color: darken(#ffbd75, 10%);

    }

    .tag-calm-mind {
        color: lighten(#c675ff, 1%);

    }
}


.BioItem {
    background-color: #fff;
    border-radius: 40px;
    width: 180px;
    height: 32px;

    .label {
        padding-top: 4px;
        padding-left: 0px;
        font-size: 9.75pt;
        font-weight: 700;
        color: darken(#ff8d0d, 10%);
        color: darken(#ffa542, 2%);
        color: lighten(#1797cb, 10%);
        text-align: left;
    }

    .content {
        padding: 4px 20px 14px 0px;
        font-size: 9.5pt;
        font-weight: 500;
        color: darken(#ccc, 2%);
        color: darken(#ffa542, 4%);
        color: lighten(#ffa542, 14%);
        text-align: left;

        //width: 148px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.toolbar:hover {
    .about-widget {
        opacity: 1 !important;
    }
}

.about-widget {
    display: flex;
    width: 74.5%;
    height: 54px;
    background-color: lighten($backgroundColor, 2.5%);
    border-radius: 100px;
    margin: 0 0px;
    margin-left: 6px;
    margin-left: auto;
    cursor: pointer;

    opacity: 0;
    transition: .5s;
    //flex: 1;
    //background-color: green;

    .about-content {
        padding: 16px 20px;
        font-size: 12.0pt;
        font-weight: 500;
        color: lighten($negativeColor, 0%);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .about-content.Work {
        color: lighten($negativeColor, 14%);
    }

    .about-content.Personal {
        color: lighten(#2dab74, 18%);
    }

    .score-circle {
        width: 12px;
        height: 12px;
        margin: 20px 5px 0 20px;
        border-radius: 100px;
        background-color: #ffc45e;
        //border: 3px solid #ffc45e;
        //background-color: #fff;
        opacity: 0.5;
    }

    .score-number {
        padding: 14px 0;
        font-size: 13.0pt;
        font-weight: 600;
        color: #0ea2df;
    }
}

.about-widget:hover {
    opacity: 1;
    background-color: #fff;

    .about-content.Work {
        color: lighten($negativeColor, 14%);
    }

    .about-content.Personal {
        color: lighten(#2dab74, 8%);
    }
}

.BioItem:hover {
    .label {
        text-decoration: underline;
    }

    .content {
        text-decoration: underline;
        color: lighten(#ffa542, 4%) !important;
    }

    cursor: pointer;
}

.BioItem.WorkItem {
    margin-top: 15px;

    .label {
        color: lighten($negativeColor, 34%);
    }

    .content {}
}

.BioItem.PersonalItem {
    .label {
        color: lighten(#2dab74, 18%);
    }

    .content {}
}

.SuggestionOption.IsPickUp {
    .label {
        height: 70px;
        ;
    }
}

.SuggestionOption,
.SessionThoughtActive {

    .pickup-progress {
        // margin-top: 24px;
        //background-color: #eee;
        //opacity: 0;
    }

    .pickup-fillbar {
        float: left;
        width: 100px;
        background-color: lighten($neutralColor, 22%) !important;
        height: 4px;
        border-radius: 100px;
        display: none;
    }

    .pickup-progressbar {
        //opacity: .6;
        display: none;
        opacity: 0;
        float: left;
        width: 80%;
        background-color: lighten($neutralColor, 49%) !important;
        //background-color: #111 !important;
        height: 4px;
        border-radius: 100px;
    }

    .pickup-outof {
        float: right;
        width: 30px;
        //display: none;

        margin: 0;
        margin-top: -13px;
        margin-left: 0px;

        padding: 0;
        color: lighten($neutralColor, 8%);
        font-size: 9.5pt;
        font-weight: 600;
        text-align: right;

        opacity: 0;


        .end {
            color: lighten($neutralColor, 22%);
        }
    }
}

.SessionThoughtActive.IsNeutral {
    .thought-content-active {
        .thought-label {
            //color: #111 !important;
        }
    }

    .pickup-progressbar {}

    .pickup-outof {
        //margin-left: 30px !important;
        padding-left: 0px;
        font-size: 11pt;
        margin-right: -2.5px;

        //color: #111 !important;
    }
}

$colorBorderHeight: 2px;

.SuggestionOption.ColorPurple {
    $baseColor: $themePurpleBackgroundColor;
    $shadowColor: $themePurpleBackgroundShadowColor;
    background-color: $baseColor;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
    //background: #fff !important;
    //background: lighten(#fdf8ff, 0%);
    //border-top: $colorBorderHeight solid darken(#fdf8ff, 1%);

    //background: #fff !important;

    .label-content {
        color: $themePurpleFontColor !important;

        //color: #222 !important;
        opacity: .9;
        padding-top: 4px;
    }
}

.SuggestionOption.ColorYellow {
    $baseColor: $themeYellowBackgroundColor;
    $shadowColor: $themeYellowBackgroundShadowColor;
    background-color: $baseColor;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
    //background: lighten(#fdf1d6, 7%) !important;
    //border-top: $colorBorderHeight solid lighten(#fdf1d6, 4%);
    //background: #fff !important;

    .label-content {
        color: darken(#fdf1d6, 70%) !important;
        color: $themeYellowFontColor !important;

        //color: #222 !important;
        opacity: .9;
        padding-top: 4px;
    }
}

.SuggestionOption.ColorPink {
    $baseColor: $themePinkBackgroundColor;
    $shadowColor: $themePinkBackgroundShadowColor;
    background-color: $baseColor;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;

    //background: lighten(#ffd9ea, 6.5%) !important;
    //border-top: $colorBorderHeight solid lighten(#ffd9ea, 5%);

    //background: #fff !important;

    .label-content {
        color: $themePinkFontColor !important;
        opacity: .8;

        //color: #222 !important;
        opacity: .9;
        padding-top: 4px;
    }
}

.SuggestionOption.ColorTeal {
    background-color: lighten(#fffde4, 2%);

    $baseColor: $themeTealBackgroundColor;
    $shadowColor: $themeTealBackgroundShadowColor;
    background-color: $baseColor;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
    //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
    //background: #fff !important;

    //background: #fff !important;
    //background-color: lighten(#d6fbfd, 7%) !important;
    //border-top: $colorBorderHeight solid lighten(#d6fbfd, 4%);

    .label-content {
        color: darken(#d6fbfd, 72%) !important;
        color: $themeTealFontColor !important;
        //color: #222 !important;
        opacity: .9;
        padding-top: 4px;
    }
}

// .SuggestionsSheet.Color1,
// .SuggestionsSheet.Color2,
// .SuggestionsSheet.Color3,
// .SuggestionsSheet.Color4 {
//     .SuggestionOption {
//         //background-color: lighten(#fffde4, 2%);

//         $baseColor: lighten(#fffde4, 2%);
//         $shadowColor: darken(#fffde4, 2.5%);
//         background-color: $baseColor;
//         //background: linear-gradient(0deg, lighten($shadowColor, 2%) 0%, $baseColor 35%, $baseColor 75%, $shadowColor 90%, $shadowColor 100%) no-repeat;
//         background-color: #fff !important;

//         .label-content {
//             color: darken(#fffde4, 67%) !important;
//         }
//     }
// }
// purple was 6a1fe5, then 9a19fd, try af19fd again later
// was 9a19fd 8% last
$negativeColor: darken(#9a19fd, 8%);
$negativeColor: darken(#bf19fd, 10.5%); // was 11%
//$negativeColor: darken(#bf19fd, 11%);
$reinforceColor: darken(#ff8942, 10.5%); // was 11%
//$reinforceColor: darken(#ffa442, 15%); // was 11%
//$reinforceColor: darken(#ffa200, 0%); // was 11%
$positiveAccentColor: #ffa200;
$neutralColor: darken(#049ceb, 0.5%);
$neutralColor: lighten(#1673ff, 5%);
$neutralColor: darken(#16b0ff, 8%);
$neutralColor: lighten(#bf19fd, 0.5%); // was 9.5
//$neutralColor: lighten(#bf19fd, 0.5%); // was 4.5
$negativeColor: lighten(#bf19fd, 5.5%); // was 5.5
$voiceColor: #00c4e1;

$neutralColor: darken(#ea19fd, 13.5%); // was 9.5
//$neutralColor: lighten(#bf19fd, 0.5%); // was 4.5
$negativeColor: lighten(#ea19fd, 0.5%); // was 5.5

//$negativeColor: darken(#20b3ff, 9.5%);
//$neutralColor: lighten(#bf19fd, 4.5%); // was 4.5
//$neutralColor: #555;
$saturation: saturate (50%);

$progressGradient: linear-gradient(108deg, rgba(255, 202, 107, 1) 0%, rgba(255, 217, 104, 1) 100%);
$progressGradient: lighten($positiveAccentColor, 24%);

$goalColor: darken($voiceColor, 2.5%);

// $solidColor: #222;
// //$negativeColor: $solidColor;
// //$negativeColor:$solidColor;
// //$negativeColor: darken(#bf19fd, 11%);
// $reinforceColor: $solidColor; //$reinforceColor: darken(#ffa442, 15%); // was 11%
// //$reinforceColor: darken(#ffa200, 0%); // was 11%
// $positiveAccentColor: #ffa200;


$workColor: #1797cb;
$personalColor: #2dab74;


$yellowTintBackgroundColor: darken(#fffdfa, .5%);
$creamColor: #fffef8;
$backgroundColor: $creamColor;
$dropBackgroundColor: lighten(#fdf1d6, 5.0%);

$mainHeadingColor: darken(#99d9e7, 10%);
$mainHeadingColor: lighten(#d170a2, 0%);
$mainHeadingColor: darken(#e075a0, 0%);
$mainHeadingColor: lighten(#444, 0%);
//$mainHeadingColor: darken(#f8e8b0, 50%);
//$mainHeadingColor: #111;
//$mainHeadingColor: #333;
//$mainHeadingColor: lightsalmon;
$layoutLineColor: lighten(#f8e8b0, 0%);
//$mainHeadingColor: darken(#888, 20%);

$dropSpaceTopSpace: -8px;
$dropColumnWidth: 502px;

$themeYellowFontColor: #753a00;
$themeYellowMediumFontColor: #e99039;
$themeYellowBackgroundColor: lighten(#fdfad6, 5%);
$themeYellowBackgroundShadowColor: lighten(#fdf1d6, 3%);

$themePurpleFontColor: darken(#fdf8ff, 66%);
$themePurpleMediumFontColor: #b867d9;
$themePurpleBackgroundColor: darken(#fdf8ff, .5%);
$themePurpleBackgroundShadowColor: darken(#fdf8ff, 1.5%);

$themePinkFontColor: darken(#ffd9ea, 64%);
$themePinkMediumFontColor: #d5568f;
$themePinkBackgroundColor: lighten(#ffd9ea, 6%);
$themePinkBackgroundShadowColor: lighten(#ffd9ea, 4%);

$themeTealFontColor: darken(#008ca5, 9%);
$themeTealMediumFontColor: #17b0cc;
$themeTealBackgroundColor: lighten(#d6fbfd, 5%);
$themeTealBackgroundShadowColor: lighten(#d6fbfd, 2.5%);

// $themeTealFontColor: #444;
// $themePurpleFontColor: #444;
// $themePinkFontColor: #444;
// $themeYellowFontColor: #444;


//$sidebarWidth: 220px;
//$sidebarWidth: 258px;
$sidebarWidth: 66px;
$sidebarSpace: -4px;
//$sidebarWidth: 304px; // was 298

$screenFadeSeconds: .6s;

$sansFamily: "Manrope",
    ui-sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Helvetica,
    "Apple Color Emoji",
    Arial,
    sans-serif,
    "Segoe UI Emoji",
    "Segoe UI Symbol";

$serifFamily: "Merriweather", serif;
@import 'global';

.GoalCard-position {
    position: relative;
    height: 105.5px;
}

.GoalCard {
    position: absolute;
    z-index: 1000;
    width: 194px;
    height: 64.5px;
    padding: 17.5px 12px 16.5px 24px;

    margin: 6px 2px;
    background-color: #fff;
    background-color: lighten($backgroundColor, 2.0%);
    //background-color: darken(#fffdfa, 0.5%);
    //background-color: #fff;
    //background-color: transparent;

    border-radius: 12px;
    //border-bottom: 2px solid darken($backgroundColor, 4%);

    cursor: pointer;

    transition: .15s;

    .title {
        height: 40.5px;
        padding: 0px 20px 0 8px;
        //font-family: 'Merriweather';
        font-weight: 500;
        font-size: 11.5pt;
        font-size: 11.0pt;
        font-size: 10.5pt;
        font-weight: 600;
        line-height: 1.40em;
        color: $negativeColor;
        color: $positiveAccentColor;
        color: lighten($goalColor, 2%);
        color: lighten(lightsalmon, 0%);
        color: darken($positiveAccentColor, 0%);
        //color: $goalColor;
        //color: #333;
        opacity: 1;
        //color: lighten($neutralColor, 18%);
        //color: $voiceColor;
        font-family: $sansFamily;
        font-weight: 500 !important;
        font-size: 11.0pt;

        //color: #40b8e9;
        text-align: left;
    }

    .stats {
        display: flex;
        padding: 3.5px 4px 0 4px;

        .explore-count {
            display: flex;
            // min-width: 100px;
            // max-width: 100px;
            opacity: 0;
        }

        .goal-grade {
            display: flex;
            margin: 0 0 0 6px;
            // min-width: 100px;
            // max-width: 100px;
            margin-left: auto;
        }

        .number {
            background-color: lighten($neutralColor, 54%);
            color: darken($neutralColor, 10%);
            border-radius: 100px;
            padding: 2.5px 8px 1px 8px;
            font-size: 8.5pt;
        }

        .label {
            padding: 2px 0 3px 0px;
            font-size: 9pt;
            font-weight: 600;
            color: darken(lightsalmon, 8%);
            color: lighten($neutralColor, 0%);
            opacity: .4;
        }

        .label-low {
            color: darken(lightsalmon, 8%);
            opacity: .55;
        }

        .score {
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 100px;
            padding: 2px 8px;
            font-size: 9.0pt;
            font-weight: 600;
        }
    }

    .expanded-content {
        display: none;
        margin: 12px 0 0 0;
        border-top: 1.5px solid lighten($positiveAccentColor, 44%);
        padding: 12px 0 0 0;
    }

    .expanded-heading {
        padding: 6px 0 8px 0;
        text-align: left;
        color: #ffa200 !important;
        font-size: 9.5pt;
        font-weight: 700;
    }

    .score-breakdown {

        .score-item {
            display: flex;
            padding: 4px 8px;
        }

        .label {
            padding: 2px 0 3px 0px;
            font-size: 10.5pt;
            font-weight: 500;
            color: darken(lightsalmon, 8%);
            color: lighten($neutralColor, 0%);
            color: lighten($goalColor, 4%);
            opacity: .95;
        }

        .label-low {
            color: darken(lightsalmon, 8%);
            opacity: .55;
        }

        .score {
            margin-left: auto;
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 100px;
            padding: 2px 8px;
            font-size: 10.5pt;
            font-weight: 600;
        }
    }
}

.GoalCard.IsSelected {
    background-color: #fff !important;
    cursor: default !important;
    box-shadow: .75px .75px .5px .5px darken($backgroundColor, 3.5%);

    .title {
        text-decoration: underline;
        text-decoration-color: lighten($positiveAccentColor, 34%);
        text-decoration-thickness: 2px;
    }
}

.GoalCard.IsExpanded {
    //position: absolute;
    height: 240px;
    z-index: 1001;
    transition: .15s;
    transform: scale(1.035);


    box-shadow: .75px .75px 0.5px 1.5px darken($backgroundColor, 3.5%);

    .expanded-content {
        display: block;
    }
}

.GoalCard:hover {
    background-color: lighten($backgroundColor, 3%);
    cursor: pointer;
}
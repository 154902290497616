$questionLabelWidth: 124px;

.EmotionsGradeSheet {

    .sheet-heading {
        margin-top: 90px;

        text-align: center;
        width: 780px;
        margin: 110px auto 0px auto;

        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
    }

    .sheet {
        background-color: #fff;

        padding: 40px 40px 80px 40px;

        width: 780px;
        margin: 10px auto 40px auto;
        border-radius: 28px;
    }

    .before-heading {
        color: darken(#0ea5e4, 1%);
        font-size: 11.0pt;
        font-weight: 500;
        //text-align: left;
        width: 680px;
        margin: 0 auto;
    }

    .how-heading {
        padding-top: 24px;
        color: darken(#0ea5e4, 1%);
        font-size: 13pt;
        font-weight: 500;
        //text-align: left;
        width: 680px;
        margin: 0 auto;
    }

    .how-heading-emotions {
        padding-top: 8px !important;
    }

    .questions {
        //display: flex;
        padding-top: 0px;
    }

    .energy-question {
        display: flex;
        padding-top: 0px;

        .energy-label {
            width: $questionLabelWidth;
            min-width: $questionLabelWidth;
            max-width: $questionLabelWidth;

            color: #ffae5e;
            font-weight: 600;
            font-size: 17pt;
            text-align: right;
            padding: 46.5px 32px 0 20px;
        }

        .energy-bar {}
    }

}

.EmotionsGradeSheet .EmotionMeter .meter-fill {
    //zoom: .9;
    //width: 460px;
}
@import 'global';

.ThoughtPreRating {
    flex: 1;
    width: 860px;
    min-width: 860px;
    max-width: 860px;

    zoom: 1.0;

    transition: .3s;

    box-sizing: border-box;

    .sheet-heading {
        padding: 24px 22px 14px 14px;
        color: darken(#0ea5e4, 1%);
        color: $negativeColor;
        font-size: 11pt;
        font-weight: 700;
        text-align: left;
        opacity: 0;
    }

    .sheet-heading-in {
        margin-top: -4px;
        padding: 0px 22px 10px 14px;
        color: darken(#0ea5e4, 1%);
        color: $negativeColor;
        font-size: 11pt;
        font-weight: 600;
        text-align: left;
    }

    .meter-container {
        margin-top: -8px;
    }

    .sheet {
        width: 512px;
        height: 100px;
        background-color: #fff;
        //background-color: #fffdfa;
        border-radius: 18px;

        padding: 32px 36px 24px 28px;

        margin-left: -8px;
    }

    .sheet.Expanded {
        box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);
        box-shadow: none;
    }
}

.ThoughtPreRating.IsNeutral {

    .sheet-heading,
    .sheet-heading-in {
        color: $neutralColor !important;
    }
}

.ThoughtPreRating.SessionSlot1 {
    padding-top: 0;
    margin-top: -3px;
}

.ThoughtPreRating.SessionSlot2 {
    padding-top: 157.5px;
}

.ThoughtPreRating.SessionSlot3 {
    padding-top: 319px;
}

.ThoughtPreRating.Show {
    padding-left: 0px;
    opacity: 1;
}

.ThoughtPreRating.Hide {
    padding-left: 40px;
    opacity: 0;
}
@import 'global';

.ReframingLoadingGrid {
    padding-bottom: 112px;

    .option-content {
        opacity: 0.5;
    }

    .item {
        //float: left;
        background-color: lighten(#e6b812, 49%);
        //background-color: lighten(#e6b812, 50%);
        //background-color: #fff;
        //background-color: transparent;
        //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 3%);
        border-radius: 8px;


        padding: 8px 20px;


        display: flex;
        width: 280px;
        height: 121.25px;
        height: 74px;
        //height: 96px;
        //height: 92px;
        box-sizing: border-box;

        font-size: 12.5pt;
        //font-size: 14.5pt;
        font-weight: 500;
        padding: 1.0em 38px 1.1em 26px;
        padding-right: 24px;
        padding-top: 15px;
        //padding-top: 0.85em;

        text-align: left;
        //text-align: center;
        line-height: 1.45em;


        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        margin: 10px 16px -5px -8px;

        background-color: lighten(#d1eff7, 11%); // Was 10% for long time
        background-color: transparent;
        //background-color: lighten(#e6b812, 49.5%);
        //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 1%);

        transition: 0.25s;

        .checkmark-img {
            float: left;
            width: 15px;
            margin: 5px 6px 0 0px;
        }

        .label {
            font-size: 12.75pt;
            font-size: 12.75pt;
            font-weight: 500;
            text-align: left;
            color: $reinforceColor;

            //opacity: 0.8;

        }
    }

    .item:hover {
        //text-decoration: underline;
        cursor: default;
        transform: scale(1);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        box-shadow: none;
    }

    .item.IsSelected {
        //background-color: transparent !important;
        margin-left: -4px;
        margin-top: 10px;
        transform: scale(1.0) !important;
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .loading-grid {
        padding: 0;
        padding-top: 2px;

        .loading-placeholders {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            padding: 0px 0px 20px 0px;
        }

        .option {
            height: 102px;

            width: 290px;
            height: 118.5px;
        }

        .option-content {
            zoom: 0.9;
            opacity: 0.2;
        }

        .loading-placeholder {

            width: 48%;
            //zoom: 0.95;

            box-sizing: border-box;

            //opacity: 0.7;
            margin-top: 0px;

            height: 22px;

            background-color: lighten(#d1eff7, 10%);
            background-color: #fff;
            //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

            border-radius: 12px;
            margin: 8px 0px 4px 0;
            //margin-left: -14px;
        }
    }

    .loading-position {
        position: relative;

        .loading-box {
            position: absolute;
            top: -358px;
            left: 150px;

            width: 240px;

            padding: 28px 0 12px 0;

            background-color: #fff;
            box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
            border-radius: 40px;

            zoom: 1.05;
        }

        .chatbot-img {
            display: none;
            width: 32px;
        }

        .label {
            padding: 12px 0;
            color: darken(#0ea5e4, 2%);
            font-size: 10pt;
            line-height: 1.4em;
        }
    }
}
@import 'global';

$progressBarHeight: 4.0px;
$largeProgressBarHeight: 6.5px; // was 6.5

.ThoughtSetContent {
    display: flex;
    flex-direction: column;
    height: 166px;

    transform: scale(.975);

    .card-ear {
        opacity: 0;
        position: absolute;
        top: 30px;
        left: 325px;
        width: 43px;
        height: 32px;
        background-color: lighten(lightsalmon, 24%);
        //background-color: #fff;
        border-radius: 0 4px 0 0;

        border-radius: 4px;

        //box-shadow: 0.5px 0.5px 2px .0px darken(#e9dec5, 8%); // Was 20%

        .ear-number {
            padding: 6px 0 0 9px;
            color: darken(lightsalmon, 2%);
            //color: $negativeColor;
            font-weight: 600;
            font-size: 10pt;
        }
    }

    .ear-donation {
        //opacity: 0;
        top: 62px;
        //left: 310px;
        width: 43px;
        border-radius: 0 0 4px 0;
        background-color: lighten($positiveAccentColor, 49%);
        background-color: #fff;

        .ear-number {
            padding: 8.5px 0 0 9px;
            font-size: 8pt;
            color: lighten($goalColor, 0%);
        }
    }

    .progress-content {}

    .notes-content {
        margin-top: 3.5px;
        //border-top: 2px dashed darken(#d6fbfd, 4%);
        padding: 2px 0 25px 18px;
        //background-color: lighten(#d6fbfd, 6%) !important;


        .notes-item {
            padding: 4px 12px 0 12px;
            color: darken(lightsalmon, 12%);
            font-family: $sansFamily;
            text-align: left;
            font-size: 11pt;

        }

        .explore-item {
            display: flex;
            align-items: flex-start;

            transform: scale(.95);
            transform-origin: top left;

            padding-top: 1px;

            //height: 48px;
            //border-bottom: 1.5px solid lighten($positiveAccentColor, 46%);


            //opacity: 0;

            .label {
                /* Initially shifted 50px below */
                transition: opacity 0.3s,
                    transform 0.3s;
            }

            .number {

                padding: 3px 4px;
                font-size: 10pt;
                font-weight: 500;
                //color: #333;
                //opacity: .35;
                // color: $goalColor;
                // color: $negativeColor;
                // color: #fff;
                opacity: .4;
            }

            .label {
                padding: 2.25px 0px 2.25px 6px;
                text-align: left;

                font-size: 12pt;
                font-weight: 500;

                //color: #444;
                //color: $goalColor;
                //color: $negativeColor;
                //color: $negativeColor;
                //font-family: 'Merriweather';
                //color: darken($negativeColor, 20%);
                line-height: 1.35em;
            }

        }
    }

    .stage-content {
        width: 100%;
        //background-color: #ddd;

        display: none;
    }

    .progress-label {
        display: none;
        position: relative;
        top: -42px;
        right: 24px;
        height: 4px;
        color: $positiveAccentColor !important;
        text-align: right;
        font-size: 9.25pt;
        font-weight: 500;

        .stage-name {
            padding-right: 6px;
            //opacity: .7;
        }
    }

    .progress-label-name {
        text-align: left;
        left: 2px;
        top: -38px;
    }

    .stage-numbers2 {
        display: flex;
        width: 100%;
        height: 32px;
        opacity: 0;

        .stage-num {
            min-width: 17.75%;
            max-width: 17.75%;

            font-size: 10pt;
            color: lighten($neutralColor, 32%);
        }
    }

    // DEBT: This is replicated and slightly different in other components.
    .stage-progressbar {
        width: 100%;
        height: $progressBarHeight;
        margin-top: -18px;
        margin-right: 6px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 39%);
        //background-color: lighten($positiveAccentColor, 46%);
        background-color: lighten(#16b0ff, 41.5%) !important;

        //opacity: 0;
    }

    .stage-fillbar {
        width: 60%;
        height: $progressBarHeight;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 20%);
        background-color: lighten($positiveAccentColor, 12%);
        //background-color: lighten($reinforceColor, 24%);
        //background: $progressGradient;
        transition: .5s;
    }

    $numberWidth: 40px;

    .stage-number {
        display: none;
        margin: -15px 0 0 0;
        padding: 0px 0 0 6px;
        width: $numberWidth;
        min-width: $numberWidth;
        max-width: $numberWidth;
        font-size: 9.75pt;
        font-weight: 600;
        color: lighten($positiveAccentColor, 12%);
    }

    .completed-content {
        display: flex;
        align-items: center;

        .completed-label {
            background-color: lighten($positiveAccentColor, 46%);
            border-radius: 8px;
            padding: 10px 16px;

            color: lighten($positiveAccentColor, 0%);
            font-weight: 600;
            font-size: 11pt;
        }

        .more-label {
            background-color: #fff;
            border-radius: 8px;
            padding: 0px 12px;
            padding-top: 2px;

            color: lighten($positiveAccentColor, 10%);
            font-weight: 600;
            font-size: 9.75pt;
        }
    }

    .card-number-position {
        position: relative;
        opacity: 1;

        .card-number {
            position: absolute;
            transform: scale(1.3);
            left: 276px;
            top: 82px;
            transition: .5s;
            //opacity: 0;
            z-index: 1000;
        }

        .card-content {
            width: 64px;
            height: 56px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
        }

        .check-img {
            width: 16px;
            align-self: center;
        }

        .number-label {
            padding: 0 0 0 4px;
            font-size: 15pt;
            font-weight: 600;
            color: $positiveAccentColor !important;
        }
    }

    .card-icon-position {
        position: relative;
        opacity: 1;
        display: none;

        .card-icon {
            position: absolute;
            transform: scale(1.3);
            left: -6px;
            top: 96.5px;
            top: 24.5px;

            width: 26px;
            height: 46px;
            background-color: #fff;
            border: 1.5px solid lighten(#decda7, 17%);
            border-bottom: 1.5px solid lighten(#decda7, 12%);
            border-right: 0;
            border-bottom: 0;

            border-radius: 14px 0 0 14px;

            transition: .5s;
            //opacity: 0;
            z-index: 1000;

            transform: scale(0.97);

            border: 0;
            left: 14px;
        }

        .card-content {
            // width: 64px;
            // height: 56px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
        }

        .check-img {
            width: 19px;
            align-self: center;
            margin: 16px 0 0 25px;

            //margin: 80px 0 0 25px;

            //width: 21px;
        }
    }

    .checklist {
        margin-left: -32px !important;
        margin-left: -2px !important;


    }

    .thought-tag {
        //opacity: 0 !important;
    }

    .original-content {
        padding-top: 0;
        margin-top: -2px;

    }

    .explore-content {
        padding: 6px 0;
        display: none;

        .explore-item {
            text-align: left;
            font-size: 9pt;
            color: $neutralColor;
        }
    }

    .progress-content {

        //margin-top: auto;
        padding-bottom: 0px;
        zoom: 0.85;
        zoom: 0.875;
        // zoom: 0.9;

    }

    .thought-tags {
        margin-top: auto;
        opacity: 1;
    }

    .label-content {
        // width: 300px !important;
        // min-width: 300px !important;
        // max-width: 300px !important;

        .thought-label {
            padding-top: 6.5px;
            padding-left: 20px;
            font-size: 11.25pt !important; // Was 11.25pt
            font-size: 11.5pt !important; // Was 11.25pt
            line-height: 1.4em !important;
            font-family: $serifFamily;
            //font-family: $sansFamily !important;
            font-weight: 500 !important;
            //text-decoration: underline !important;
            padding-right: 20px;
            padding: 8px 0px 0 24px;
        }
    }

    .original-content .label-content .thought-label {
        line-height: 1.4em !important;
        //opacity: 0.5;

        // width: 270px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        // opacity: 0;

        //background-color: lighten($negativeColor, 35%);
    }

    .reframed-content {
        margin-top: -4px !important;
        padding-top: 4.5px !important;
        padding-bottom: 5px !important;

        margin: -6px 4px 0 6px !important;

        background-color: rgba(#fff, .7);
        //border-radius: 0 0 18px 18px;

        //border-bottom: 1.5px solid rgba(#333, .05);
    }

    .original-container {
        margin-top: -3.5px !important;
    }

    .flat-content {
        display: flex;
        flex-direction: column;
        margin: 9.0px 9px 11px 10px;
        //height: 200px !important;
        padding: 16px 0px 0px 0px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0.5px 0.5px 2px .0px darken(#e9dec5, 8%); // Was 20%
        transition: .4s;

        position: relative;
        top: 0px;
        z-index: 10;



        background-color: transparent !important;
        box-shadow: none !important;
        //background-image: url('../public/sticky-note.png');
        background-size: 265.5px;
        background-position: 0px -0px;
        background-repeat: no-repeat;

        // transform: scale(1.01);
        // transform: scale(0.97);
    }

    .thought-tags {
        margin-top: 7px;
        margin-left: 4px;
    }

    .area-icon {
        margin-left: auto;
        margin-top: -4px;
        margin-right: -8px;
    }

    .area-img {
        width: 20px;
        margin-top: -2px !important;
    }
}

.ThoughtSetContent.Work {
    .card-number-position {
        .number-label {
            color: $workColor;
        }
    }
}

.ThoughtSetContent.Personal {
    .card-number-position {
        .number-label {
            color: $personalColor;
        }
    }
}

.ThoughtSetContent.Reinforce {
    .card-number-position {
        .number-label {
            color: $positiveAccentColor;
        }
    }
}

.ThoughtSetContent.IsIncomplete {

    height: 100%;

    .reframed-content {
        padding-top: 7px !important;
    }

    .reframed-label {
        font-size: 14.5pt !important;
        font-weight: 700 !important;
    }

    .label-content {
        width: 98% !important;
        min-width: 98% !important;
        max-width: 98% !important;
    }

    .thought-tags {
        margin-top: -2.5px;
        margin-left: -5px;
    }

    .card-number-position {
        opacity: 0;
    }

    .original-container {
        display: none;
    }

    .original-content {
        opacity: 0;
    }


}



.ThoughtSetContent {
    .thought-checks {
        display: none !important;
    }
}

// Tweaks to CSS because there are conflicts with 
// when .SessionThoughtCompleted is also applied
.ThoughtSetContent.IsIncomplete {
    .thought-checks {
        transform: scale(1.3) !important;
        transform-origin: left;
        display: flex;
        width: 75%;

        .checkmark-container {
            margin-top: -12px !important;
        }
    }

    .thought-tags {
        //zoom: 1.2;

        .thought-tag {}
    }
}

.ThoughtSetContent.ShowCheck {
    .card-number-position {
        .card-number {
            transform: scale(1.3);
            opacity: 1;
        }
    }
}

.ThoughtSetContent.HideCheck {
    .card-number-position {
        .card-number {
            transform: scale(2.1);
            opacity: 0;
        }
    }
}

.ThoughtSetContent.HideNumber {
    .card-number-position {
        .card-number {
            opacity: 0;
        }
    }
}

.ThoughtSetContent.IsNeutral {
    .thought-linethrough {
        color: lighten($neutralColor, 10%) !important;
        //color: #fff;
        text-decoration: none !important;
        opacity: .7 !important;
    }
}

.area-tags {
    display: flex;
    flex-direction: column;
    //width: 90%;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0 0px 0 0;

    transform: scale(1);

    margin-left: auto;
    margin-top: -12px;
    margin-right: 22px;
}

.large-area-tags {
    display: flex;
    margin-top: -8px;
}

.small-area-tags {
    display: flex;
    display: none;
    //display: none;
    //flex-direction: column;
    margin: 0px 0 0 4px;
    //opacity: .75;
    //margin-top: -62px;
    //width: 0;
    //margin-left: -58px;

    //opacity: 0;

    .small-area-tag {
        display: flex;
        //flex-direction: column;
    }

    .small-plus {
        margin-left: 0px;
        font-size: 11pt;
        font-weight: 700;
        color: lighten($positiveAccentColor, 12%);
        //color: lighten($negativeColor, 46%);
        //color: #ccc;
        //color: darken($backgroundColor, 14%);
        //color: #fff;
        color: #fff;
    }

    .area-small-img {
        width: 15px;
        margin: 2px;
        align-self: center;
    }
}

$tagWidth: 26px;

.AreaTag.AreaTagMood {
    //margin-left: -1px;
}

.AreaTag.AreaTagFocus {
    //margin-left: -1px;

    .area-img {
        margin-top: 5px;
    }
}

.AreaTag.AreaTagOpenness {
    //margin-left: 0.5px;
}

.AreaTag.AreaTagEase {
    //margin-left: 1.5px;
}

.AreaTag.AreaTagMotivation {
    .area-img {
        margin-top: 4px;
    }
}

.AreaTag.Big {
    width: 32px;
    max-width: 32px;
    min-width: 32px;

    transform: scale(1.1);
}

.AreaTag.Small {
    width: 32px;
    max-width: 32px;
    min-width: 32px;

    transform: scale(.7);
}

.AreaTag {

    display: flex;

    background-color: lighten($positiveAccentColor, 57%);
    border-radius: 100px;
    margin: 2px 1px 0 0;
    margin: 2px 3px 0 3px;
    width: $tagWidth;
    max-width: $tagWidth;
    min-width: $tagWidth;

    .area-icon {
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        height: 28px;
        box-sizing: border-box;
        border-radius: 14px;
        margin: 4.5px 12px 0 -5px;
        // background-color: #fff !important;
        // box-shadow: 0.5px 0.5px 2px 0px #cdb47b;
        //border: 1px solid lighten(#cdb47b, 32%);

        transform: scale(1.3);
    }

    .area-img {
        width: 16px;
        align-self: flex-start;
        //align-self: flex-start;
        margin: 7.5px 6px 0 -6px;

        margin: 0px 3px 0 2px;
        //margin-top: -8px !important;
    }

    .area-ease {
        width: 15.0px;
        margin-top: 6px;
        margin-left: 4.0px;
    }

    .area-openness {
        width: 16px;
        margin: 7px 0px 0 -1px;
        opacity: .8;
    }

    .area-focus {
        //margin: 6px 0px 0 -4px;
        margin-top: 6px;
    }

    .area-motivation {
        width: 17px;
        margin: 6px 0px 0 0px;
    }

    .area-purpose {
        margin-top: 6px;
    }

    .area-mood {
        width: 15px;
        margin-top: 6.5px;
        margin-right: 1.5px;
    }

    .area-points {
        //display: none;
        color: lighten($positiveAccentColor, 0%);
        font-weight: 600;
        font-size: 11.5pt;
        margin: 6px 4px 4px -10px;
        padding: 0 8px 0 0;

        .plus-sign {
            display: none;
            //opacity: .5;
            padding-right: 0.5px;
        }
    }
}

.ThoughtSetContent.IsLog {
    .reframed-content {
        .label-content {
            padding-top: 2px;
            width: 248px !important;
            min-width: 248px !important;

            .thought-label {
                //font-size: 11.75pt !important;
                line-height: 1.5em !important;
            }
        }
    }

    .stage-progressbar {
        opacity: 1 !important;
    }

    .completed-content {
        margin-left: -4px;
        transform: scale(.9);
        transform-origin: top left;
    }

    .more-label {
        display: none !important;
    }
}

.ThoughtSetContent {
    .stage-progressbar {
        //opacity: 0 !important;
    }
}

.ThoughtSetContent.IsGrid,
.ThisSessionThought {

    .stage-progressbar,
    .stage-fillbar {
        height: $largeProgressBarHeight !important;
    }


}

.ThoughtSetContent.IsExploring {
    .reframed-content {
        .thought-label {
            padding-right: 0px !important;
            // color: lighten($neutralColor, 2%) !important;
            // color: darken(#d6fbfd, 72%) !important;
            // color: darken(#06848a, 4%) !important;
        }
    }

    .original-content {
        display: none;

        .thought-linethrough {
            opacity: 0 !important;
        }
    }

    .stage-progress {
        display: flex;
        width: 96%;
    }

    .stage-fillbar {
        background-color: lighten($neutralColor, 5.0%) !important;
        //background-color: lighten(#16b0ff, 0%) !important;
    }

    .stage-progressbar {
        width: 100%;
        background-color: lighten($neutralColor, 48.5%) !important;
        background-color: lighten(#16b0ff, 41.5%) !important;
    }



    .stage-outof {
        min-width: 60px;
        max-width: 60px;

        margin: 0;
        margin-top: -15px;
        margin-left: -8px;
        padding: 0;
        color: lighten($voiceColor, 0%);
        font-size: 10.5pt;
        font-weight: 600;

        .end {
            //color: lighten($neutralColor, 32%);
            opacity: .5;
        }
    }

    .area-tags {
        //opacity: 0 !important;
        font-weight: 500;
    }
}

.ThoughtSetContent.IsReinforce {
    .original-content .thought-label {
        opacity: 0 !important;
    }

    .stage-progress {
        width: 93%;
    }

    .stage-content {
        padding-top: 2px;
    }

    .area-tags {
        opacity: 0;
        display: none;
    }

    .stage-outof {
        display: none;
    }

    .progress-label {
        color: lighten($reinforceColor, 5%) !important;
    }

    .progress-content {
        padding-top: 2px !important;
        padding-bottom: 3px;
    }
}

.ThoughtSetContent.IsExploring {
    .original-content .thought-label {
        opacity: 0 !important;
    }

    .stage-progress {
        width: 95%;
    }

    .area-tags {
        opacity: 0;
        display: none;
    }

    .stage-outof {
        display: none;
    }

    .progress-content {
        //margin-top: 0;
        padding-bottom: 12px;
    }

    .progress-label {
        color: darken($neutralColor, 0%) !important;
    }
}
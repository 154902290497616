@import 'global';

$reportPrimaryColor: $themePinkFontColor;
$reportPrimaryLightColor: $themePinkMediumFontColor;

.AssessmentReport {
    width: 370px;
    height: 600px;
    //position: fixed;
    z-index: 900;
    top: 110px;
    left: 100px;

    margin-left: -20px;

    background-color: #fff;
    border-radius: 24px;

    transform: scale(1.1);
    transform-origin: top left;
    box-shadow: 1px 2px 5px 1px darken(#e3e6f7, 2%);

    .report-layout {
        display: flex;
        width: 100%;
        height: 640px;

        .breakdown-col {


            //border-right: 1.5px solid darken($backgroundColor, 2%);
        }

        .questions-col {}

    }

    .report-subheading {
        padding-top: 40px;
        font-size: 9.0pt;
        font-weight: 800 !important;
        color: $positiveAccentColor;
        color: lightsalmon;
        color: $reportPrimaryLightColor;
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }

    .score-bubble {
        margin: 20px 60px;
        background-color: lighten(lightsalmon, 24%);
        padding: 40px 0 48px 0;
        border-radius: 20px;
    }

    .score-sub {
        padding-top: 0px;
        margin-top: -12px;
        font-size: 12pt;
        font-weight: 800 !important;
        color: lightsalmon;
        //color: #222;
    }

    .score-big {
        padding-top: 12px;
        font-size: 48.5pt;
        font-weight: 800 !important;
        color: lightsalmon;
        //opacity: .8;
    }

    .report-done-button {
        color: $reportPrimaryColor;
        font-weight: 600;
        background-color: $reportPrimaryLightColor;
        color: #fff;
        width: 120px;
        margin: 8px auto;
        padding: 12px;
        border-radius: 100px;
        transition: .3s;
        cursor: pointer;

        &:hover {
            transform: scale(1.04);
            background-color: darken($reportPrimaryLightColor, 12%);
        }
    }

    .assessment-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(1.35);
        transform-origin: center;

        padding: 20px 0;

        .icon {
            width: 16px;
            align-self: center;
        }

        .label {
            padding: 3px 0 0 0px;
            font-size: 11.5pt;
            color: $reportPrimaryColor;
            color: #333;

            font-family: $sansFamily;
            font-weight: 700;
            letter-spacing: 0;
            text-transform: none;
        }
    }

    .date-label {
        font-size: 9.5pt;
        font-weight: 600;
        color: lighten($voiceColor, 0%);
        opacity: .8;
    }

    .history-heading,
    .questions-heading {
        padding: 40px 0 0 58px;
        font-size: 10.5pt;
        font-weight: 800 !important;
        color: $reportPrimaryLightColor;
        text-align: left;
    }

    .history-heading {
        padding: 0;
        padding-top: 4px;
        text-align: center;
    }

    .answers {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        //align-content: flex-start;
        height: 480px;
        padding: 12px 30px 20px 58px;
    }

    .answer {
        width: 280px;
        display: flex;

        padding: 18px 0;
        border-bottom: 1.5px solid lighten($voiceColor, 50%);

        //box-shadow: 1px 1px 2px 1px lighten(#decda7, 14%);
        //border-radius: 12px;

        // padding: 12px 24px;
        // margin: 8px;

        .number {
            max-width: 22px;
            min-width: 22px;
            padding: 0 8px 0 0;
            font-size: 11.0pt;
            font-weight: 600;
            color: lighten(lightsalmon, 8.5%);
            color: $reportPrimaryColor;
            text-align: left;
        }

        .label {
            padding: 0 28px 0 0;
            font-size: 10.0pt;
            font-weight: 500;
            line-height: 1.35em;
            color: darken($voiceColor, 3.5%);
            color: $reportPrimaryColor;
            text-align: left;
        }

        .score-value {
            transform: scale(.85);
            margin-left: auto;
            font-size: 11pt;
            font-weight: 600;
            border-radius: 6px;
            max-width: 36px;
            min-width: 36px;
            height: 28px;
            padding: 5px 0 0 0;
        }
    }

    .score-breakdown {
        padding: 12px 98px;
        //transform: scale(.9);

        .score-item {
            display: flex;
            padding: 4px 8px;
        }

        .label {
            width: 120px;
            padding: 2px 0 3px 0px;
            font-size: 11.5pt;
            font-weight: 500;
            text-align: left;
            color: darken(lightsalmon, 8%);
            color: lighten($neutralColor, 0%);
            color: lighten($goalColor, 4%);
            opacity: .95;
        }

        .label-low {
            color: darken(lightsalmon, 8%);
            opacity: .55;
        }

        .score-value {
            margin-left: auto;
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 5px;
            padding: 2px 8px;
            font-size: 9.5pt;
            font-weight: 600;
        }

        .score {
            margin-left: auto;
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 5px;
            padding: 2px 8px;
            font-size: 10.5pt;
            font-weight: 600;
        }
    }
}
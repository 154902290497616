.EmotionsCompletedSheet {

    .sheet-heading {
        margin-top: 90px;

        text-align: center;
        width: 780px;
        margin: 110px auto 0px auto;

        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
    }

    .sheet {
        background-color: #fff;

        padding: 40px 40px 80px 40px;

        width: 780px;
        margin: 10px auto 40px auto;
        border-radius: 28px;
    }

    .before-heading {
        color: darken(#0ea5e4, 1%);
        font-size: 11.0pt;
        font-weight: 500;
        //text-align: left;
        width: 680px;
        margin: 0 auto;
    }

    .how-heading {
        padding-top: 0.5em;
        color: darken(#0ea5e4, 1%);
        font-size: 15.5pt;
        font-weight: 700;
        //text-align: left;
        width: 680px;
        margin: 0 auto;
    }

    .questions {
        //display: flex;
        padding-top: 14px;
    }

}
$questionLabelWidth: 116px;

.EmotionQuestion {
    .meter-container {
        //zoom: .9;
        //zoom: 1.1;
    }

    .question-layout {
        display: flex;

        width: 700px;
        margin: 12px auto 18px auto;
    }

    .question-label {
        width: $questionLabelWidth;

        min-width: $questionLabelWidth;
        max-width: $questionLabelWidth;

        color: lighten(#6a1fe5, 0%);
        font-weight: 600;
        font-size: 11.0pt;
        text-align: right;

        //text-align: right;

        padding: 24.5px 28px 0 6px;
    }
}
@import 'global';

$landingBorderRadius: 32px;

.AssessmentsPeek {
    width: 100%;
    height: 694px;

    background-color: lighten(#d9ebff, 0%);
    background-color: lighten(#d1eff7, 9%);
    background-color: #fff;

    background-color: lighten(#d9ebff, 0%);
    //background-color: lighten(#d1eff7, 2%);

    background-color: lighten(#d1eff7, 9%);
    background-color: #fff;
    background-color: transparent;

    //background-color: lighten(#d9ebff, 0%);

    border-radius: $landingBorderRadius;

    transition: .4s;

    background-color: darken($creamColor, 2%);
    background-color: lighten(#fdf1d6, 1%);
    background-color: lighten($dropBackgroundColor, 1%);
    margin: 0 20px;
    width: 460px;

    position: fixed;
    top: 90px;

    left: 6px + $sidebarWidth + $sidebarSpace;

    width: $dropColumnWidth;

    top: 42px;
    top: 0px;
    border-radius: 0;
    height: 1000px;
    z-index: 1;

    width: $dropColumnWidth;
    height: 714px;
    top: 108px;
    left: 6px + $sidebarWidth + $sidebarSpace;
    border-radius: 40px;
    background-color: lighten($dropBackgroundColor, 0%);

    .assessment-credits {
        background-color: darken($dropBackgroundColor, 4%);
        border-radius: 18px;
        margin: 14px 20px 0px 20px;
        padding: 12px 28px 12px 28px;

        font-size: 10.5pt;
        font-weight: 600;
        color: darken($mainHeadingColor, 40%);

        .credits-phrase {
            //text-decoration: underline;
            font-weight: 700;
            color: darken($mainHeadingColor, 20%);
        }

        .start-assessment-phrase {
            font-weight: 700;
            color: darken($mainHeadingColor, 20%);
        }
    }
}

.TakeAssessmentPage,
.AssessmentsPeekPage {
    .StackCard-position {
        opacity: 0;
    }
}
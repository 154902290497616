$meterBorderRadius: 24px;
$labelColor: darken(#ffae5e, 14%);
$labelOpacity: 0.5;

$meterFill: darken(#ffbd5e, 4%);
$gaugeOffFill: darken(#fff7f1, 0%);

$floatingFill: lighten(#ffc451, 0%);

.EnergyMeter {

    padding: 0;
    zoom: 1.55;

    .meter-question {
        padding: 8px 20px 0 20px;
        text-align: center;
        font-size: 10.5pt;
        color: #666;
        font-weight: 700;
        line-height: 1.4em;
    }

    .meter-thought-layout {
        display: flex;
        display: none;
    }

    .meter-thought-col {
        width: 50%;
    }

    .meter-thought-repeat {
        padding: 18px 8px 8px 8px;
        //padding-bottom: 0;
        font-size: 11.5pt;
        font-weight: 700;
        color: darken(#797DEA, 5%);
        line-height: 1.4em;

        text-align: center;
    }

    .meter-thought-repeat-new {
        //padding-bottom: 0;
        //padding-top: 4px;
        //font-size: 10.5pt;
        font-weight: 700;
        color: lighten(#fd6f53, 3%);
        line-height: 1.4em;

        text-align: left;

        height: 40px;
    }

    .meter-numbers {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }

    .meter-thought-repeat-number {
        background-color: lighten(#d8d9f6, 7%);
        padding: 12px;
        border-radius: 10px;
        width: 40px;
        //margin: 4px auto;
        margin: 8px 4px;

        color: darken(#797DEA, 4%);
        color: #fff;
        font-weight: 700;
        float: right;
    }

    .meter-thought-repeat-number-new {
        float: left;
        background-color: lighten(#fbded9, 6%);
        background-color: darken(#fefbef, 1%);
        background-color: #ccf3be;
        background-color: #fff;
        color: lighten(#fd6f53, 3%);
        color: #fff;
        color: darken(#ccf3be, 20%);
        color: #555;

        padding-left: 12px;
    }

    .meter-fill {
        display: flex;
        flex-direction: row-reverse;
        width: 500px;
        width: 240px;
        height: 60px;
        border: 0px solid #fff;
        border-radius: $meterBorderRadius !important;
        margin: 14px auto;

        background: linear-gradient(90deg, lighten(#a2eaf2, 3%) 0%, lighten(#7bc0f4, 3%) 50%, lighten(#9295e7, 3%) 100%);
        background: lighten(#6a1fe5, 4%);

        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

        zoom: 0.95;
    }

    .meter-gauge {
        width: 27.4px;
    }

    .meter-gauge.HoverGauge {
        background-color: lighten(#10c77b, 0%);
        background-color: transparent;

        .label {
            color: #fff;
            font-weight: 600;
            text-align: center;
        }
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);

        .label {
            color: #999;
            color: lighten(#797DEA, 30%);
            //opacity: 0.6;
            font-weight: 700;

            color: #fff;
            opacity: $labelOpacity;


        }
    }

    .meter-gauge.NewThoughtHoverGauge {
        background-color: lighten(#ffe985, 20%);
        background: linear-gradient(90deg, rgba(254, 251, 239, 1) 0%, rgba(254, 246, 200, 1) 1%, lighten(#ffee8b, 10%) 66%, lighten(#ffee8b, 1%) 100%);
        //background: linear-gradient(90deg, rgba(254,251,239,1) 0%, rgba(254,246,200,1) 31%, lighten(#ffee8b, 10%) 100%);

        .label {

            color: lighten(#fd6f53, 13%);
            //opacity: 0.6;
            //font-weight: 700;
        }
    }

    .meter-gauge.ZeroHoverGauge {

        .label {
            color: #fff;
            font-weight: 700;
        }
    }

    .label {
        text-align: center;
        color: $labelColor;
        opacity: $labelOpacity;

        font-weight: 500;
    }

    .meter-gauge.OffGauge {
        background-color: #fff;
        //background-color: #fefbef !important;
    }

    .meter-gauge.HundredGauge {
        .label {
            left: 4.5px;
        }
    }


    .meter-gauge-top {
        //background-color: #ddd;
        border-radius: 0 $meterBorderRadius $meterBorderRadius 0 !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: #fff;
        //background-color: lighten(#ffe985, 20%);
        //background: linear-gradient(90deg, rgba(254,251,239,1) 0%, rgba(254,246,200,1) 1%, lighten(#ffee8b, 10%) 66%, lighten(#ffee8b, 1%) 100%);
        //background-color: #fefbef;
    }

    .meter-gauge-bottom {
        //background-color: #ddd;
        border-radius: $meterBorderRadius 0 0 $meterBorderRadius;
    }

    .meter-reading {
        font-size: 13.5pt;
        font-weight: 700;
        color: #555;
        margin-top: -8px;
        text-align: center;
    }


    .label {
        position: relative;
        top: 19px;
        left: 8.5px;
        font-size: 10pt;
    }


    .meter-gauge {
        //background-color: lighten(#ffae5e, 10%);
    }
}

.EnergyMeter.IsHoverNotActive {
    .meter-gaugeIGNORE {
        background-color: #fff !important;
        background: #fff !important;

        .label {
            color: #bbb !important;
            opacity: 1 !important;
            font-weight: 400 !important;
        }
    }

    .floating-thought-new {
        opacity: 0 !important;
    }

    .floating-thought-bubble,
    .floating-thought-bubble-new {
        opacity: 0 !important;
    }
}

.EnergyMeter.IsHoverNotActive.FillPurple {
    .meter-gauge {
        background-color: lighten(#f1f1ff, 1%) !important;
        background-color: lighten(#d9ebff, 5%) !important;
        background-color: lighten(#f1e9f9, 2%) !important;
    }
}

.EnergyMeter.IsHoverNotActive.FillOrange {
    .meter-gauge {
        background-color: $gaugeOffFill !important;
    }
}

.EnergyMeter.FillOrange {
    .meter-gauge.OffGauge {
        background-color: $gaugeOffFill !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: $gaugeOffFill !important;
    }

    .meter-fill {

        background-color: $meterFill;
    }
}




.EnergyMeter {
    cursor: pointer;

    .floating-thoughts-container {
        // display: none;
        position: absolute;
        // cursor: pointer;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;

        pointer-events: none;

        z-index: 1000;
    }

    .floating-thought-bubble {
        width: 150px;
        height: 34px;
        position: relative;
        //top: -300px;
        //left: -176px;
        text-align: left;

        background-color: #fff;
        background-color: lighten(#dfe0f8, 5%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        border-radius: 40px;

        padding: 0px 16px;
        text-align: center;

        font-size: 11pt;
        font-weight: 600;
        color: #6368e7;
        //border: 2px solid #fafafa;

        height: 44px;
        line-height: 44px;
        text-align: center;
        padding-bottom: 4px;

        .thought-text {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
    }

    .floating-thought-bubble-new {
        left: 230px;
        text-align: center;
        color: #fd7c62;
        background-color: darken(#fefbef, 4%) !important;
        //border: 1px solid darken(#fefbef, 9%)
    }

    .floating-thought {
        position: relative;
        //top: -300px;
        top: -89px;
        top: -15px;
        top: -52px;
        top: -66px;
        background-color: lighten(#6a1fe5, 9%);

        //top: -51px;
        left: 10px;
        text-align: left;
        width: 40px;
        text-align: center;

        font-size: 9.5pt;
        font-weight: 600;
        color: #111;

        //opacity: 0.5;

        padding: 0px 0px;

        font-size: 11.5pt;
        padding: 12px 10px 12px 10px;
        padding: 14px 13px;
        //padding: 9px 10px;
        //padding: 11px 6px;
        border-radius: 10px;
        border-radius: 12px;
        color: #fff;
        color: #6a1fe5;
        color: #fff;
        //box-shadow: 1px 2px 3px 1px darken(#eee, 0%);

        z-index: 100;

        display: flex;
        justify-content: center;

        .floating-checkmark-img {
            width: 10px;
            margin-right: 2px;
            align-self: center;
        }

        .floating-label {}
    }

    .floating-thought-new {
        left: 170px;
        color: #fd7c62;
        background-color: #fefbef !important;
        background-color: darken(#fefbef, 5%) !important;
        //border: 1px solid darken(#fefbef, 9%)
        z-index: 100;
    }

    .labels-content {
        display: flex;

        .label {
            padding-left: 24px;
            margin-top: -12px;
            font-size: 10.0pt;
            font-weight: 500;
            color: $labelColor;
            opacity: $labelOpacity;
            //color: #fff;
            //color: darken(#d9ebff, 16%);
            //color: #9cd5ed;
            //            opacity: 0.3;
            z-index: 0;
        }

        .label-end {
            padding-right: 32px;
            margin-left: auto;
        }
    }
}

.EnergyMeter.IsHoverNotActive {
    .floating-thought {
        opacity: 0;
    }
}

.EnergyMeter.IsCompleted {
    cursor: default !important;

    .floating-thought {
        transition: 0.075s ease-in-out;
        top: -54.5px !important;
        //zoom: 1.15;
    }
}

.EnergyMeter.FillOrange {
    .floating-thought {
        background-color: darken(#ffae5e, 16%);
        background-color: $floatingFill;

        box-shadow: 1px 0px 12px 0px rgba(255, 191, 89, 0.75);
        -webkit-box-shadow: 1px 0px 12px 0px rgba(255, 191, 89, 0.75);
        -moz-box-shadow: 1px 0px 12px 0px rgba(255, 191, 89, 0.75);
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);

        .label {
            color: #999;
            color: darken(#e97d35, 22%);
            //opacity: 0.6;
            font-weight: 700;
        }
    }
}

.EnergyMeter.ShowCheck {
    .floating-thought {
        top: -51.5px !important;
        padding: 9.6px 13px;
        background-color: lighten(#6a1fe5, 14%);
        transition: .2s;
    }

    .floating-thought {
        .floating-label {
            font-size: 10.5pt;
        }
    }
}

.EnergyMeter.FillOrange.ShowCheck {
    .floating-thought {
        background-color: lighten(#ffae5e, 10%);
    }
}
@import 'global';

.AssessmentsBadge {

    border-radius: 6px;
    width: 80px;
    height: 28px;
    text-align: center;
    font-size: 10.5pt;
    font-weight: 700;
    color: #b502f8;
    color: #25aee6;
    color: #ffa200;
    padding: 3px 0 0 0;
    margin: -.5px 0 0 0;
    box-sizing: border-box;
    border: 1.5px solid lighten(#f8e9d3, 2%);
    background-color: #fff;
}

.assessments-label {
    padding: 0 12px 0 0;
    font-size: 10.0pt;
    font-weight: 600;
    color: #b502f8;
    color: #25aee6;
    color: lighten(#ffa200, 12%);
}

.assessments-container:hover {
    .AssessmentsBadge {
        // text-decoration: underline;
        // text-decoration-color: lighten($positiveAccentColor, 36%);
        // text-decoration-thickness: 2px;
    }

    .assessments-label {
        color: lighten(#ffa200, 0%);
        text-decoration: underline;
        text-decoration-color: lighten($positiveAccentColor, 36%);
        text-decoration-thickness: 2px;
    }
}
@import 'global';

$progressBarHeight: 6px;

.StackCard-position {


    // NOTE: Widget positions are fixed individually and these values have no effect here.
    position: fixed;
    height: 108.5px;

    //top: 292px;
    top: 100px;

    transition: .3s;

    left: -6px;
    z-index: 100;

    opacity: 1;

    //opacity: 0;
    //opacity: 0;
    pointer-events: none;
}

.StackCard {
    height: 58px;
    margin: 6px 16px 0px 30px;

    border-radius: 100px;
    background-color: #fffcf7;
    background-color: transparent;
    //background-color: #fff;
    //box-shadow: 0.5px 0.5px 2px .0px darken($backgroundColor, 8%);

    opacity: 1;

    // transform: scale(.975);
    // transform-origin: center center;

    //top: 600px;
    //opacity: 0;
    margin-top: -24px;
    z-index: 5000;

    display: flex;

    pointer-events: none;

    .card-bubble {
        transform: scale(1.025);
        min-width: 90px;
        max-width: 90px;
        //background-color: #fff;
        //box-shadow: 0.5px 0.5px 2px .0px darken(#e9dec5, 2%);
        //border: 2px solid darken(#fff1cf, 1%);
        border-radius: 100px;
        margin: 8px 10.5px;
    }

    .fill-bk {
        opacity: 0;
        position: absolute;
        width: $dropColumnWidth + 4px;
        height: 200px;
        left: 0px;
        top: 122px;
        background-color: darken($dropBackgroundColor, 04%);
    }




    .turn-in-button {
        position: fixed;
        bottom: 138px;
        left: 100px;
        z-index: 5000;

        width: 200px;
        background-color: #f5cf4a;
        background-color: lighten($themeYellowMediumFontColor, 4%);
        border-radius: 100px;

        color: #fff;
        font-size: 13pt;
        font-weight: 700;
        padding: 16px 20px;

        transition: .35s;
        opacity: 0;
        cursor: pointer;
        pointer-events: all;

        &:hover {
            text-decoration: underline;
            transform: scale(1.1) !important;
            background-color: lighten($themeYellowMediumFontColor, 0%);
        }
    }

    &.ShowTurnInButton {
        .turn-in-button {
            opacity: 1 !important;
        }
    }

    .rewards-bubble {
        opacity: 0;
        margin-left: auto;


        margin-top: 8px;
        background-color: #fff;
        border: 2px solid darken(#fff1cf, 1%);

        .reward-dollars {
            padding: 11px 0 0 0px;
            color: darken($goalColor, 0%);
            //color: #45c230 !important;
            color: #cda400;
            color: #5fc4d5;
            //color: $positiveAccentColor !important;
            font-size: 9.5pt;
            font-weight: 700;
        }
    }

    .card-heading {
        color: $mainHeadingColor;
        font-weight: 700;
        font-size: 9.5pt;
        text-align: left;
        padding: 12px 24px 0px 24px;
        //border-bottom: 2px solid rgba($mainHeadingColor, .2);
    }

    .stack-bubble {
        background-color: #fff;
        border: 2.5px solid darken($dropBackgroundColor, 6%);
        //border: 0;

        //opacity: 0;
    }

    .progress-bubble,
    .challenge-bubble {
        position: fixed;
        bottom: 46px;
        left: 348px + $sidebarSpace;

        border-radius: 18px;

        //background-color: #fff;
        //background-color: transparent;
        width: 200px;
        height: 80px;

        width: 224px;


        // border-top: 2.5px solid darken($dropBackgroundColor, 0%);
        // border-radius: 0;
        // background-color: transparent;

    }

    .challenge-bubble {
        .progress-count {
            transform: scale(.75);
            transform-origin: bottom right;
            margin-top: -2px !important;
        }
    }

    .progress-bubble {
        left: 110px + $sidebarSpace;
    }

    .progress-count {
        padding: 4px 0px 0 2px;
        color: darken($mainHeadingColor, 18%);
        font-size: 15.5pt;
        font-weight: 500;
        text-align: center;

        .current-count {
            font-size: 21pt;

        }

        .progress-outof {
            //opacity: .35;
            opacity: .5;
            padding-left: 2px;
            font-size: 13.5pt;
            color: $themeYellowMediumFontColor;
        }
    }


    .widget-heading {
        display: flex;
        padding: 12px 0 20px 18px;

        .icon {
            width: 16px;
            align-self: center;
        }

        .label {
            padding: 3px 0 0 6px;
            font-size: 11.5pt;
            color: darken($voiceColor, 0%);
            //color: lightsalmon;

            font-family: $sansFamily;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: none;
        }

        .score {
            background-color: lighten(lightsalmon, 24%);
            margin-left: auto;
            margin-right: 16px;
            padding: 5px 6px 0 6px;
            border-radius: 4px;
            color: lightsalmon;
            letter-spacing: 0.5px;
            transform: scale(.9);
        }
    }

    .progress-content {
        padding: 20px 20px 20px 20px;
    }

    .stage-title {
        padding: 0 22px 4px 0;
        text-align: right;

        font-size: 9.25pt;
        color: darken($voiceColor, 0%);
        //color: lighten($positiveAccentColor, 0%);
    }

    .stage-progressbar {
        width: 100%;
        height: $progressBarHeight;
        margin-top: -18px;
        margin-right: 0px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 39%);
        //background-color: lighten($positiveAccentColor, 46%);
        background-color: lighten($voiceColor, 52.5%) !important;

        //opacity: 0;
    }

    .stage-fillbar {
        width: 60%;
        height: $progressBarHeight;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 20%);
        background-color: lighten($voiceColor, 3%);
        //background-color: lighten($reinforceColor, 24%);
        //background: $progressGradient;
        transition: .5s;
    }

    .stage-outof {}


    .expanded-content {
        display: none;
        margin: 0px 28px;
        margin-top: -18px;
        border-top: 0px solid lighten($positiveAccentColor, 44%);
        padding: 12px 0 0 0;
    }

    .expanded-heading {
        padding: 6px 0 8px 0;
        text-align: left;
        color: #ffa200 !important;
        font-size: 9.5pt;
        font-weight: 700;
    }

    .score-breakdown {
        //transform: scale(.9);

        .score-item {
            display: flex;
            padding: 4px 8px;
        }

        .label {
            padding: 2px 0 3px 0px;
            font-size: 9.5pt;
            font-weight: 500;
            color: darken(lightsalmon, 8%);
            color: lighten($neutralColor, 0%);
            color: lighten($goalColor, 4%);
            opacity: .95;
        }

        .label-low {
            color: darken(lightsalmon, 8%);
            opacity: .55;
        }

        .score {
            margin-left: auto;
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 5px;
            padding: 2px 8px;
            font-size: 9.5pt;
            font-weight: 600;
        }
    }
}

.StackCard.NewFadedOut {
    position: relative;
    transform: translateY(40px) !important;
    opacity: 0;
    //transition: .7s !important;
}

.StackCard.IsExpanded {
    //position: absolute;
    //height: 222px;
    //width: 428px;
    z-index: 1001;
    transition: .15s;
    transform: scale(1.035);
    transform: translateY(-4px) scale(1.1);
    //transform-origin: top center;
    box-shadow: 1.75px 1.75px 3.5px 0.5px darken($backgroundColor, 1.5%);
    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    cursor: pointer;
    background-color: darken(#fffcf7, 3%);

    .main-content {
        padding: 8px 0;
    }

    .expanded-content {
        display: block;
    }
}


.score.poor {
    color: darken(lightsalmon, 0%) !important;
    background-color: lighten(lightsalmon, 24%) !important;

    // color: darken(#ffc000, 6%) !important;
    // background-color: lighten(#ffc000, 47.0%) !important;
}

.score.fair {
    color: darken(#ffc000, 6%) !important;
    background-color: lighten(#ffc000, 47.0%) !important;
}

.score.good {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
}

.score.great {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
}

.StackWidget {
    position: fixed;

    width: 158px;
    width: 150px;
    height: 78px;

    bottom: 44px;
    left: 400px;

    background-color: darken($themePurpleBackgroundColor, 0%);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: .5px .5px 2px 0px rgba(darken($dropBackgroundColor, 50%), .0);
    border: 2px solid rgba(darken($dropBackgroundColor, 9.5%), 1);


    .m-heading {
        padding: 12px 0 0 0px;
        color: lighten($themeYellowFontColor, 60%);
        color: #333;
        //color: lighten($themePurpleFontColor, 10%);
        font-size: 9.5pt;
        font-weight: 700 !important;
        text-align: center;
    }
}

.StackWidget {
    //transform: scale(.975);
}

.CardWidget {
    left: 106px;
    //width: 126px;
    //opacity: 0;

    // background-color: transparent;
    // box-shadow: none;
    // transform: scale(1.15);
}

.MoodWidget {
    left: 262.5px;
    transition: .2s;

    .mood-meter {
        padding-top: 4px;
        position: relative;
        transform: scale(1.0);
        transition: .4s;

        .meter-img {
            position: relative;
            left: 12px;
            width: 56px;
        }

        .needle-img {
            position: relative;
            width: 20px;
            left: -27px;
            transform: rotate(0deg);
            transition: .5s;
        }

        .needle-img.Value0 {
            transform: rotate(-90deg);
            left: -35.5px;
            top: 9px;
        }

        .needle-img.Value10 {
            transform: rotate(-72deg);
            left: -35.5px;
            top: 6.5px;
        }

        .needle-img.Value20 {
            transform: rotate(-54deg);
            left: -34px;
            top: 4px;
        }

        .needle-img.Value30 {
            transform: rotate(-36deg);
            left: -32px;
            top: 2px;
        }

        .needle-img.Value40 {
            transform: rotate(-18deg);
            left: -30px;
            top: 1px;
        }

        .needle-img.Value50 {
            transform: rotate(0deg);
            left: -27px;
            top: 1px;
        }

        .needle-img.Value60 {
            transform: rotate(17deg);
            left: -25px;
            top: 1px;
        }

        .needle-img.Value70 {
            transform: rotate(36deg);
            left: -22px;
            top: 1.5px;
        }

        .needle-img.Value80 {
            transform: rotate(54deg);
            left: -20px;
            top: 3px;
        }

        .needle-img.Value90 {
            transform: rotate(72deg);
            left: -18px;
            top: 5.5px;
        }

        .needle-img.Value100 {
            transform: rotate(90deg);
            left: -16.5px;
            top: 8px;
        }

    }
}

.MoodWidget.Increasing {

    .mood-meter {
        // transform: scale(1.075);
        // transform: scale(1.125);
    }

}

.DonationWidget {
    left: 420px;

    .goal-content {
        display: flex;
    }

    .dollar-img {
        width: 50px;
        align-self: center;

        margin: 6px 6px 0 12px;
        transform: rotate(-.5deg);
    }

    .coin-img {
        width: 18px;
        align-self: center;
        margin: 12px 8px 0 -26px;
        z-index: 1000;
    }

    .goal {
        padding: 3.5px 0px 0px 0px;
        text-align: center;
        color: #fff;
        font-size: 16.5pt;
        font-size: 16.25pt;
        font-weight: 700;
        color: $themeTealBackgroundColor;
        color: darken(#e9bd23, 0%);
        color: lighten($themeTealFontColor, 18%);
        //color: #333;
        color: #4bd868;

        .progress-outof {
            display: none;
            padding-left: 4px;
            font-size: 10pt;
            opacity: .65;
        }
    }
}

.dollar-drop-img {

    // position: absolute;


    // width: 100px;

    // top: 200px;
    // left: 200px;

    // animation: curvedJump 2s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    // animation: circularMotion 5s linear infinite;

    // transform-origin: -150px 0;
    // animation: circularMotion 5s linear infinite;
}

@keyframes circularMotion {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes curvedJump {
    0% {
        top: 200px;
        left: 200px;
    }

    25% {
        top: 140px;
        /* Highest point of the jump */
        left: 300px;
        /* Midway horizontally */
    }

    50% {
        top: 400px;
        /* Descending to the final position */
        left: 400px;
    }

    75% {
        top: 360px;
        /* Slight bounce up after landing */
        left: 400px;
    }

    100% {
        top: 400px;
        left: 400px;
    }
}

@keyframes jump {
    0% {
        top: 200px;
        left: 200px;
    }

    25% {
        top: 150px;
        /* Jump up */
        left: 300px;
        /* Move right */
    }

    50% {
        top: 400px;
        /* Fall down */
        left: 400px;
        /* Move right */
    }

    75% {
        top: 350px;
        /* Bounce up slightly */
        left: 400px;
    }

    100% {
        top: 400px;
        left: 400px;
    }
}

.dollar-drop-container {
    position: relative;
    width: 400px;
    height: 400px;
    //border: 1px solid black;
    margin: 10px auto;
}

.smile-drop-img {
    position: absolute;
    width: 28px;
    //background-color: red;
    align-self: center;

    opacity: 0;

    //transform: rotate(-3deg);
}

.dollar-drop-img {
    position: absolute;
    width: 100px;
    //background-color: red;
    align-self: center;

    opacity: 0;

    //transform: rotate(-3deg);
}

.dollar-150-drop-img {
    position: absolute;
    width: 108px;
    //background-color: red;
    align-self: center;

    opacity: 0;
}

.quarter-drop-img {
    position: absolute;
    width: 30px;
    //background-color: red;
    align-self: center;

    opacity: 0;
}

.quarter-2-drop-img {
    position: absolute;
    width: 40px;
    //background-color: red;
    align-self: center;

    opacity: 0;
}
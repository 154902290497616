@import 'global';

$progressBarHeight: 6px;

.AssessmentCard-position {
    position: relative;
    height: 142.5px;

    transition: .3s;
}

.AssessmentCard {
    height: 142px;
    margin: 8px 20px 0px 20px;

    border-radius: 18px;
    //box-shadow: 0.5px 0.5px 2px .0px darken(#e9dec5, 8%);
    //background-color: lighten($voiceColor, 54.5%);
    //background-color: #fff;

    background-color: darken($dropBackgroundColor, 4%);
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px darken($dropBackgroundColor, 6%);

    transform: scale(1.01);
    transform-origin: center center;


    .widget-heading {
        display: flex;
        padding: 18px 42px 0 60px;

        transform: scale(1.25);
        height: 28px;

        .icon {
            width: 14px;
            margin-top: -2px;
            margin-left: 4px;
            align-self: center;
        }

        .label {
            padding: 3px 0 0 20px;
            font-size: 11.5pt;
            color: darken($voiceColor, 0%);
            color: lightsalmon;
            color: $mainHeadingColor;
            //color: lightsalmon;

            font-family: $sansFamily;
            font-weight: 600;
            letter-spacing: 0;
            text-transform: none;
        }

        .score {
            background-color: lighten(lightsalmon, 24%);
            margin-left: auto;
            margin-right: 16px;
            padding: 2px 7px 2px 7px;
            border-radius: 8px;
            color: lightsalmon;
            letter-spacing: 0.5px;
            transform: scale(1.025);
            transform-origin: top left;
        }

        .score-empty {
            background-color: #fff !important;
            border: 0px !important;

            padding: 8px 8px 4px 0;
            font-size: 7pt;
            color: darken(#f2ca6f, 20%);
        }
    }

    .description {
        margin: 8px 160px 0 44px;
        font-size: 10.5pt;
        //padding: 8px 0 0 0;
        text-align: left;
        font-weight: 500;
        color: darken(#f2ca6f, 20%);
    }

    .start-content {
        .start-button {
            margin-left: auto;
            margin-right: 16px;
            margin-top: 0px;
            width: 140px;
            font-size: 9.5pt;
            color: #111;
            background-color: lighten($mainHeadingColor, 64%);
            background-color: darken($themePinkMediumFontColor, 1%);
            //background-color: darken($themePinkBackgroundColor, 4%);
            //background-color: darken($dropBackgroundColor, 4%);
            //border: 2px solid lighten($mainHeadingColor, 18%);
            color: darken(#f2ca6f, 20%);
            color: $mainHeadingColor;
            color: #333;
            //text-decoration: underline;
            font-weight: 700;
            border-radius: 100px;
            padding: 8px 0px;
            transition: .2s;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .progress-content {
        padding: 20px 20px 20px 20px;
    }

    .stage-title {
        padding: 0 22px 4px 0;
        text-align: right;

        font-size: 9.25pt;
        color: darken($voiceColor, 0%);
        color: darken(lightsalmon, 2%);
        //color: lighten($positiveAccentColor, 0%);
    }

    .stage-progressbar {
        width: 100%;
        height: $progressBarHeight;
        margin-top: -18px;
        margin-right: 0px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 39%);
        //background-color: lighten($positiveAccentColor, 46%);
        background-color: lighten($voiceColor, 52.5%) !important;
        background-color: lighten(lightsalmon, 22%) !important;
        //opacity: 0;
    }

    .stage-fillbar {
        width: 60%;
        height: $progressBarHeight;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 20%);
        background-color: lighten($voiceColor, 3%);
        background-color: lighten(lightsalmon, 0%);
        //background-color: lighten(#45c230, 8%) !important;
        //background-color: lighten($reinforceColor, 24%);
        //background: $progressGradient;
        transition: .5s;
    }

    .stage-outof {}


    .expanded-content {
        display: none;
        margin: 0px 28px;
        margin-top: -18px;
        border-top: 0px solid lighten($positiveAccentColor, 44%);
        padding: 12px 0 0 0;
    }

    .expanded-heading {
        padding: 6px 0 8px 0;
        text-align: left;
        color: #ffa200 !important;
        font-size: 9.5pt;
        font-weight: 700;
    }

    .score-breakdown {
        padding: 0 30px;
        //transform: scale(.9);
        transform: scale(1.1) !important;


        .score-item {
            display: flex;
            padding: 4px 8px;
        }

        .label {
            padding: 2px 0 3px 0px;
            font-size: 9.5pt;
            font-weight: 500;
            color: darken(lightsalmon, 8%);
            color: lighten($neutralColor, 0%);
            color: lighten($goalColor, 4%);
            opacity: .95;
        }

        .label-low {
            color: darken(lightsalmon, 8%);
            opacity: .55;
        }

        .score {
            margin-left: auto;
            background-color: transparent;
            color: darken(lightsalmon, 8%);
            border-radius: 5px;
            padding: 2px 8px;
            font-size: 9.5pt;
            font-weight: 600;
        }
    }

    .score-breakdown-history {
        transform: scale(1.1);
        border-top: 0px solid darken($creamColor, 10%);
        padding: 8px 30px 8px 70px;
    }
}

.AssessmentCard.ThemeColorTeal {
    .start-button {
        background-color: darken($themeTealBackgroundShadowColor, 1%) !important;
    }
}

.AssessmentCard.ThemeColorYellow {
    .start-button {
        background-color: darken($themeYellowBackgroundShadowColor, 1%) !important;
    }
}

.AssessmentCard.ThemeColorPurple {
    .start-button {
        background-color: darken($themePurpleBackgroundShadowColor, 1%) !important;
    }
}

.AssessmentCard.ThemeColorPink {
    .start-button {
        background-color: darken($themePinkBackgroundShadowColor, 1%) !important;
    }
}

.AssessmentCard.NewFadedOut {
    position: relative;
    transform: translateY(40px) !important;
    opacity: 0;
    //transition: .7s !important;
}

.AssessmentCard.IsExpanded {
    //position: absolute;
    height: auto;
    //width: 428px;
    z-index: 1001;
    transition: .15s;
    transform: scale(1.035);

    padding-bottom: 24px;
    //transform-origin: top center;
    //box-shadow: 1.75px 1.75px 3.5px 0.5px darken($backgroundColor, 1.5%);


    .main-content {
        padding: 8px 0;
    }

    .expanded-content {
        display: block;
    }
}


.score.poor {
    color: darken(lightsalmon, 0%) !important;
    background-color: lighten(lightsalmon, 23%) !important;
    border: 1.5px solid lighten(lightsalmon, 20%) !important;
}

.score.fair {
    color: darken(#ffc000, 6%) !important;
    background-color: lighten(#ffc000, 47.0%) !important;
    border: 1.5px solid lighten(#ffc000, 36.0%) !important;
}

.score.good {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
    border: 1.5px solid lighten(#6bd659, 30.5%) !important;
}

.score.great {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
    border: 1.5px solid lighten(#6bd659, 31.5%) !important;
}


.score-value.poor {
    color: darken(lightsalmon, 0%) !important;
    background-color: lighten(lightsalmon, 23%) !important;
}

.score-value.fair {
    color: darken(#ffc000, 6%) !important;
    background-color: lighten(#ffc000, 47.0%) !important;
}

.score-value.good {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
}

.score-value.great {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
}

.score-big.poor {
    color: darken(lightsalmon, 0%) !important;
    background-color: lighten(lightsalmon, 23%) !important;
}

.score-big.fair {
    color: darken(#ffc000, 6%) !important;
    background-color: lighten(#ffc000, 47.0%) !important;
}

.score-big.good {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
}

.score-big.great {
    color: darken(#6bd659, 12%) !important;
    background-color: lighten(#6bd659, 37.5%) !important;
}
@import 'global';

$exploreColor: #00c4e1;

.ExploreSheet {
    opacity: 0;
    position: relative;
    transform: translateY(40px);
    transition: .5s;

    padding: 8px;
    padding-top: 52px;
    padding-left: 68px;

    .sheet-binding {
        width: 374px;
        height: 412px;
        border: 6px solid darken($themeTealBackgroundColor, 70%);
        border-top: 4px solid darken($themeTealBackgroundColor, 70%);
        border-left: 4px solid darken($themeTealBackgroundColor, 70%);
        border-right: 8px solid darken($themeTealBackgroundColor, 70%);
        border-radius: 12px;
    }

    .sheet-page-effect {
        height: 408px;
        border-radius: 12px;
        border-right: 4px solid darken($themeTealBackgroundColor, 9%);
        border-bottom: 4px solid darken($themeTealBackgroundColor, 4%);
    }

    .sheet {
        width: 374px;
        height: 412px;
        border-radius: 22px;

        margin: 4px 0 0 -10px;

        background-color: darken($backgroundColor, 2%);
        background-color: lighten($exploreColor, 52%);
        background-color: lighten($themeTealBackgroundColor, 10%);

        border: 12px solid darken($themeTealBackgroundColor, 3%);
        border-top: 4px solid darken($themeTealBackgroundColor, 3%);
        border-left: 4px solid darken($themeTealBackgroundColor, 3%);

        box-shadow: 4.75px 4.75px 4px 2px rgba(#eee, 1);

        transform: scale(1.15);
        transform-origin: top left;

        // border: 2px solid #ffe7c2 !important;
        // border-top: 2px solid #fff3e1 !important;
        // border-left: 2px solid #fff3e1 !important;
    }

    .sheet-heading {
        padding: 64px 8px 2px 26px;
        margin-top: 0px;
        font-size: 20.75pt;
        color: darken(#04d8eb, 5%);
        color: #111;
        color: darken(#fffdfa, 50%);
        color: #f88300;
        color: $negativeColor;
        color: lighten($exploreColor, 2%);
        color: $positiveAccentColor;
        color: #f0c35d;
        //color: $positiveAccentColor;
        //opacity: 0.25;
        font-family: 'Merriweather';
        font-weight: 600;
        text-align: left;
        padding-right: 0px;
        transition: .6s;
    }

    .post-thought-content {
        opacity: 0;
        position: relative;
        transform: translateY(40px);
        transition: .5s;
    }

    .explore-list {
        padding: 12px 18px 8px 12px;
    }

    .explore-item {
        display: flex;
        align-items: flex-start;

        height: 60px;
        //border-bottom: 1.5px solid lighten($positiveAccentColor, 46%);

        transition: 2.5s !important;


        .label {
            /* Initially shifted 50px below */
            transition: opacity 0.3s,
                transform 0.3s;

            transition: .35s;
        }

        .number {

            padding: 13px 12px 13px 0px;
            font-size: 8pt;
            font-weight: 700;

            color: lighten($themeTealFontColor, 20%);
        }

        .label {
            padding: 11.25px 0px;
            text-align: left;

            font-size: 10pt;
            font-weight: 500;

            color: $neutralColor;
            color: darken(#00c9e1, 2%);
            color: $voiceColor;
            color: darken(lightsalmon, 8%);
            //font-family: 'Merriweather';
            //color: darken($negativeColor, 20%);
            line-height: 20.0px;
        }

    }

    .explore-item.IsFading {


        .label {
            opacity: 0;
            /* Start completely invisible */
            transform: translateX(32px);
            transition: 0s !important;
        }
    }

}

.ExploreSheet.IsLong {
    .sheet {
        height: 520px;
        zoom: 0.925;
    }

    .sheet-heading {
        margin-top: 3px;
    }
}

.FadeInExploreSheet {
    .ExploreSheet {
        opacity: 1;
        margin-top: 0px;
        position: relative;
        transform: translateY(0px);
    }
}

.SlideInPostThought {
    .post-thought-content {
        opacity: 1 !important;
        transform: translateY(0px) !important;
        transition: .5s;

        top: -528px;
        left: 388px;
    }
}

.ThemeColorTeal {
    .sheet {
        //background-color: $themeTealBackgroundColor;
    }

    .explore-list .number {
        color: lighten($themeTealFontColor, 20%);
        //opacity: .5;
    }

    .explore-list .label {
        color: lighten($themeTealFontColor, 8%);
    }

    .explore-item {
        //border-bottom: 1.5px dashed darken($themeTealBackgroundColor, 68%);
    }
}




/** Notebook Base **/
$bindingColor: desaturate(lighten($themeTealFontColor, 38%), 25%);
$pageShadowColor: $themeTealFontColor;

.notebook {
    transform: scale(1.35);
    position: absolute;
    width: 540px;
    height: 350px;
    top: 50%;
    left: 50%;
    margin: 94px 0 0 -320px;

    background-color: $bindingColor, ;
    background-image:
        -webkit-repeating-radial-gradient(center center,
            rgba($bindingColor, .5),
            rgba($bindingColor, .5) 2px,
            transparent 2px,
            transparent 100%);
    background-image:
        repeating-radial-gradient(center center,
            rgba($bindingColor, .5),
            rgba($bindingColor, .5) 2px,
            transparent 2px,
            transparent 100%);
    background-size: 4px 4px;

    border-radius: 15px;
    box-shadow:
        inset 2px 2px 2px rgba(255, 255, 255, .2),
        2px 2px 3px rgba(0, 0, 0, .2);


    .page-heading-container {
        position: relative;
        z-index: 2000;
    }

    .page-heading {
        width: 200px;
        position: relative;
        font-family: $serifFamily;
        font-size: 8.5pt;
        top: 30px;
        left: 36px;
        text-align: left;
    }
}

/** Left Page **/
.left {
    position: absolute;
    left: 10px;
    right: 50%;
    top: 10px;
    bottom: 10px;

    //background: rgb(247, 246, 212);
    background-color: $themeTealBackgroundColor;

    border-radius: 10px 0 0 10px;
    box-shadow:
        inset -10px 0 30px -10px rgba($pageShadowColor, .2),
        -2px 2px 1px rgba($pageShadowColor, .8);
}

/** Right Page **/
.right {
    position: absolute;
    left: 50%;
    right: 10px;
    top: 10px;
    bottom: 10px;

    background: rgb(247, 246, 212);
    background-color: $themeTealBackgroundColor;

    border-radius: 0 10px 10px 0;
    box-shadow:
        inset 10px 0 30px -10px rgba($pageShadowColor, .2),
        2px 2px 1px rgba($pageShadowColor, .8);
}

/** Page inner **/
.page-inner {
    position: absolute;
    top: 30px;
    bottom: 20px;
    left: 15px;
    right: 15px;
    outline: none;
    overflow: hidden;

    background: linear-gradient(to bottom,
            transparent,
            transparent 19px,
            rgba($bindingColor, .2),
            rgba($bindingColor, .2) 1px);
    background-size: 100% 20px;


    font-size: 10pt;
    font-family: 'Kalam';
    //font-family: $serifFamily;
    font-weight: 700 !important;
    //color: #555;
    text-align: left;

    .number {
        font-family: $sansFamily;
    }
}

.page-inner>div {
    text-align: left !important
}


@mixin notebook($pageBackgroundColor, $bindingColor, $lineColor, $pageShadowColor, $pageFontColor, $pageLightFontColor) {
    .notebook {
        background-color: $bindingColor;
        background-image:
            -webkit-repeating-radial-gradient(center center,
                rgba($bindingColor, .5),
                rgba($bindingColor, .5) 2px,
                transparent 2px,
                transparent 100%);
        background-image:
            repeating-radial-gradient(center center,
                rgba($bindingColor, .5),
                rgba($bindingColor, .5) 2px,
                transparent 2px,
                transparent 100%);
    }

    .left {
        background-color: $pageBackgroundColor;

        border-radius: 10px 0 0 10px;
        box-shadow:
            inset -10px 0 30px -10px rgba($pageShadowColor, .2),
            -2px 2px 1px rgba($pageShadowColor, .8);
    }

    .right {
        background-color: $pageBackgroundColor;

        border-radius: 0 10px 10px 0;
        box-shadow:
            inset 10px 0 30px -10px rgba($pageShadowColor, .2),
            2px 2px 1px rgba($pageShadowColor, .8);
    }

    .page-inner {
        background: linear-gradient(to bottom,
                transparent,
                transparent 19px,
                rgba($lineColor, .2),
                rgba($lineColor, .2) 1px);
        background-size: 100% 20px;
    }

    .explore-item {
        .number {
            color: $pageLightFontColor;
        }

        .label {
            color: $pageFontColor
        }
    }

    .page-heading {
        color: $pageFontColor;
    }
}

.ThemeColorTeal {
    @include notebook(lighten($themeTealBackgroundColor, 3%), desaturate(lighten($themeTealMediumFontColor, 34%), 10%), lighten($themeTealMediumFontColor, 18%), $themeTealFontColor, darken($themeTealFontColor, 0%), lighten($themeTealMediumFontColor, 8%));

    .sheet-heading {
        //color: $themeTealMediumFontColor !important;
    }
}

.ThemeColorYellow {
    @include notebook(lighten($themeYellowBackgroundColor, 2%), lighten($themeYellowMediumFontColor, 21%), darken($themeYellowMediumFontColor, 2%), $themeYellowFontColor, lighten($themeYellowFontColor, 20%), $themeYellowMediumFontColor)
}

.ThemeColorPurple {
    @include notebook(lighten($themePurpleBackgroundColor, 2.5%), desaturate(lighten($themePurpleFontColor, 52%), 20%), darken($themePurpleBackgroundColor, 20%), $themePurpleFontColor, lighten($themePurpleFontColor, 10%), lighten($themePurpleFontColor, 40%))
}

.ThemeColorPink {
    @include notebook(lighten($themePinkBackgroundColor, 1.5%), desaturate(lighten($themePinkFontColor, 52%), 30%), darken($themePinkBackgroundColor, 20%), $themePinkFontColor, lighten($themePinkFontColor, 10%), lighten($themePinkFontColor, 40%))
}

.ExploreSheet {
    .sheet {
        display: none;
    }
}
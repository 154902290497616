.WelcomeScreen {

    .main-layout {
        display: flex;
        display: none;
        margin: 0 auto;
        width: 780px;

        opacity: 0;
        margin-top: 120px;
        transition: 1s all;

        .left-col {
            width: 320px;
        }

        .right-col {
            width: 360px;
        }

    }

    .chair-img {
        width: 200px;
    }

    .content {
        width: 400px;
        margin: 280px auto 0 auto;
    }

    .welcome-position {
        position: relative;
        height: 48px;
        margin-top: -18px;
    }

    .welcome-text {
        position: relative;
        padding-top: 0px;
        padding-bottom: 20px;
        font-size: 19pt;
        font-weight: 700;
        color: darken(#0ea5e4, 4%);
        //color: #f98056;
        //text-shadow: 1px 2px darken(#e3e6f7, 8%);

        opacity: 0;
        padding-top: 40px;

        transition: 0.5s all;
    }

    .name-text {
        padding-top: 28px;
        font-size: 47pt;
        font-weight: 900;
        color: #6e73ff;
        //text-shadow: 1px 2px darken(#e3e6f7, 8%);
        //letter-spacing: -2px;
    }

    .tag-text {
        font-size: 21pt;
        font-weight: 700;
        color: #f98056;
        //text-shadow: 1px 2px darken(#e3e6f7, 2%);
    }

    .julia-text {
        padding-top: 4px;
        font-size: 14pt;
        font-weight: 700;
        color: lighten(#f98056, 10%);
        //text-shadow: 1px 2px darken(#e3e6f7, 2%);
    }
}

.WelcomeScreen.ShowLoadingText {
    .welcome-text {
        opacity: 1;
        padding-top: 0;
    }
}

.WelcomeScreen.ShowLoadingScreen {

    .main-layout {
        opacity: 1;
        margin-top: 0;
    }
}

.JuliaScreen {
    opacity: 0;
    transform: scale(0.5);
    transition: 1s all;

    margin-top: 180px;
    margin-left: 100px;

    .face-img {
        margin-top: 20px;
        margin-left: 50px;
        margin: 50px auto 10px auto;
        z-index: 100;
        //opacity: 0 !important;




        position: relative;
        top: 52px;
        left: -49px;
    }

    .face-top-img {
        position: relative;
        top: 81px;
        left: 62.5px;
        //animation: welcomeWiggle 2.5s ease-in-out infinite;

        width: 122px;


        animation-name: welcomeHighFloating;
        animation-delay: .0s;
        animation-duration: 2.6s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        z-index: 1000;
    }

    .face-bottom-img {
        position: relative;
        width: 122px;
        top: 88px;
        left: -58px;
        animation: welcomeWiggle 2.6s ease-in-out infinite;
        animation-delay: 2s;
        z-index: 1000;
    }

    .GreetingContent {
        background-color: #fff;
        border-radius: 20px;
        width: 400px;
        padding: 40px;
        height: 120px;
        margin: 60px auto;
        font-size: 22pt;
        font-weight: 700;
        color: lighten(#0ea5e4, 4%);
        z-index: 100;

        opacity: 0;
        padding-top: 40px;
        transition: 0.5s all;
        z-index: 1000;

        .first-greeting {
            color: lighten(#0ea5e4, 4%);
            font-size: 24pt;
            padding-top: 16px;
        }
    }


}

.wave-img {
    position: fixed;
    width: 105%;
    left: -70px;
    top: 80px;

    z-index: -1;
    opacity: .3;

    animation-duration: 18s;
    animation-name: slideHand;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-timing-function: linear;
}

.ShowJuliaScreen .JuliaScreen {
    opacity: 1;
    transform: scale(1);
}

.ShowGreeting .GreetingContent {
    opacity: 1;
    padding-top: 80px;
}

.julia-face {}

.AnimateOut .JuliaScreen {
    opacity: 0 !important;
    margin-top: 0px !important;
    transition: 1.5s;
}

.AnimateOut .wave-img {
    opacity: 0 !important;
}

@keyframes slideHand {
    from {
        transform: scale(1);
        left: -10px;
    }

    to {
        //transform: scale(1.1);
        left: 68px;
    }
}

@keyframes welcomeWiggle {

    0%,
    7% {
        transform: rotateZ(0);
    }

    15% {
        transform: rotateZ(-15deg);
        filter: brightness(1.0);
        filter: brightness(1.1);
    }

    20% {
        transform: rotateZ(10deg);
    }

    25% {
        transform: rotateZ(-10deg);

    }

    30% {
        transform: rotateZ(6deg);

    }

    35% {
        transform: rotateZ(-4deg);

    }

    60% {
        filter: brightness(1.0);
    }

    40%,
    100% {
        transform: rotateZ(0);
    }
}

@keyframes welcomeHighFloating {
    0% {
        transform: translate(0px, 0px);
        filter: brightness(1.2);
    }

    30% {
        filter: brightness(1);
    }

    40% {
        transform: translate(0px, -29px);
        filter: brightness(1);
    }

    70% {
        filter: brightness(1);
    }

    100% {
        transform: translate(0px, -0px);
        filter: brightness(1.2);
    }
}
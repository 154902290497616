.ResponseComposer {



    padding: 64px 0 0 0;

    .composer-layout {
        display: flex;

        width: 100%;
        margin: 0px auto;

        .left-col {
            width: 49%;
            border-right: 0px solid darken(#e3e6f7, 2%);
            height: 2000px;
            transition: 0.25s;
        }

        .right-col {
            width: 50%;

            box-sizing: border-box;
            padding-left: 38px;

            text-align: left;
            transition: 0.25s;
        }

        .reframing-col {
            display: none;
        }

        .meter-col {
            display: none;
        }
    }

    .reframing-content {
        margin-top: 0 !important;
    }

    .belief-content {
        display: none;
    }

    .refresh-container {
        padding-right: 34px;
        transition: .3s all;
        cursor: pointer;
    }

    .refresh-container:hover {
        transform: scale(1.2);
        margin-top: 8px;
        margin-left: 8px;
    }

    .refresh-icon-img {
        width: 30px;
    }

    .refresh-button {
        color: darken(#0ea5e4, 2%);
        //color: #222;
        //color: #135ec4;
        border-radius: 24px;
        //margin: 8px 8px 4px 0;

        width: 160px;
        margin: 12px auto;
        //float: right;

        padding: 16px 0;

        //background-color: lighten(#d1eff7, 10%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .reframing-refresh-icon-img {
        width: 28px;
        margin-top: 8px;
    }

    .reframing-refresh-button {
        color: darken(#0ea5e4, 2%);
        //color: #222;
        //color: #135ec4;
        border-radius: 24px;
        //margin: 8px 8px 4px 0;

        width: 40px;
        margin: 0px auto;
        //float: right;

        padding: 26px 0;

        transition: .3s all;
        cursor: pointer;

        //background-color: lighten(#d1eff7, 10%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .reframing-refresh-button:hover {
        transform: scale(1.2);
    }

    .original-thought {


        padding: 1.5em 0 0 0;

        text-align: left;
        padding-right: 0px;
        padding-left: 0px;

        padding-top: 20px;

        display: inline-flex;
        flex-direction: column;

        .label {
            background: #797DEA;
            background: linear-gradient(to right, #878bec 0%, #4447A1 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 22pt;
            font-weight: 600;
        }

        .strike {
            width: 25%;
            background-color: lighten(#4447A1, 5%);
            height: 2.5px;
            margin-top: -19px;
            transition: 0.25s;
        }
    }

    .current-response {
        zoom: .85;
        font-size: 17pt;
        //transform: scale(0.95);

        border-bottom: 1px solid #eee;
        padding: 1em 120px;
        padding-top: 0;
        text-align: center;

        line-height: 1.5em;

        font-size: 19pt;
        font-weight: 500;

        padding: 2em 0;

        //padding-top: 1.05em;

        padding-top: 18px;
        padding-bottom: 18px;


        text-align: left;


        border-radius: 40px;
        //border: 2px solid #eee;
        //background-color: #fff;
    }

    .bonus-response {
        padding: 6px 0 6px 0;
    }

    .response {
        display: flex;
        align-items: flex-start;
        //align-content: flex-start;
        //justify-content: flex-start;
        //justify-items: flex-start;

        .checkmark {
            width: 18px;
            //align-self: center;

            margin: 8px 6px 0 0;
        }

        .label {
            //float: left;
            font-size: 16.5pt;

            padding: 0.2em 0px;
            padding-left: 0px;
            padding-top: 0;
            text-align: left;

            line-height: 1.5em;
            font-weight: 500;

            background: #0ea5e4;
            //background: linear-gradient(to right, #13ad6e 0%, #10c77b 50%, #13ad6e 100%);
            background: linear-gradient(to right, #0ea5e4 0%, #007bc2 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .composer-content-thoughtlog {
        zoom: 0.95;
    }

    .response-extra {
        display: flex;
        align-items: flex-start;

        .checkmark {
            width: 20px;
            //align-self: center;

            margin: 8px 6px 0 0;
        }

        .label {
            //float: left;
            font-size: 17.5pt;

            padding: 0.2em 0px;
            padding-left: 0px;
            padding-top: 0;
            text-align: left;

            line-height: 1.5em;
            font-weight: 500;

            background: #00bdb2;
            background: linear-gradient(to right, #00bdb2 30%, #11a299 75%);
            //background: linear-gradient(to right, #0ea5e4 0%, #007bc2 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .response-extra2 {
        display: flex;
        align-items: flex-start;

        .checkmark {
            width: 20px;
            //align-self: center;

            margin: 8px 6px 0 0;
        }

        .label {
            //float: left;
            font-size: 17.5pt;

            padding: 0.2em 0px;
            padding-left: 0px;
            padding-top: 0;
            text-align: left;

            line-height: 1.5em;
            font-weight: 500;

            background: #00ce7e;
            background: linear-gradient(to right, #00ce7e 0%, #00b06c 60%);
            //background: linear-gradient(to right, #00bdb2 30%, #11a299 75%);
            //background: linear-gradient(to right, #0ea5e4 0%, #007bc2 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .options-layout {
        width: 600px;
        margin-left: auto;
        //border-top: 2px solid darken(#e3e6f7, 2%);
        //height: 1000px;
    }

    .options-heading {
        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
        color: #677583;
        padding-top: 26px;

        padding-bottom: 4px;
        text-align: left;
    }

    .options-heading-indent {
        padding-left: 30px;
    }

    .options-heading-corrected {
        padding-top: 50px;
        padding-bottom: 4px;
    }

    .options {
        //width: 1180px;
        margin: 0px auto;
        padding-top: 8px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .option {
        width: 47.0%;
        zoom: 0.95;

        box-sizing: border-box;

        font-size: 12.5pt;
        font-weight: 500;
        padding: 1em 30px 1.25em 30px;

        text-align: left;
        //text-align: center;
        line-height: 1.45em;

        color: darken(#0ea5e4, 1%);
        color: darken(#0ea5e4, 4%);
        color: #0ea2df;
        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        margin: 8px 8px 4px 0;
        margin: 10px 10px -2px 0;

        background-color: lighten(#d1eff7, 11%); // Was 10% for long time
        //background-color: #fff;
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

        transition: 0.25s;
    }

    .option:hover {
        background-color: #fff;
        transform: scale(1.05);
        cursor: pointer;
    }

    .option.Highlighted {
        background-color: lighten(#ecf18f, 20%);
    }

    .done-button {
        float: left;
        font-size: 15pt;
        font-weight: 600;

        padding: 1em 2em;
        border-radius: 8px;

        background-color: #f2c08a;

        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        color: #fff;

        background: rgb(255, 222, 159);
        background: linear-gradient(180deg, #10c77b 0%, #13ad6e 100%);
        background: linear-gradient(180deg, lighten(#0ea5e4, 4%) 0%, lighten(#098dc4, 4%) 100%);

        transition: .2s all;

        cursor: pointer;
    }

    .done-button:hover {
        //background: linear-gradient(180deg, #0ea5e4 0%, #0ea5e4 100%);
        transform: scale(1.1);
        box-shadow: 2px 3px 3px 1px darken(#e3e6f7, 4%);
    }

    .done-more {
        display: flex;
        align-items: center;
        padding: 38px 0 20px 0;
        color: lighten(#677583, 10%);
        font-size: 10.5pt;
        transition: .25s all;

        zoom: 1.15;

        .keep-going-arrow-img {
            width: 18px;
            padding: 2px 2px 0 4px;
            opacity: 0.6;
        }

        .keep-going-cards-img {
            width: 40px;
        }

        .keep-going-label {
            padding: 0 0 0 8px;
            font-size: 11.5pt;
            color: #677583;
            font-weight: 600;
            opacity: 0.8;
        }
    }
}

.ResponseComposer.ShowSheet {
    .original-thought {
        padding-top: 0.75em !important;

        .label {
            font-size: 19.5pt;
        }

        .label-long {
            font-size: 17.5pt;
        }
    }

    .composer-layout {
        display: flex;

        width: 100%;
        margin: 0px auto;

        .left-col {
            width: 7.5%;
            border-right: 0px solid darken(#e3e6f7, 2%);
            height: 2000px;
            opacity: 0;
            display: none;
        }

        .right-col {
            width: 33%;
            min-width: 30%;
            margin-left: 7.5%;

            box-sizing: border-box;
            padding-left: 38px;

            text-align: left;
        }

        .reframing-col {
            display: block;
            max-width: 560px;
            width: 50%;

            box-sizing: border-box;
            padding-left: 24px;

            text-align: left;
        }

        .meter-col {
            display: block;
            width: 20%;

            margin-top: 0px;
        }
    }

    .options-heading-thought {
        //display: none;
    }

    .options-heading-belief {
        padding-left: 20px;
    }

    .composer-container {
        padding: 20px 32px 20px 34px;
        margin-top: 8px;
        margin-left: -12px;
        background-color: #fff;
        background-color: lighten(#d9ebff, 6%);
        background-color: #fff;
        //background-color: transparent;
        // background-color: transparent;
        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        box-shadow: none;
        border-radius: 12px;
        height: 522px;
        //zoom: .95;

        .composer-content {
            zoom: .8;
        }
    }

    .composer-container-reframing {
        padding: 20px 28px 20px 32px;
        background-color: #fff;
    }

    .reframe-meter-container {
        margin: 8px 0 0 -12px;
        padding: 0 0 0 40px;
        padding: 10px 30px 30px 30px;
        border-radius: 12px;
        background-color: lighten(#d9ebff, 6%);
        background-color: #fff;
    }

    .done-button {
        display: none;
    }

    .done-more {
        display: none;
    }
}

.ResponseComposer.IsAskingFirstMeter {
    .left-col {
        width: 0%;
    }

    .right-col {
        margin-left: 31%;
    }
}

.ResponseComposer.IsReframing {

    .option {
        //color: darken(#ee9b69, 9%) !important;
    }

    .original-thought {

        .label {
            background: #797DEA;
            background: linear-gradient(to right, #878bec 0%, #4447A1 100%);
            background: linear-gradient(to right, #ee9b69 0%, #ee9b69 40%, #e75e47 80%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .strike {
            opacity: 0;
        }
    }
}

.ResponseComposer.HasReframed {
    .composer-container-reframing {
        background-color: lighten(#d9ebff, 6%);
        background-color: #fff;
        // background-color: transparent;
        height: 40px;

    }

    .belief-content {
        display: block;
    }

    .meter-col {
        opacity: 0 !important;

    }

    .reframing-col {
        //zoom: 0.75;
        // max-width: 15%;
        // width: 15%;

        .ReframingList {
            .instructions {
                display: none;
            }
        }

        .reframing-refresh-button {
            display: none;
        }
    }

    .right-col {
        //transform: scale(0.75);
        //max-width: 10%;
        //width: 10%;
        //zoom: 0.75;
    }
}

// .composer-content-finishscreen {
//     display: none;
// }
// .ResponseComposer.IsFinishingScreen {
//     .right-col {
//         .composer-container {
//             background-color: #fff !important;
//             padding-right: 12px !important;
//         }

//         .composer-content-thoughtlog {
//             display: none;
//         }
//         .composer-content-finishscreen {
//             display: block;
//         }
//     }
// }



.ResponseComposer.IsFading {
    transition: all .7s;
    opacity: 0 !important;
}

.ResponseComposer.IsReframing {
    // .reframing-col {
    //     display: none !important;
    // }

    .reframing-content {
        display: none !important;
    }

    .reframe-thought-content {
        display: none !important;
    }
}


.BeliefMeter.meter-container {
    width: 240px;
    height: 579px;
}

.BeliefMeter {
    cursor: pointer;

    .floating-thoughts-container {
        position: absolute;
        cursor: pointer;
    }

    .floating-thought-bubble {
        width: 150px;
        height: 34px;
        position: relative;
        top: -300px;
        left: -176px;
        text-align: left;

        background-color: #fff;
        background-color: lighten(#dfe0f8, 5%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        border-radius: 40px;

        padding: 0px 16px;
        text-align: center;

        font-size: 11pt;
        font-weight: 600;
        color: #6368e7;
        //border: 2px solid #fafafa;

        height: 44px;
        line-height: 44px;
        text-align: center;
        padding-bottom: 4px;

        .thought-text {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
    }

    .floating-thought-bubble-new {
        left: 230px;
        text-align: center;
        color: #fd7c62;
        background-color: darken(#fefbef, 4%) !important;
        //border: 1px solid darken(#fefbef, 9%)
    }

    .floating-thought {
        position: relative;
        top: -300px;
        left: 10px;
        text-align: left;

        font-size: 9.5pt;
        font-weight: 600;
        color: #111;

        //opacity: 0.5;

        padding: 0px 0px;

        font-size: 11.5pt;
        padding: 10px;
        border-radius: 10px;
        color: #fff;
        //box-shadow: 1px 2px 3px 1px darken(#eee, 0%);
    }

    .floating-thought-new {
        left: 170px;
        color: #fd7c62;
        background-color: #fefbef !important;
        background-color: darken(#fefbef, 5%) !important;
        //border: 1px solid darken(#fefbef, 9%)
    }
}

.first-meter-container {
    float: left;
    margin-right: 40px;
    //margin-top: -14px;
    //margin-left: -16px;
    zoom: 0.95;
    zoom: 1.1;

    margin-top: 10px;
    margin-left: -16px;
}

.clearfix {
    clear: both;
}

.meter-heading {
    //width: 200px;
    margin: 0px 0 0 -400px;
    padding: 0px 0 0 4px;
    font-size: 11pt;
    font-weight: 700;
    color: darken(#0ea5e4, 4%);

    padding-top: 40px;
    line-height: 1.5em;

    margin-top: -120px;
    margin: 0;

    margin: -12px 0 0 0;
}

.new-thought {
    display: flex;
    padding: 25px 0 0 0px;

    .icon-img {
        width: 20px;
        align-self: center;
    }

    .label {
        padding-left: 5px;
        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
        color: #677583;
        color: #ed9b0b;
    }
}

.BeliefMeter.meter-container {
    padding: 20px 20px 20px 20px;
    margin-top: 8px;
    margin-left: 12px;
    background-color: #fff;
    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    border-radius: 12px;
    height: 580px;

    .meter-question {
        padding: 8px 20px 0 20px;
        text-align: center;
        font-size: 10.5pt;
        color: #666;
        font-weight: 700;
        line-height: 1.4em;
    }

    .meter-thought-layout {
        display: flex;
        display: none;
    }

    .meter-thought-col {
        width: 50%;
    }

    .meter-thought-repeat {
        padding: 18px 8px 8px 8px;
        //padding-bottom: 0;
        font-size: 11.5pt;
        font-weight: 700;
        color: darken(#797DEA, 5%);
        line-height: 1.4em;

        text-align: center;
    }

    .meter-thought-repeat-new {
        //padding-bottom: 0;
        //padding-top: 4px;
        //font-size: 10.5pt;
        font-weight: 700;
        color: lighten(#fd6f53, 3%);
        line-height: 1.4em;

        text-align: left;

        height: 40px;
    }

    .meter-numbers {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }

    .meter-thought-repeat-number {
        background-color: lighten(#d8d9f6, 7%);
        padding: 12px;
        border-radius: 10px;
        width: 40px;
        //margin: 4px auto;
        margin: 8px 4px;

        color: darken(#797DEA, 4%);
        color: #fff;
        font-weight: 700;
        float: right;
    }

    .meter-thought-repeat-number-new {
        float: left;
        background-color: lighten(#fbded9, 6%);
        background-color: darken(#fefbef, 1%);
        background-color: #ccf3be;
        background-color: #fff;
        color: lighten(#fd6f53, 3%);
        color: #fff;
        color: darken(#ccf3be, 20%);
        color: #555;

        padding-left: 12px;
    }

    .meter-fill {
        width: 180px;
        height: 400px;
        border: 2px solid #eee;
        border-radius: 20px;
        margin: 14px auto;

        background: rgb(13, 241, 115);
        background: linear-gradient(0deg, rgba(13, 241, 115, 0.7) 0%, rgba(244, 219, 55, 0.9) 58%, rgba(250, 171, 33, 1) 81%, rgba(250, 113, 22, 1) 100%);

        zoom: 0.95;
    }

    .meter-gauge {
        height: 17.4px;
    }

    .meter-gauge.HoverGauge {
        background-color: lighten(#10c77b, 0%);
        background-color: transparent;

        .label {
            color: #fff;
            font-weight: 600;
            text-align: center;
        }
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);

        .label {
            color: #888;
            //opacity: 0.6;
            font-weight: 700;
        }
    }

    .meter-gauge.NewThoughtHoverGauge {
        background-color: lighten(#ffe985, 20%);
        background: linear-gradient(90deg, rgba(254, 251, 239, 1) 0%, rgba(254, 246, 200, 1) 1%, lighten(#ffee8b, 10%) 66%, lighten(#ffee8b, 1%) 100%);
        //background: linear-gradient(90deg, rgba(254,251,239,1) 0%, rgba(254,246,200,1) 31%, lighten(#ffee8b, 10%) 100%);

        .label {

            color: lighten(#fd6f53, 13%);
            //opacity: 0.6;
            //font-weight: 700;
        }
    }

    .meter-gauge.ZeroHoverGauge {

        .label {
            color: #fff;
            font-weight: 700;
        }
    }

    .label {
        text-align: center;
    }

    .meter-gauge.OffGauge {
        background-color: #fff;
        //background-color: #fefbef !important;
    }


    .meter-gauge-top {
        //background-color: #ddd;
        border-radius: 20px 20px 0 0;
    }

    .meter-gauge-top.HoverGauge {
        background-color: #fff;
        background-color: lighten(#ffe985, 20%);
        background: linear-gradient(90deg, rgba(254, 251, 239, 1) 0%, rgba(254, 246, 200, 1) 1%, lighten(#ffee8b, 10%) 66%, lighten(#ffee8b, 1%) 100%);
        //background-color: #fefbef;
    }

    .meter-gauge-bottom {
        //background-color: #ddd;
        border-radius: 0 0 20px 20px;
    }

    .meter-reading {
        font-size: 13.5pt;
        font-weight: 700;
        color: #555;
        margin-top: -8px;
        text-align: center;
    }


    .label {
        position: relative;
        top: -8px;
        font-size: 10pt;
        color: #999;
    }
}

.BeliefMeter,
.completion-message {

    .rating-10 {
        background: linear-gradient(0deg, darken(#b1ee99, 17%) 0%, lighten(#b1ee99, 3%) 100%);
    }

    .rating-15 {
        background: linear-gradient(0deg, darken(#b7ec95, 17%) 0%, lighten(#b7ec95, 3%) 100%);
    }

    .rating-20 {
        background: linear-gradient(0deg, darken(#beec8e, 17%) 0%, lighten(#beec8e, 3%) 100%);
    }

    .rating-25 {
        background: linear-gradient(0deg, darken(#c5e988, 17%) 0%, lighten(#c5e988, 3%) 100%);
    }

    .rating-30 {
        background: linear-gradient(0deg, darken(#cae985, 17%) 0%, lighten(#cae985, 3%) 100%);
    }

    .rating-35 {
        background: linear-gradient(0deg, darken(#d1e881, 17%) 0%, lighten(#d1e881, 3%) 100%);
    }

    .rating-40 {
        background: linear-gradient(0deg, darken(#d7e67e, 17%) 0%, lighten(#d7e67e, 3%) 100%);
    }

    .rating-45 {
        background: linear-gradient(0deg, darken(#dde477, 17%) 0%, lighten(#dde477, 3%) 100%);
    }

    .rating-50 {
        background: linear-gradient(0deg, darken(#e3e273, 17%) 0%, lighten(#e3e273, 3%) 100%);
    }

    .rating-55 {
        background: linear-gradient(0deg, darken(#ebdf6e, 17%) 0%, lighten(#ebdf6e, 3%) 100%);
    }

    .rating-60 {
        background: linear-gradient(0deg, darken(#f1de6a, 17%) 0%, lighten(#f1de6a, 3%) 100%);
    }

    .rating-65 {
        background: linear-gradient(0deg, darken(#f0d361, 17%) 0%, lighten(#f0d361, 3%) 100%);
    }

    .rating-70 {
        background: linear-gradient(0deg, darken(#f0c859, 14%) 0%, lighten(#f0c859, 3%) 100%);
    }

    .rating-75 {
        background: linear-gradient(0deg, darken(#efc354, 12%) 0%, lighten(#efc354, 3%) 100%);
    }

    .rating-80 {
        background: linear-gradient(0deg, darken(#eebb4e, 11%) 0%, lighten(#eebb4e, 3%) 100%);
    }

    .rating-85 {
        background: linear-gradient(0deg, darken(#eeaf46, 10%) 0%, lighten(#eeaf46, 3%) 100%);
    }

    .rating-90 {
        background: linear-gradient(0deg, darken(#efa441, 9%) 0%, lighten(#efa441, 3%) 100%);
    }

    .rating-95 {
        background: linear-gradient(0deg, darken(#eb913c, 8%) 0%, lighten(#eb913c, 6%) 100%);
    }

    .rating-100 {
        background: linear-gradient(0deg, darken(#e97d35, 7%) 0%, lighten(#e97d35, 7%) 100%);
    }
}

.BeliefMeter.IsFirst {
    height: 500px !important;

    .floating-thoughts-container {
        display: none;
    }

    .floating-thought-new {
        opacity: 0 !important;
    }

    .floating-thought-bubble,
    .floating-thought-bubble-new {
        opacity: 0 !important;
    }

    .meter-thought-repeat {
        display: none;
    }

    .meter-question {
        padding-bottom: 16px;
    }

    .meter-gauge-top.HoverGauge {
        background-color: #fff;
        background: #fff;
        //background-color: #fefbef;
    }
}

.BeliefMeter.IsHoverNotActive {
    .meter-gauge {
        background-color: #fff !important;
        background: #fff !important;

        .label {
            color: #999 !important;
            opacity: 1 !important;
            font-weight: 400 !important;
        }
    }

    .floating-thought-new {
        opacity: 0 !important;
    }

    .floating-thought-bubble,
    .floating-thought-bubble-new {
        opacity: 0 !important;
    }
}



.new-rating-100 {
    background-color: #fefbef !important;
}

.new-rating-95 {
    background-color: #fefbef !important;
}

.new-rating-90 {
    background-color: #fefbef !important;
}

.new-rating-85 {
    background-color: #fefbef !important;
}

.new-rating-80 {
    background-color: #fefbef !important;
}

.new-rating-75 {
    background-color: #fefbef !important;
}

.new-rating-70 {
    background-color: #fefbef !important;
}

.new-rating-65 {
    background-color: #fefbef !important;
}

.new-rating-60 {
    background-color: #fefbef !important;
}

.new-rating-55 {
    background-color: #fefbef !important;
}

.new-rating-50 {
    background-color: #fefbef !important;
}

.new-rating-45 {
    background-color: #fefbef !important;
}

.new-rating-40 {
    background-color: #fefbef !important;
}

.new-rating-35 {
    background-color: #fefbef !important;
}

.new-rating-30 {
    background-color: #fefbef !important;
}

.new-rating-25 {
    background-color: #fefbef !important;
}

.new-rating-20 {
    background-color: #fefbef !important;
}

.new-rating-15 {
    background-color: #fefbef !important;
}

.new-rating-10 {
    background-color: #fefbef !important;
}
@import 'global';

$exploreColor: #00c4e1;

.TakeAssessmentScreen {
    opacity: 1;
    position: relative;
    transform: translateY(40px);
    transition: .5s;

    margin: -60px 0 0 80px;

    .assessment-heading {
        font-size: 25pt;
        font-weight: 600;
        color: $mainHeadingColor;
    }

    .active-questions {
        padding-top: 40px;
    }

    .assessment-content {
        transition: .3s;
    }

    .assessment-content.IsPageLoading {
        opacity: 0.5;
    }
}
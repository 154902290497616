@import 'global';

.QuestionCard-position {
    //position: relative;
    height: 158.5px;

    transition: .3s;

    //transform: scale(.9);
}

.QuestionCard {
    margin: 2px 16px 0px 12.5px;

    align-items: center;

    height: 148px;
    //background-color: #fff;
    border-radius: 9px;
    //box-shadow: 0.5px 0.5px 2px 0px lighten(#decda7, 2%);

    z-index: 100;
    //opacity: 0;
    transition: .3s !important;
    transform-origin: center center !important;

    //background-color: transparent;
    //box-shadow: none;

    .main-content {
        display: flex;
        height: 48px;
        align-content: center;

        width: 380px;
    }

    .expanded-content {
        display: none;

        transform: scale(.85);
        transform-origin: top left;

        margin: -0px 0 0 -12px;
        padding: 0 0 0 22px;
    }

    .icon {
        width: 14px;
        align-self: center;
        padding: 0 6px 0 18px;
        margin-top: -16px;
    }

    .number {
        font-size: 9.5pt;
        font-weight: 700;
        color: lighten($themeYellowFontColor, 20%);
        color: darken($dropBackgroundColor, 40%);
    }

    .question {
        padding: 0px 14px 4px 12px;
        font-size: 9.25pt;
        font-weight: 500;
        color: $voiceColor;
        color: #333;
        color: darken($dropBackgroundColor, 70%);
        color: #444;
        text-align: left;
    }
}

.QuestionCard.NewFadedOut {
    position: relative;
    transform: translateY(40px) !important;
    opacity: 0;
    //transition: .7s !important;
}

.QuestionCard.IsCompleted {
    position: relative;
    //transform: translateY(40px) !important;
    opacity: 1;
    transform: scale(.95) !important;
    //transform-origin: top left !important;
    //height: 0;
    //transition: .7s !important;

    .question {
        color: $themeTealFontColor !important;
    }
}

.QuestionCard.IsExpanded {
    //position: absolute;
    height: 162px;
    width: 428px;
    z-index: 1001;
    transition: .15s;
    transform: scale(1.1);
    transform-origin: top center;
    //box-shadow: 1.75px 1.75px 3.5px 0.5px darken($backgroundColor, 1.5%);


    .main-content {
        padding: 8px 0;
        transform: scale(1.15);
        transform-origin: top left;
    }

    .expanded-content {
        display: flex;
    }
}
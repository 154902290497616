@import 'global';

$exploreColor: #00c4e1;

.TurnInStackScreen {
    opacity: 1;
    position: relative;
    transform: translateY(40px);
    transition: .5s;

    margin: -52px 0 0 100px;


    .screen-heading {
        font-size: 25pt;
        font-weight: 700;
        color: $mainHeadingColor;
        color: $themePinkFontColor;
        text-align: center;
    }

    .screen-subheading {
        font-size: 11.5pt;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: $mainHeadingColor;
        color: $themePinkMediumFontColor;
        text-align: center;
    }

    .donations-sheet {
        width: 780px;
        height: 620px;

        margin: 0px 0 0 0;
        border-radius: 24px;

        //background-color: darken($creamColor, 6%);
        //box-shadow: 1px 1px 12px 2px lighten(#d2c8a5, 12%);
    }

    .choose-heading {
        padding: 12px 0 0 0;
        font-size: 11.0pt;
        font-weight: 600;
        //text-decoration: underline;

    }


    .charity-done-button {
        opacity: 0;
        color: $themePinkBackgroundColor;
        font-weight: 600;
        background-color: $themePinkFontColor;
        color: #fff;
        width: 340px;
        margin: 8px auto;
        margin-top: -276px;
        padding: 12px;
        border-radius: 100px;
        transform: scale(.9);
        transition: .3s;

        &:hover {
            cursor: pointer;
            transform: scale(1.15) !important;
            background-color: darken($themePinkFontColor, 6%);
        }
    }

    &.ShowDoneButton {
        .charity-done-button {
            opacity: 1;
            transform: scale(1.05);
        }
    }

    .donations-heading {
        display: flex;
        padding: 12px 0 8px 0;


        justify-content: center;
        align-items: center;

        .h-text {
            padding: 0 6px;
            color: #333;
            font-family: $serifFamily;
            font-weight: 500;
            font-size: 14.5pt;
        }

        .h-dollars {
            padding: 8px 12px;
            border-radius: 100px;
            font-family: $sansFamily;
            font-size: 15pt;
            font-weight: 700;
            color: #4bd868;
            background-color: #fff;
            border: 2.5px solid lighten(#4bd868, 28%);
        }
    }

    .charity-cards {
        padding: 8px 0 0 0;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }


}

.CharityCard {
    width: 280px;
    height: 184px;
    background-color: darken($creamColor, 1%);
    border-radius: 18px;

    //border: 3px solid darken($creamColor, 8%);
    box-shadow: 1px 1px 4px 1px rgba(#ddd2b6, .6);

    margin: 6px 10px;

    padding: 24px 32px;
    transition: .2s;

    &:hover {
        cursor: pointer;
        background-color: lighten($creamColor, 1%);
        transform: scale(1.025);
    }

    &.IsSelected {
        background-color: lighten($themePinkBackgroundColor, 10%) !important;
        transform: scale(1.025);
    }

    .main-content {
        display: flex;
        align-items: flex-start;
    }

    .photo-content {
        min-width: 60px;
        max-width: 60px;
        margin-left: auto;

        .charity-photo-img {
            width: 100%;
            border-radius: 12px;
        }
    }

    .charity-content {
        margin: -8px 0 0 -8px;
        padding: 0 0 0 0px;

        .charity-logo-img {
            width: 82px;
            border-radius: 12px;
            border: 2px solid darken($creamColor, 12%);
        }
    }

    .details-content {
        height: 58px;

        .total-donations {
            display: none;
            background-color: #fff;
            border: 2px solid darken($creamColor, 12%);
            width: 100%;
            margin-left: -5%;
            border-radius: 100px;
            padding: 4px 12px;
            font-size: 9.5pt;
            text-align: left;
            font-weight: 600;
        }

        .reach-phrase {
            font-size: 8pt;
        }

        .dollars-phrase {
            color: darken(#4bd868, 8%);
            font-weight: 800;
            //text-decoration: underline;
        }

        .charity-about {
            padding-top: 6px;
            font-size: 9.5pt;
            text-align: left;
            opacity: .85;
        }

        .charity-phrase {
            font-weight: 700;
        }
    }

    .reach-content {
        padding-top: 12px;
        font-size: 8pt;
        font-weight: 800;

        .reach-phrase {
            color: $themeYellowMediumFontColor;
        }

        .reach-amount {
            padding-top: 0px;
            text-align: right;
            font-size: 9.5pt;
            font-weight: 600;
            color: $themeYellowMediumFontColor;

            .current-amount {
                //margin-top: -4px;
                padding: 4px 0px;
                //background-color: #fff;
                border-radius: 100px;
                // border: 2px solid darken($creamColor, 10%);
                color: darken(#4bd868, 12%);
                text-decoration: underline;
                font-weight: 800;
            }
        }

        .reach-progress-bar {
            margin-top: 8px;
            margin-bottom: 8px;
            height: 6px;
            background-color: darken($creamColor, 10%);
            border-radius: 100px;
        }

        .reach-progress-fill {
            height: 6px;
            background-color: darken($creamColor, 30%);
            border-radius: 100px;
        }
    }
}
@import 'global';

$landingBorderRadius: 32px;

.AssessmentSidebar {
    transition: .4s;

    margin: 0 20px;

    position: fixed;
    z-index: 1;

    width: $dropColumnWidth;
    height: 714px;
    top: 108px;
    left: 6px + $sidebarWidth + $sidebarSpace;
    border-radius: 40px;
    background-color: lighten($dropBackgroundColor, 0%);
    background-color: #fff;

    .slots {
        width: 460px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
    }

    .slot-placeholder {
        width: 47%;
        height: 80px;
        margin: 3px 6px;
        background-color: desaturate(lighten($themeTealBackgroundColor, 1.0%), 20%);
        //background-color: lighten(#f0f4f4, 2%);
        //background-color: $dropBackgroundColor;
        border-radius: 18px;
    }

    .slot {
        width: 47%;
        height: 80px;
        margin: 3px 6px;
        background-color: #f9f9f9;
        //background-color: lighten(#f0f4f4, 2%);
        //background-color: $dropBackgroundColor;
        border-radius: 18px;

        opacity: 1;
        transition: .5s;
        transform: translateY(0px);

        .question-content {
            display: flex;
            padding: 8px 16px;
        }

        .score-content {
            position: relative;

            .score {
                position: absolute;
                top: 43px;
                left: 160px;
                text-align: center;

                width: 40px;
                padding: 5px;
                background-color: darken(#f0ffff, 2.5%);
                color: darken(#3fefff, 26%);
                font-weight: 700;
                font-size: 10.5pt;
                border-radius: 12px;
            }
        }

        .number {
            font-size: 9.5pt;
            font-weight: 700;
            color: lighten($themeYellowFontColor, 20%);
            color: darken($dropBackgroundColor, 40%);
        }

        .question {
            padding: 0px 20px 4px 4px;
            font-size: 8.25pt;
            font-weight: 500;
            color: $voiceColor;
            color: #333;
            color: darken($dropBackgroundColor, 70%);
            color: #444;
            text-align: left;
        }
    }

    .slot.Completed {
        background-color: #fff;
        box-shadow: 0.5px 0.5px 2px .5px rgba(darken(#ebdac1, 16%), .55);
    }

    .slot.IsFaded {
        opacity: 0;
        transform: translateY(40px);
        transition: 0s;
    }
}
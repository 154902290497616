@import 'global';

//$personalColor: darken(#5bd143, 5%);
$personalColor: lighten(#5bd143, 41%);
$personalFontColor: darken(#5bd143, 18%);

$goalColor: $voiceColor;

.two-areas-phrase {
    color: $themePinkFontColor;
    text-decoration: underline;
}

.AboutMeScreen {
    .main-layout {
        display: flex;
        width: 1260px;
        //width: 100%;
        margin: 28px auto 0px auto;
        padding-left: $sidebarWidth - 40px;
        //align-items: center;

        .grade-col {
            max-width: 600px;
            min-width: 600px;
        }

        .list-col {
            max-width: 300px;
            min-width: 300px;
        }

        .ai-col {
            max-width: 260px;
            min-width: 260px;
            margin-left: 12px;

            margin-top: 110px;
            margin-bottom: 20px;

            .sheet {
                box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 2%);
                box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);


                animation: jumpIn 0.6s ease 0s 1;
            }
        }

        .ai-col-left {
            margin-left: 0;
            margin-right: 12px;
        }
    }

    .sheet {
        transform: scale(1.04);
        transform-origin: center center;
        background-color: #fffcf7;
        padding: 20px 0px 80px 12px;

        margin: 0px auto 40px auto;
        border-radius: 28px;

        text-align: center;

        animation: jumpIn 1s ease 0s 1;
    }

    .sheet-content.IsPageLoading {
        opacity: .6;
    }

    .sheet-heading {
        display: flex;

        padding-top: 1.25em;
        margin-left: .65em;
        font-size: 12.5pt;
        font-weight: 700;
        color: darken(#0ea5e4, 1%);
        //color: lightsalmon;
        color: #333;
        text-align: left;

        align-items: center;

        .julia-face-img {
            width: 28px;
            padding-right: 8px;
        }

        .label {}
    }



    .work-scene-position {
        position: absolute;
        height: 0px;
        z-index: 1000;
    }

    .scene-svg {
        position: relative;
        top: -106px;
        left: 276px;
        width: 268px;

        // top: -208px;
        // left: 570px;
        // z-index: 1000;
    }

    .personal-scene-svg {
        position: relative;
        top: -148px;
        left: 424px;
        width: 208px;
    }

    .questions-position {
        position: relative;
    }

    .questions-sheet {
        position: absolute;

        width: 220px;
        height: 290px;
        height: 190px;
        //background-color: #eee;

        top: -348px;
        left: 340px;

        left: 606px;
        left: 592px;

        transform: scale(1.025);
        transform-origin: top left;

        background-color: lighten($backgroundColor, 2%);
        background-color: transparent;
        padding: 20px 24px;
        padding-top: 0;
        border-radius: 24px;

        text-align: left;
    }

    .heading-content {
        display: flex;
        align-content: center;
        align-items: center;
    }

    .pen-area {
        // width: 38px;
        // height: 38px;
        // margin: 18px 0 12px 0;
        // border-radius: 12px;
        // background-color: #fff;
        // //box-shadow: 1px 1px 3px 0px darken(#e3e6f7, 4%);
        // border: 1.5px solid darken($backgroundColor, 4%);
        // transform: scale(.925);
    }

    .pen-img {
        width: 42px;
        text-align: left;
        margin: 8px 0 0 8px;
    }

    .help-content {
        position: absolute;

        .AiMention {
            top: 100px;
            left: -386px;
        }

        .skip-instructions {
            position: relative;
            color: #0ea5e4;
            font-size: 9.75pt;
            font-weight: 600;
            top: -148px;
            left: 77px;
            text-align: left;
        }

        .ai-arrow-img {
            position: relative;
            width: 44px;
            top: -214px;
            left: 418px;
            //animation: bounceIn 2.2s 2 0s;
        }
    }

    .questions-list {
        padding: 0px 0;
        padding-bottom: 40px;

        .question {
            padding: 0.5em 0em;
            padding-right: 20px;
            font-size: 10.0pt;
            font-weight: 500;
            line-height: 1.45em;
            text-align: left;
            color: darken(#0ea5e4, 2%);
            color: lighten($negativeColor, 20%);
        }
    }

    .answer-fill {
        background-color: #7a7ceb;

        margin: 0px auto;
        //width: 50px;
        height: 308px;
        font-size: 13.5pt;
        padding: 1em 1.5em;

        padding: 26px 12px;
        padding-left: 26px;

        border-radius: 12px;
        text-align: left;

    }

    .life-title {
        color: $negativeColor;
        font-size: 19pt;
        font-weight: 700;
    }

    .life-title-personal {
        color: $personalFontColor !important;
    }

    .about-answer {

        //border: 2px solid lighten(#0ea5e4, 44%);
        background-color: lighten(#0ea5e4, 50%);



        text-align: left;

        font-weight: 500;
        color: darken(#0ea5e4, 11%);
        outline: none;
        border: 0;
        font-size: 12.75pt;
        //font-size: 12.75pt;
        font-family: "Merriweather", ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";

        width: 422px;
        height: 284px;
        background-color: #7a7ceb;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 500;
        line-height: 1.45em;
        //border-right: 1.0px solid #f3f3f3 !important;
        //background-color: #eee;
        //background: linear-gradient(to bottom, #878bec 0%, #4447A1 100%);
        color: #fff;

        //color: #222 !important;
    }

    .work-about-head-img {
        width: 32px;
        align-self: center;

        margin: -7px 17px 0 17px;
    }

    .compose-icon-position {
        position: absolute;
    }

    .compose-icon-img {
        position: relative;
        width: 48px;
        top: -328px;
        left: 8px;
    }

    .examples-layout {
        display: flex;
        display: none;
        opacity: 0;

        .questions-col {
            width: 40%;
            min-width: 40%;
        }

        .examples-col {
            width: 60%;
            min-width: 60%;
        }
    }

    .examples-heading {
        padding: 4em 0 0 0;
        //margin-left: 134px;
        margin-left: 32px;
        font-size: 10pt;
        color: #999;
        color: darken(#0ea5e4, 1%);
        font-weight: 700;
        text-align: left;

        font-size: 12.5pt;
        font-weight: 800;
    }

    .questions-heading {
        margin-left: 0;
        padding: 6px 0 0 8px;
        color: lighten(#ffc088, 10%);
        color: #333;
        color: #0d97d1;
        color: darken($reinforceColor, 0%);
        color: lighten($negativeColor, 12%);
        font-size: 10.5pt;
        font-weight: 700;
        opacity: 1;

        .like-word {
            font-weight: 500;
        }
    }

    .about-examples {
        //padding: 0px 36px;
        //margin: 0px auto;
        margin-top: -4px;
        transform: scale(0.95);
        //display: none;

        .example-name {
            padding-top: 12px;
            padding-bottom: 4px;
            padding-left: 20px;
            font-size: 11.5pt;
            font-weight: 800;
            text-align: left;
            color: #878bec;
        }

        .example {
            //width: 480px;
            margin-right: 24px;
            margin: 2px auto;
            padding: 1.25em 1.5em;
            padding-top: 0;
            font-size: 12.5pt;
            color: #555;
            color: darken(#0ea5e4, 1%);
            font-weight: 400;
            text-align: left;

            border-radius: 12px;

            //background: linear-gradient(to bottom, #878bec 0%, #4447A1 100%);
            background-color: lighten(#878bec, 0%);
            //background-color: lighten(#878bec, 22%);
            color: #878bec;
            color: #fff;

            // background-color: #fff;
            // color: #878bec;
            font-weight: 500;
        }

        .divider {
            color: #bbb;
            color: darken(#0ea5e4, 1%);
        }
    }

    .done-button-position {
        position: absolute;
        height: 10px;
    }

    .done-button {
        position: relative;

        top: -48px;
        left: 60px;
        //left: 32px;

        width: 120px;
        //margin: 12px auto;
        margin-left: auto;
        margin-right: 80px;

        background-color: lighten(#0ea5e4, 4%);
        color: #fff;

        font-weight: 700;
        font-size: 11.5pt;

        padding: 1.15em;
        border-radius: 100px;

        font-size: 11pt;
        padding: 0.85em;

        transform: scale(1.05);
        transition: .2s;

        cursor: pointer;

        //animation: jumpIn 0.6s ease 0s 1;
    }

    .done-button:hover {
        transform: scale(1.15) !important;
    }

    .face-img {
        width: 76px;
    }

    .julia-heading {
        //border-top: 2px solid #fafafa;
        padding-top: 1.5em;
        font-size: 14pt;
        font-weight: 700;
        color: darken(#0ea5e4, 1%);

        padding-top: 0.5em;
        font-size: 11.5pt;
    }

    .julia-subheading {
        padding-top: 0.5em;
        font-size: 11.5pt;
        font-weight: 600;
        color: darken(#0ea5e4, 1%);

        padding-top: 1em;
        font-size: 14pt;
    }



    .AiGreeting,
    .AiMention {
        //opacity: 0;
        //transform: scale(0.5);
        transition: 1s all;

        margin-top: -18px;
        margin-left: 0px;

        position: relative;
        left: -214px;
        z-index: 3000;

        position: fixed;
        top: 150px;
        left: 24px;

        .steps-content {
            //border: 2px solid $backgroundColor;
            border-left: 0;
            border-right: 0;
            width: 200px;
            position: relative;

            //top: -110px;
            //left: 362px;
            top: 4px;
            left: 0px;
            //opacity: 0;

            transform: scale(1.05);

            .steps {
                font-size: 11.5pt;
                font-weight: 600;
                color: lighten(#0ea5e4, 8%);
            }

            .step-phrase {
                font-weight: 800;
                //text-decoration: underline;
            }

            .step-title {
                margin: 4px 34px 0 34px;
                border-top: 1.5px solid lighten($positiveAccentColor, 40%);
                border-top: 1.5px solid $backgroundColor;
                padding: 4px 0 0 0;
                font-weight: 800;
                font-size: 10.5pt;
                color: darken(#0ea5e4, 0%);
                color: lighten($negativeColor, 15%);
            }

            .step-instructions {
                margin: 4px 14px 0 14px;
                padding: 14px 0 0 0;
                font-weight: 500;
                font-size: 9.75pt;
                color: darken(#0ea5e4, 0%);
                color: lighten($negativeColor, 22%);
            }
        }

        .face-img {
            margin-top: 110px;
            margin-left: 50px;
            margin: 50px auto 10px auto;
            width: 50px;
            z-index: 100;
            //opacity: 0 !important;




            position: relative;
            top: 112px;
            left: 352px;
        }

        .face-top-img {
            position: relative;
            top: 106px;
            left: 381.5px;
            //animation: welcomeWiggle 2.5s ease-in-out infinite;


            animation-name: welcomeFloating;
            animation-delay: .0s;
            animation-duration: 2.3s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }

        .face-bottom-img {
            position: relative;
            top: 108px;
            left: 331px;
            animation: welcomeWiggle 2.3s ease-in-out infinite;
            animation-delay: 2s;
        }

        .GreetingContent {
            background-color: transparent;

            border-radius: 20px;
            width: 190px;
            padding: 0px 0px;
            margin: 24px auto;
            font-size: 12.0pt;
            font-size: 11.5pt;
            line-height: 1.65em;
            font-weight: 600;
            color: darken(#0ea5e4, 0%);
            z-index: 100;

            transition: 0.5s all;

            width: 290px;
            text-align: left;
            position: relative;
            top: 20px;
            left: 544px;
        }

        .wave-img {
            position: fixed;
            width: 200px;
            left: 100px;
            margin-top: 118px;
            margin-left: 280px;
            //top: 590px;
            z-index: -100;
            opacity: 0;

            //opacity: 0;

            animation-duration: 4s;
            animation-name: aboutMeSlideHand;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }
    }
}

.AboutMeScreen {

    .sheet-heading {
        display: block;
        padding: 20px 0 28px 0;
        margin: 0;

        color: #87c0ff;
        color: darken($negativeColor, 6%);
        color: #444;
        color: $goalColor;
        color: lightsalmon;
        color: #333;
        //color: #333;

        //color: $reinforceColor;
        font-size: 11.5pt;
        font-weight: 500;
        //font-family: Georgia, 'Times New Roman', Times, serif;
        //font-family: 'Merriweather', serif !important;
        font-size: 19.5pt;
        text-align: center !important;
        font-weight: 800;
    }

    .goals-instructions {
        padding: 28px 0 0 0;
        color: $themeTealFontColor;
        font-size: 10.25pt;
        font-weight: 500;

        opacity: 0;
        display: none;
    }

    .ExplorePage {
        padding: 0px 0;
    }

    .goals-content {
        width: 90%;
        max-width: 580px;
        margin: 0 auto;
        padding: 0 0 20px 0;
    }

    .goals-pagehead {
        margin: 0 0 14px 0;
        border-bottom: 2px solid darken($backgroundColor, 0%);
        padding: 10px 0px 8px 0;
        text-align: right;
        font-size: 9pt;
        //font-family: 'Merriweather', serif;
        color: darken(#87c0ff, 8%);
    }

    // My name's Ei (like eye), your AI guide for exploring your mind. 
    // 

    .goals-heading {
        //margin: -66px 0 20px 0;
        color: darken(#ffc27d, 16%);
        color: #87c0ff;
        color: darken($negativeColor, 0%);

        //color: $reinforceColor;
        font-size: 11.5pt;
        font-weight: 500;
        //font-family: Georgia, 'Times New Roman', Times, serif;
        //font-family: 'Merriweather', serif !important;
        font-size: 19pt;
        text-align: center;
        font-weight: 800;

        .icon-img {
            width: 24px;
        }

        .skill-phrase {
            font-weight: 500;
            font-family: $sansFamily;
            //text-decoration: underline;
        }

        .skill-phrase-focus {
            color: darken(#FFC695, 14%);
        }
    }

    .goals-subheading {
        padding: 8px 60px 0 0;
        color: darken(#ffc27d, 16%);
        color: darken(#87c0ff, 4%);
        //color: darken($negativeColor, 10%);

        //color: $reinforceColor;
        font-size: 10.0pt;
        font-weight: 500;
        //font-family: Georgia, 'Times New Roman', Times, serif;
        //font-family: 'Merriweather', serif !important;
        font-size: 12pt;
        text-align: left;
    }

    .two-areas-phrase {
        font-weight: 700;
        //text-decoration: underline;
    }

    .goals {
        display: flex;
        flex-wrap: wrap;
        padding: 12px 0 0 0;

        .GoalExploreCard {
            width: 48%;
            min-width: 48%;
            max-width: 48%;
            height: 138px;
            margin: 2.5px 1%;
            box-sizing: border-box;

            //border: 4px solid lighten($positiveAccentColor, 48%);
            //background: lighten($positiveAccentColor, 59.5%);
            border-radius: 24px;
            padding: 20px 8px 20px 20px;
            transition: .3s;
            cursor: pointer;

            border: 2px solid #fffcf7;

            //border: 2px solid lighten(lightsalmon, 22%);

            .title-content {
                display: flex;
                // justify-content: center;
                // align-items: center;
                padding: 8px 0 0 0;
                //height: 100%;

                zoom: 1.075;
            }

            .icon-img {
                width: 24px;
                margin: 0 6px 0 0;
                align-self: center;
            }

            .title {
                color: darken($backgroundColor, 20%);
                //font-size: 15pt;
                font-weight: 500;

                padding: 0px 0 0 0;
                //font-family: 'Merriweather';
                font-weight: 700;
                font-size: 14.75pt;
                font-size: 13.25pt;
                color: $negativeColor;
                color: $reinforceColor;
                color: $goalColor;
                color: lightsalmon;
                color: #333;
                color: $themePinkFontColor;
                // color: #444;
                //color: #40b8e9;
                text-align: left;

                text-decoration: underline;
                text-decoration-color: lighten($positiveAccentColor, 60%);

                transition: .3s;
            }

            .goal-description {
                //display: none;
                padding: 8px 0 8px 34px;
                color: darken($positiveAccentColor, 0%);
                color: darken($goalColor, 0%);
                color: #444;
                color: darken($goalColor, 0%);
                color: lightsalmon;
                color: #333;
                color: $themePinkMediumFontColor;
                //color: #999;
                font-size: 10.5pt;
                //font-family: 'Merriweather';
                font-weight: 500;
                text-align: left;
                line-height: 1.4em;
            }
        }

        .GoalExploreCard:hover {
            background: lighten($positiveAccentColor, 48.5%);

            .title {
                text-decoration: underline;
                text-decoration-color: lighten($positiveAccentColor, 36%);
                text-decoration-thickness: 2px;
            }
        }

        .GoalExploreCard.IsSelected {
            border: 4px solid lighten($positiveAccentColor, 44%);
            background: lighten($positiveAccentColor, 50.5%);
            background: linear-gradient(0deg, rgba(255, 234, 171, 1) 0%, rgba(255, 255, 255, 1) 71%);
            background: linear-gradient(0deg, lighten(rgba(255, 242, 201, 1), 7%) 0%, rgba(255, 255, 255, 1) 71%);

            border: 2px solid darken(#ffedd1, 8%) !important;
            border-top: 2px solid darken(#fff5e6, 6%) !important;
            border-left: 2px solid darken(#fff5e6, 6%) !important;

            .title {
                color: lighten($positiveAccentColor, 10%);


                text-decoration: underline;
                text-decoration-color: lighten($positiveAccentColor, 30%);

                color: $themeYellowMediumFontColor;
            }

            .goal-description {
                color: lighten($positiveAccentColor, 10%);
                color: $themeYellowMediumFontColor;
                transition: .3s;
            }
        }
    }

    .goals-done-button {
        margin: -4px auto 10.5px auto;
        //background-color: darken($backgroundColor, 28%);
        width: 150px;

        border-radius: 12px;
        font-size: 13.5pt;
        font-weight: 500;
        color: #777;
        color: $themePinkFontColor;
        padding: 1.0em;
        text-decoration: underline;

        opacity: 1;
        opacity: 0;
        transition: .5s;

        cursor: pointer;

        &:hover {
            color: #333 !important;
        }
    }
}

@keyframes aboutMeSlideHand {
    from {
        transform: rotateZ(-1deg);
        transform: scale(1.0);
        left: -10px;
        top: 3px;
    }

    to {
        transform: rotateZ(-5deg);
        transform: scale(1.05);
        left: 0px;
        top: -3px;
    }
}

$workColor: lighten(#4b52df, 39%);
//$workColor: lighten(#d9ebff, 5%);
$workFontColor: darken(#4b52df, 1%);

.AboutMeScreen .WorkSheet {

    .answer-fill {
        color: $negativeColor;
        background-color: $workColor;
        background-color: #fff;
        //border: 2px solid $workColor;
    }

    .about-answer {
        color: $negativeColor;
        //color: #333;
        background-color: $workColor;
        background-color: #fff;
        padding-left: 52px;
    }

    .about-answer::placeholder {
        color: lighten($negativeColor, 26%);
    }

    .about-examples {
        .example-name {
            color: $negativeColor;
        }

        .example {
            color: $negativeColor;
            background-color: $workColor;
            background-color: #fff;
        }
    }

    .questions-list {
        .question {
            // color: darken($negativeColor, 2%);
            // color: #111;
        }
    }
}

.AboutMeScreen.HasStartedAnswer {
    .help-content {
        opacity: 0;
        transition: 2s;
    }
}

.AboutMeScreen.ShowDoneButton {
    .skip-button {
        opacity: 0;
        transition: .3s;
    }

    .goals-done-button {
        opacity: 1;
    }
}





.AboutMeScreen {
    .WorkSheet.PersonalSheetChanges {
        .life-title {
            color: $personalFontColor !important;
        }

        .AiMention {
            top: 116px !important;
        }

        .ai-arrow-img {
            top: -200px !important;
            //animation: bounceIn 2.2s 2 0s;
        }

        .scene-svg {
            top: -76px !important;
            left: 276px;
            width: 248px !important;
        }

        .about-answer {
            color: $personalFontColor;
        }

        .about-answer::placeholder {
            color: lighten($personalFontColor, 40%);
        }

        .questions-heading {
            color: lighten($personalFontColor, 6%) !important;
        }

        .question {
            color: lighten($personalFontColor, 6%) !important;
        }
    }
}


// OBSOLETE
.AboutMeScreen .PersonalSheet {

    .answer-fill {
        color: $personalFontColor;
        background-color: $personalColor;
    }

    .about-answer {
        color: $personalFontColor;
        background-color: $personalColor;
    }

    .about-examples {
        .example-name {
            color: $personalFontColor;
        }

        .example {
            color: $personalFontColor;
            background-color: $personalColor;
            background-color: #fff;
        }
    }

    .questions-heading {
        color: lighten(#0ea5e4, 38%);
        color: lighten(#e8ba2e, 30%);
    }

    .questions-sheet {
        background-color: darken(#35c264, 0%);
        background-color: lighten(#0ea5e4, 4%);
        background-color: #e8ba2e;
    }

    .questions-list {
        .question {
            color: darken($personalFontColor, 2%);
        }
    }
}


@keyframes jumpIn {
    0% {
        margin-top: 60px;
        opacity: 0;


    }

    100% {
        margin-top: 0px;
        opacity: 1;

    }
}

@keyframes welcomeFloating {
    0% {
        transform: translate(0px, 0px);
        filter: brightness(1.2);
    }

    30% {
        filter: brightness(1);
    }

    40% {
        transform: translate(0px, -9px);
        filter: brightness(1);
    }

    70% {
        filter: brightness(1);
    }

    100% {
        transform: translate(0px, -0px);
        filter: brightness(1.2);
    }
}

@keyframes bounceIn {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        opacity: 1;
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-10px);
    }
}
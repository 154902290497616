@import 'global';

// Was 788px. Not sure yet.


$width: 800px;

.NegativeGrid {
    flex: 1;
    width: $width;
    min-width: $width;
    max-width: $width;

    margin-left: -6px;
    margin-top: -28px;
    margin-top: -14px;
    margin-top: 16px;
    //margin-top: -6px;

    padding-left: -2px + 18px + $sidebarWidth + $sidebarSpace;

    transition: .3s;

    transform: scale(.975);
    transform: scale(.91); // was .935
    transform-origin: top left;

    //zoom: 1.05;

    //opacity: .6;

    .greeting-content {
        margin-top: -44px;
        transform: scale(1.1);
        transform-origin: top left;

        padding-bottom: 14.5px;

        .greeting-bubble {
            display: flex;
            border-radius: 18px;
            padding: 0px 0 0 26px;
        }

        .plant-img {
            width: 20px;
            align-self: center;
        }

        .greeting-label {
            padding: 6px 0 0 4px;
            font-family: $serifFamily;
            font-size: 13pt;
            font-weight: 700;

            color: #333;
            color: $mainHeadingColor;

            .date-phrase {
                color: desaturate(darken(#ffeca7, 10%), 30%);
                //color: #aaa;
                opacity: .8;
                color: #fff;
            }
        }
    }

    .headings {
        position: relative;
    }

    .heading-column {
        position: absolute;
        top: 28px;
        left: 28px;
        width: 334px;
        border-bottom: 2.5px solid rgba($mainHeadingColor, .2);
        border-bottom: 2px solid darken($dropBackgroundColor, 12%);
        //border-bottom: 2.5px solid darken($dropBackgroundColor, 6%);
        //border-bottom: 2.5px solid #eee;
        padding-bottom: 6px;

        //font-family: $serifFamily;
        font-size: 11.5pt;
        font-weight: 500;

        color: darken($mainHeadingColor, 6%);
        //color: darken($dropBackgroundColor, 52%);
        //color: $themeTealFontColor;

        // color: darken($themeYellowBackgroundColor, 60%);
        // font-size: 15pt;

        //color: #222;
        //opacity: 0;
        //color: #333;

        //color: darken(#ebc43d, 14%);
        //border-bottom: 2.5px solid lighten(#ebc43d, 30%);

        //color: darken($dropBackgroundColor, 50%);
        //color: #333;
        //color: lighten(#920041, 50%);
        //color: #bbb !important;
        //opacity: 0.8;

        text-align: left;

        //opacity: .55;
        top: 32.5px;
        opacity: 1;

        // width: 100px;
        // background-color: lighten($dropBackgroundColor, 1%);
        // border: 0;
        // border-radius: 100px;
        // padding: 8px 18px;

        //border-bottom: 0;

    }

    .heading-column-2 {
        left: 392px;
        //color: darken(#a8dfe8, 14%);
        //border-bottom: 1.5px solid lighten(#a8dfe8, 10%);
    }

    .refresh-container2 {
        //opacity: 0;
        //margin-left: auto;
        //flex: 1;
        //background-color: red;
        margin-left: auto;
        margin-left: 6.5px;
        margin-right: 14px;
        margin-top: 146px;
        margin-top: 143px;
        margin-top: 62px;
        margin-left: 678px;

        margin-top: 72px;
        margin-left: 345px;

        margin-top: 58px;
        margin-left: 675px;

        // margin-top: -263px;
        // margin-left: 920px;
        //margin-top: 23px;
    }

    .RefreshButton {
        width: 54px;
        height: 54px;
        background-color: lighten($dropBackgroundColor, 24%);
        background-color: transparent;
        border: 1.75px solid darken($dropBackgroundColor, 8%);
        border: 0;
        border-radius: 100px;
        transition: .2s;
        transform: scale(1.5);

        //box-shadow: .75px .75px .5px .5px darken($backgroundColor, 3.5%);

        .refresh-icon-img {
            width: 24px;
            margin-top: 15px;
        }
    }

    .RefreshButton:hover {
        cursor: pointer !important;
        transform: scale(1.55);
        box-shadow: 1px 2px 3px 1px darken(#f7eee3, 0%);
        background-color: lighten($dropBackgroundColor, 1%);
    }
}
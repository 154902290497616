@import 'global';

$landingBorderRadius: 40px;

.SessionGrid {
    width: 100%;
    height: 694px;
    height: 754px;

    //background-color: lighten(#d9ebff, 0%);

    border-radius: $landingBorderRadius;

    transition: .4s;

    background-color: darken($creamColor, 2%);
    background-color: lighten(#fdf1d6, 4%);
    background-color: lighten($dropBackgroundColor, 0%);
    //background-color: #fff;
    //border-right: 2px solid lighten($layoutLineColor, 8%);
    //background-color: #fff;
    margin: 0 20px;
    width: $dropColumnWidth;

    position: fixed;
    top: 90px;
    top: 110px + $dropSpaceTopSpace;

    left: 28px;
    left: 6px + $sidebarWidth + $sidebarSpace;

    top: 42px;
    top: 0px;
    border-radius: 0;
    height: 672px;
    height: 714px;
    height: calc(100vh - 100px);
    //height: 730px;
    z-index: 1;

    top: 108px;
    border-radius: 40px;
    //border-radius: 0;

    //border: 2px solid darken($dropBackgroundColor, 10%);
    //background-color: #fff;

    //border-right: 2.5px solid darken($dropBackgroundColor, 5%);

    //padding-top: 100px;
    //left: 216px;

    //zoom: 1.025;

    .thoughts {
        // zoom: 0.945;
        margin: 20px 14px 0 16px;
        height: 480px;

        transition: all .2s;
        // margin: 14px 20px 4px 12px;
        // padding: 12px;
        // background: darken(#fffdfa, 3%);
    }

    .thoughts.Considering {
        transform: scale(.9);
        //margin-top: 80px;
        transform-origin: bottom center;
    }

    .thoughts.HideOnEdge2 {
        //transition: all .3s;
        //margin-left: -300px;

        .SessionThought.ColorTeal,
        .SessionThought.ColorPink {
            left: -400px !important;
            transition: all .6s !important;
        }
    }

    .thoughts.HideOnEdge1 {
        //transition: all .3s;
        //margin-left: -300px;

        .SessionThought.ColorYellow,
        .SessionThought.ColorPurple {
            left: -300px !important;
            transition: all .6s !important;
        }
    }


}

.SessionGrid.Considering {

    .TotalSlots6,
    .TotalSlots7,
    .TotalSlots8,
    .TotalSlots9,
    .TotalSlots10 {
        $scale: .89;
        $leftShift: 20px;

        $bonusOffset: -46px;
        $bonusMultiplier: 8px;

        .SessionThought.SlotNumber6 {
            position: relative;
            top: -588px + $bonusOffset;
            left: 2px;
            transform: scale($scale) rotate(0deg);
            opacity: 1;
        }

        .SessionThought.SlotNumber7 {
            position: relative;
            top: -711px + $bonusOffset - ($bonusMultiplier * 1);
            left: 22px;
            transform: scale($scale) rotate(-.5deg);
            opacity: 1;
        }

        .SessionThought.SlotNumber8 {
            position: relative;
            top: -832px + $bonusOffset - ($bonusMultiplier * 2);
            left: 6px;
            transform: scale($scale) rotate(0deg);
            opacity: 1;
        }

        .SessionThought.SlotNumber9 {
            position: relative;
            top: -956px + $bonusOffset - ($bonusMultiplier * 3);
            left: 26px;
            transform: scale($scale) rotate(-.5deg);
            opacity: 1;
        }

        .SessionThought.SlotNumber10 {
            position: relative;
            top: -1078px + $bonusOffset - ($bonusMultiplier * 4);
            left: 6px;
            transform: scale($scale) rotate(0deg);
            opacity: 1;
        }

        $multiplier: 53;

        .SessionThought.SlotNumber1 {
            position: relative;
            top: -21px;
            left: -20px;
            transform: scale($scale) rotate(0deg);
        }

        .SessionThought.SlotNumber2 {
            position: relative;
            top: -58px;
            top: -91px - ($multiplier * 1);
            //top: -70px;
            //left: -8px;
            transform: scale($scale) rotate(-.5deg);
            //left: -2px;
            left: 0px;
        }

        .SessionThought.SlotNumber3 {
            position: relative;
            top: -112px;
            top: -160px - ($multiplier * 2);
            //top: -138px;
            transform: scale($scale) rotate(.5deg);
            //left: 12px;
            //left: 8px;
            left: -20px;
            //opacity: 0;
        }

        .SessionThought.SlotNumber4 {
            position: relative;
            top: -168px;
            top: -231px - ($multiplier * 3);
            //top: -208px;
            //left: 12px;
            //left: 8px;
            transform: scale($scale) rotate(-.5deg);
            left: 0px;
            //opacity: 0;
        }

        .SessionThought.SlotNumber5 {
            position: relative;
            top: -112px;
            top: -301px - ($multiplier * 4);
            //top: -138px;
            transform: scale($scale) rotate(.5deg);
            //left: 12px;
            //left: 8px;
            left: -20px;
            //opacity: 0;
        }
    }
}

.SessionThought {
    height: 136px;
    height: 127px;
    height: 154.5px;

    width: 282px;

    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    align-content: center;

    border-radius: $landingBorderRadius;
    margin-bottom: 12px;
    margin-top: -5px !important;

    background-color: #fff;
    background-color: lighten(#d1eff7, 9%);
    background-color: $yellowTintBackgroundColor;

    transition: all .3s;
    transition-property: top, height;

    //opacity: 0;

    .label {
        width: 120px;
        margin: 0 auto;

        color: lighten(#6A1FE1, 20%);
        //color: #fff;
        font-size: 10.5pt;
        font-weight: 700;
        padding: 0.5em;
        border-radius: 100px;

        font-size: 15pt;
        margin-left: -98px;
        color: lighten(#0ea2df, 30%);
        color: #0ea2df;
        //opacity: .5;
        //opacity: .75;
        //opacity: 0;

        //opacity: .3;
        margin-left: 0px;
        text-align: center;
        width: 100%;
        color: #ffae5e;
        font-size: 21pt;
        font-weight: 400;
        //z-index: -1;

        color: #fbe7d0;
        font-size: 16.5pt;


    }

    .thought-content {
        height: 152.5px;
        //height: 118.5px;
        //height: 92px;
        box-sizing: border-box;

        font-size: 13.5pt;
        font-weight: 500;
        padding: 1.1em 68px 1.1em 31px;
        padding-top: 14px;

        text-align: left;
        //text-align: center;

        color: darken(#0ea5e4, 1%);
        color: darken(#0ea5e4, 4%);
        color: #0ea2df;
        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        border-radius: 12px;
        margin: 8px 8px 4px 0;
        margin: 10px 10px -2px 0;
    }

    .thought-label {



        // Was 12.5pt
        font-size: 12.5pt;
        font-size: 13.6pt;

        font-family: $serifFamily;

        padding: 0;

        line-height: 1.40em;
        box-sizing: border-box;

        color: darken(#d1eff7, 72%);

        color: #372c49;
        color: #444;
        color: darken(#e75e47, 0%);
        color: #111;
        color: darken(#797dea, 5%);
        //color: darken(#e75e47, 20%);
        font-weight: 500;
        font-weight: 500;
        //opacity: 0.5;

        //background-color: #fff;

        //text-align: left;
        color: $negativeColor;
    }

    .thought-checks {
        margin-top: auto;
        //margin-left: auto;

        height: 30px;
        //background-color: #ddd;

        display: flex;

        //transform: scale(.8);

        .thought-check {
            width: 16px;
            height: 16px;
            border: 2px solid darken(#ffae5e, 0%);

            //background-color: #e75e47;

            opacity: 0.25;
            border-radius: 100px;
            margin: 0 8px 0 0;

            .checkmark-img {
                opacity: 0;
                width: 10px;
            }
        }

        .thought-check.Completed {
            //@include: #0ea2df;
            //background-color: #e75e47;
            opacity: .75;

            .checkmark-container {
                margin-top: -7.5px;
                margin-left: 3px;
            }

            .checkmark-img {
                opacity: 1;
                padding: 0;
                //margin-top: -40px !important;
            }
        }
    }
}

.SessionThought {
    //transform: scale(.925);
}

.NegativeThoughtCard .SessionThought {
    opacity: 0;
}

.SessionThought.SlotNumber1 {
    position: relative;
    top: 0;
    left: -12px;
    transform: scale(.98) rotate(-.5deg);
}

.SessionThought.SlotNumber2 {
    position: relative;
    top: -24px;
    //left: -8px;
    transform: scale(.98) rotate(.5deg);
    //left: -2px;
}

.SessionThought.SlotNumber3 {
    position: relative;
    top: -48px;
    transform: scale(.98) rotate(-.5deg);
    //left: 12px;
    //left: 8px;
    left: -14px;
}

.SessionThought.SlotNumber4 {
    position: relative;
    top: -108px;
    transform: scale(.98) rotate(-.5deg);
    //left: 12px;
    //left: 8px;
    transform: scale(.98) rotate(.5deg);
    //left: -14px;
}

.TotalSlots1 {
    $scale: .945;

    .SessionThought.SlotNumber1 {
        position: relative;
        top: 0;
        left: -12px;
        transform: scale($scale) rotate(0deg);
    }
}

.TotalSlots2 {
    $scale: .945;

    .SessionThought.SlotNumber1 {
        position: relative;
        top: 0;
        left: -12px;
        transform: scale($scale) rotate(0deg);
    }

    .SessionThought.SlotNumber2 {
        position: relative;
        top: -58px;
        top: -52px;
        //top: -70px;
        //left: -8px;
        transform: scale($scale) rotate(-.5deg);
        //left: -2px;
        left: 2px;
    }
}

.TotalSlots3 {
    $scale: .945;

    .SessionThought.SlotNumber1 {
        position: relative;
        top: 0;
        left: -12px;
        transform: scale($scale) rotate(0deg);
    }

    .SessionThought.SlotNumber2 {
        position: relative;
        top: -58px;
        top: -52px;
        //top: -70px;
        //left: -8px;
        transform: scale($scale) rotate(-.5deg);
        //left: -2px;
        left: 2px;
    }

    .SessionThought.SlotNumber3 {
        position: relative;
        top: -112px;
        top: -106px;
        //top: -138px;
        transform: scale($scale) rotate(.0deg);
        //left: 12px;
        //left: 8px;
        left: -14px;
        //opacity: 0;
    }
}

.TotalSlots4 {
    $scale: .92;

    .SessionThought.SlotNumber1 {
        position: relative;
        top: 0 - 12.5px;
        left: -12px;
        transform: scale($scale) rotate(0deg);
    }

    .SessionThought.SlotNumber2 {
        position: relative;
        top: -58px;
        top: -52px - 18.5px;
        //top: -70px;
        //left: -8px;
        transform: scale($scale) rotate(-.5deg);
        //left: -2px;
        left: 0px;
    }

    .SessionThought.SlotNumber3 {
        position: relative;
        top: -112px;
        top: -106px - 24.5px;
        //top: -138px;
        transform: scale($scale) rotate(.0deg);
        //left: 12px;
        //left: 8px;
        left: -14px;
        //opacity: 0;
    }

    .SessionThought.SlotNumber4 {
        position: relative;
        top: -168px;
        top: -160px - 30.5px;
        //top: -208px;
        //left: 12px;
        //left: 8px;
        transform: scale($scale) rotate(-.5deg);
        left: 0px;
        //opacity: 0;
    }
}

.TotalSlots1,
.TotalSlots2,
.TotalSlots3,
.TotalSlots4,
.TotalSlots5 {
    $scale: .85;

    $bonusOffset: 690px;
    $bonusOffset: 688px;
    $bonusMultiplier: 85.5px;

    .SessionThought.SlotNumber1 {
        position: relative;
        top: -21px;
        top: -711px + $bonusOffset + 100px;
        top: -711px + $bonusOffset;
        left: -22px;
        transform: scale($scale) rotate(0deg);
    }

    .SessionThought.SlotNumber2 {
        position: relative;
        top: -58px;
        top: -91px;
        top: -711px + $bonusOffset - ($bonusMultiplier * 1);
        top: -711px + $bonusOffset - ($bonusMultiplier * 1);
        //top: -70px;
        //left: -8px;
        transform: scale($scale) rotate(-.75deg);
        //left: -2px;
        left: -4px;
    }

    .SessionThought.SlotNumber3 {
        position: relative;
        top: -112px;
        top: -160px;
        top: -711px + $bonusOffset - ($bonusMultiplier * 2);
        top: -711px + $bonusOffset - ($bonusMultiplier * 2);
        //top: -138px;
        transform: scale($scale) rotate(0deg);
        //left: 12px;
        //left: 8px;
        left: -24px;
        //opacity: 0;
    }

    .SessionThought.SlotNumber4 {
        position: relative;
        top: -168px;
        top: -231px;
        top: -711px + $bonusOffset - ($bonusMultiplier * 3);
        //top: -208px;
        //left: 12px;
        //left: 8px;
        transform: scale($scale) rotate(-.75deg);
        left: -2px;
        //opacity: 0;
    }

    .SessionThought.SlotNumber5 {
        position: relative;
        top: -112px;
        top: -301px;
        top: -711px + $bonusOffset - ($bonusMultiplier * 4);
        //top: -138px;
        transform: scale($scale) rotate(.5deg);
        //left: 12px;
        //left: 8px;
        left: 12px;
        //opacity: 0;
    }
}

// .TotalSlots5 {
//     $scale: .85;
//     $multiplier: 43;

//     .SessionThought.SlotNumber1 {
//         position: relative;
//         top: -21px;
//         left: -20px;
//         transform: scale($scale) rotate(0deg);
//     }

//     .SessionThought.SlotNumber2 {
//         position: relative;
//         top: -58px;
//         top: -91px - ($multiplier * 1);
//         //top: -70px;
//         //left: -8px;
//         transform: scale($scale) rotate(-.5deg);
//         //left: -2px;
//         left: 10px;
//     }

//     .SessionThought.SlotNumber3 {
//         position: relative;
//         top: -112px;
//         top: -160px - ($multiplier * 2);
//         //top: -138px;
//         transform: scale($scale) rotate(.5deg);
//         //left: 12px;
//         //left: 8px;
//         left: -20px;
//         //opacity: 0;
//     }

//     .SessionThought.SlotNumber4 {
//         position: relative;
//         top: -168px;
//         top: -231px - ($multiplier * 3);
//         //top: -208px;
//         //left: 12px;
//         //left: 8px;
//         transform: scale($scale) rotate(-.5deg);
//         left: 10px;
//         //opacity: 0;
//     }

//     .SessionThought.SlotNumber5 {
//         position: relative;
//         top: -112px;
//         top: -301px - ($multiplier * 4);
//         //top: -138px;
//         transform: scale($scale) rotate(.5deg);
//         //left: 12px;
//         //left: 8px;
//         left: -20px;
//         //opacity: 0;
//     }
// }


.TotalSlots6,
.TotalSlots7,
.TotalSlots8,
.TotalSlots9,
.TotalSlots10 {
    $scale: .89;
    $leftShift: 20px;

    $bonusOffset: -228px;
    $bonusMultiplier: -39px;
    $bonusLeftOffset: -18px;

    .SessionThought.SlotNumber6 {
        position: relative;
        top: -588px + $bonusOffset;
        left: -4px + $bonusLeftOffset;
        transform: scale($scale) rotate(0deg);
        opacity: 1;
    }

    .SessionThought.SlotNumber7 {
        position: relative;
        top: -711px + $bonusOffset - ($bonusMultiplier * 1);
        left: 22px + $bonusLeftOffset;
        transform: scale($scale) rotate(-.5deg);
        opacity: 1;
    }

    .SessionThought.SlotNumber8 {
        position: relative;
        top: -832px + $bonusOffset - ($bonusMultiplier * 2);
        left: 6px + $bonusLeftOffset;
        transform: scale($scale) rotate(0deg);
        opacity: 1;
    }

    .SessionThought.SlotNumber9 {
        position: relative;
        top: -956px + $bonusOffset - ($bonusMultiplier * 3);
        left: 26px + $bonusLeftOffset;
        transform: scale($scale) rotate(-.5deg);
        opacity: 1;
    }

    .SessionThought.SlotNumber10 {
        position: relative;
        top: -1078px + $bonusOffset - ($bonusMultiplier * 4);
        left: 36px + $bonusLeftOffset;
        transform: scale($scale) rotate(0deg);
        opacity: 1;
    }

    $multiplier: 80;
    $whiteLeftOffset: -40px;

    .SessionThought.SlotNumber1 {
        position: relative;
        top: -21px;
        left: -0px + $whiteLeftOffset;
        transform: scale($scale) rotate(0deg);
    }

    .SessionThought.SlotNumber2 {
        position: relative;
        top: -58px;
        top: -91px - ($multiplier * 1);
        //top: -70px;
        //left: -8px;
        transform: scale($scale) rotate(-.5deg);
        //left: -2px;
        left: 0px + $whiteLeftOffset;
    }

    .SessionThought.SlotNumber3 {
        position: relative;
        top: -112px;
        top: -160px - ($multiplier * 2);
        //top: -138px;
        transform: scale($scale) rotate(.5deg);
        //left: 12px;
        //left: 8px;
        left: -10px + $whiteLeftOffset;
        //opacity: 0;
    }

    .SessionThought.SlotNumber4 {
        position: relative;
        top: -168px;
        top: -231px - ($multiplier * 3);
        //top: -208px;
        //left: 12px;
        //left: 8px;
        transform: scale($scale) rotate(-.5deg);
        left: 0px + $whiteLeftOffset;
        //opacity: 0;
    }

    .SessionThought.SlotNumber5 {
        position: relative;
        top: -112px;
        top: -301px - ($multiplier * 4);
        //top: -138px;
        transform: scale($scale) rotate(.5deg);
        //left: 12px;
        //left: 8px;
        left: 14px + $whiteLeftOffset;
        //opacity: 0;
    }
}

.SessionThought.Negative {
    //background-color: #fff;

    box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);

    //border-radius: 18px;

    .thought-checks {
        //margin-left: 260px;

        margin-top: 25px;
        zoom: .7;
        opacity: 0;

        .thought-check {
            border: 3px solid darken(#ffae5e, 0%);
            //opacity: 0.5;
        }
    }
}

.SessionThought.IsNeutral {
    .thought-label {
        color: $neutralColor !important;
    }
}

.SessionThought.IsNeutralCompleted {
    .thought-label {
        //color: $neutralColor !important;
    }

    .thought-linethrough {
        color: lighten($neutralColor, 10%) !important;
        //color: #fff;
        text-decoration: none !important;
        opacity: .7 !important;
    }
}

.SessionThoughtCompleted {

    //background-color: #fff;

    //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0.5%);

    border-radius: $landingBorderRadius;
    //background-color: #fff;

    background-color: lighten(#ffc45e, 30.5%);
    background-color: transparent;
    //background-color: #fff;
    // border: 6px solid lighten(#ffc45e, 30.5%);
    // box-sizing: border-box;
    //border: 6px solid #fff;
    //background-color: #f9f1ff;

    //zoom: .9;

    //background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #fff 31%, rgba(255, 255, 255, 1) 66%, rgba(255, 255, 255, 1) 100%);


    //border: 8px solid lighten(#ffc45e, 31.5%);
    //border: 8px solid #fff;



    display: block;
    padding: 0px 0 0 0px;

    .original-content,
    .reframed-content {
        display: flex;
        padding-top: 18px;
    }

    .reframed-content {
        padding-top: 14px;
        //padding-bottom: 12px;
        margin-top: -0px;
        height: 50px;
    }

    .original-content {
        margin-top: -8px;
        padding-top: 0;

        margin-top: 0px;
        padding-left: 0px;
    }

    .checkmark-img {
        width: 14px;
        padding: 8px 8px 0 0;
        opacity: 1;
        align-self: flex-start;
    }

    .x-img {
        opacity: 0.5;
    }

    .label-content {
        // width: 270px;
        // min-width: 270px;
        padding-right: 24px;

        display: flex;
        //height: 40px;
        align-items: center;
        //justify-content: center;
    }

    .ratings-content {
        display: flex;
        display: none;
        width: 80px;
        min-width: 80px;

        //margin-left: auto;
        padding-right: 30px;
    }

    .rating {
        display: flex;
        //zoom: 1.1;

        .rating-label {
            padding: 0 4px;
            font-size: 10pt;
            font-weight: 700;
            color: $negativeColor;
            opacity: 0.75;
        }

        .rating-label-old {
            opacity: 0.35;
            text-decoration: line-through;
        }

        .rating-label-reframe {
            color: $reinforceColor;
            opacity: 1;
        }

        .rating-bar {

            .bar-fill {}
        }
    }

    .original-rating,
    .reframing-rating {
        margin-top: -4px;
        padding: 16px;
        background-color: lighten($negativeColor, 47%);
        border-radius: 14px;

        //transform: scale(0.9);
    }

    .reframing-rating {
        background-color: lighten(#f76148, 35%);
    }

    .arrow {
        opacity: 0.5;
        margin-top: -2px;
        padding: 0 1px;

        .arrow-img {

            width: 10px;
        }
    }

    .thought-label {
        padding-top: 5px;
        padding-right: 40px;
        font-size: 13.25pt;
        text-align: left;
        opacity: 1;
        color: $negativeColor;
        align-self: flex-start;
    }

    .thought-linethrough {
        text-decoration: line-through !important;
        font-size: 9.5pt !important;
        opacity: .30;

        // background: #797DEA;
        // background: linear-gradient(to right, $negativeColor 0%, lighten($negativeColor, 45%) 33%, $negativeColor 66%, lighten($negativeColor, 45%) 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }

    .reframed-label {
        opacity: 1;
        //font-size: 12.50t;
        //font-weight: 500 !important;
        color: $reinforceColor;
        text-decoration: none !important;
    }

    .progress-content {
        //margin-top: auto;
        display: flex;

        .progress-label {
            padding: 7px 8px 0 0px;
            font-size: 9pt;
            font-weight: 700;
            color: #0ea2df;
        }
    }

    .checklist {
        //width: 120px;
    }

    .ratings {
        display: flex;
        padding-left: 4px;
        padding-top: 1px;

        .ratings-label {
            padding: 7px 6px 0 78px;
            font-size: 9pt;
            font-weight: 700;
            color: #fff;
        }

        .rating-compare {
            padding: 0 3px;
            font-size: 9pt;
            font-weight: 500;
        }

        .rating-compare-new {
            color: $reinforceColor;
        }

        .rating-compare-old {
            color: #6a1fe5;
            opacity: 0.6;
        }

        .divider {
            width: 30px;
            height: 1.5px;
            background-color: #ffae5e;
            opacity: 0.3;
        }
    }

    .thought-checks {
        margin-top: 8px;
        margin-left: 0px;
        //margin-left: auto;

        height: 30px;
        //background-color: #ddd;

        display: flex;

        //transform: scale(.8);

        .thought-check {
            width: 16px;
            height: 16px;
            border: 2px solid darken(#ffae5e, 0%);

            //background-color: #e75e47;

            opacity: 0.6;
            border-radius: 100px;
            margin: 0 8px 0 0;

            .checkmark-img {
                opacity: 0;
                width: 10px;
                margin: 11px 0 0 -3px;
            }
        }

        .thought-check.Completed {
            //@include: #0ea2df;
            //background-color: #e75e47;
            opacity: 1;

            .checkmark-container {
                margin-top: -7.5px;
                margin-left: 3px;
            }

            .checkmark-img {
                opacity: 1;
                padding: 0;
                //margin-top: -40px !important;
            }
        }
    }
}

.SessionThoughtActive {
    display: block !important;
    box-shadow: none !important;
    background-color: #fffdfa;
    border-radius: $landingBorderRadius;

    //transition: 1s !important;

    .drop-space {
        padding: 9px 11px 10px 14px;
    }

    .drop-card {
        //width: 360px;
        background-color: #fff;
        box-shadow: 0.75px 1.3px 3.5px 0px darken(#eeeae0, 10%);
        border-radius: 14px;

        box-shadow: 0.5px 0.5px 2px 0px lighten(#e9dec5, 0%);
        box-shadow: 0.5px 0.5px 2px 0px #cdb47b; // Also needs to be set in SuggestionsSheet.animateNegativeCard


        //box-sizing: border-box;
    }

    .drop-card.Landing {
        //box-sizing: border-box;
        border: 2px solid #fff !important;
        border-top: 2px solid #fff !important;
        border-left: 2px solid #fff !important;
        box-shadow: none;
    }

    .drop-card.Landed {
        //box-sizing: border-box;
        border: 2px solid darken($backgroundColor, 4%) !important;
        border-top: 2px solid darken($backgroundColor, 1%) !important;
        border-left: 2px solid darken($backgroundColor, 1%) !important;
        box-shadow: none;
    }

    .thought-content-active {
        text-align: left;
        padding: 22px 26px;
        width: 282px;
        height: 94px;
    }
}

.SessionThought.Pending,
.SessionThought.IsHidden {
    align-content: center;
    align-items: center;
    justify-content: center;
    //opacity: 0 !important;
    background-color: darken(#fffcf7, 2%) !important;
    background-color: rgba(#fffcf7, .5) !important;
    border: 2px dashed darken(#fffcf7, 8%);
    border: 0;
    //opacity: .5;

    opacity: 0;

    position: relative;
    z-index: 1;

    .pending-border {}
}

.SessionThoughtActive .drop-card {
    .thought-label {
        height: 95px;
    }
}

.SessionThoughtExpanded {
    //box-shadow: none !important;
    //box-shadow: 0.5px 0.5px 2px 0px #e9dec5;
    align-content: flex-start;
    pointer-events: none;
}

.SessionThoughtExpanded.Reinforce {
    //box-shadow: none !important;

    // TODO: Override by JS animation right now.
    //box-shadow: 0.5px 0.5px 2px 0px darken(#e9dec5, 6%);
    align-content: flex-start;
}

.SessionThoughtFlat {
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.SessionThought.SlideIn {
    .flat-content {
        position: relative;
        top: 60px;
        opacity: 0;
    }
}

.SessionThought.SlideInNumber {
    .card-number {
        opacity: 0;
        transform: scale(2.2);
    }
}

.SessionGrid.IsPostSession {
    padding-top: 14px !important;
    transform: scale(.925);
    transform-origin: top left;

    .SessionThought {
        background-color: transparent !important;
        margin-top: -42px !important;
        margin-top: -22px !important;

        .flat-content {
            border-radius: 16px !important;
            box-shadow: 1px 1px 4px 1px darken(#e3e6f7, 0%) !important;

            //margin-top: -40px !important;
        }
    }

    // .SessionThought:first-child {
    //     margin-left: 4px;
    // }

    // .SessionThought:nth-child(2) {
    //     margin-left: 14px;
    // }

    // .SessionThought:nth-child(3) {
    //     margin-left: 24px;
    // }
}

@keyframes thoughtSlideIn {
    from {
        top: 60px;
        //transform: scale(1.15);
        //opacity: 0;
        //left: 60px;
    }

    to {
        top: 0px;
        // transform: scale(1);
        //opacity: 1;
        //left: 0px;
        position: static;
    }
}

// Deprectated?
@keyframes numberFadeIn {
    from {
        transform: scale(2.3);
        opacity: 0;
    }

    to {
        transform: scale(1.3);
        opacity: 1;
    }
}

.SessionGrid .IsBonus .ThoughtSetContent {
    .flat-content {
        background-color: lighten(#fffae1, 2.5%) !important;
        background: linear-gradient(0deg, rgba(255, 250, 225, 1) 0%, rgba(255, 254, 247, 1) 25%);
        //box-shadow: 1.5px 1.5px 4px 0px lighten(#dcd2bd, 11%);

        .stage-progressbar {
            background-color: lighten($neutralColor, 55.5%) !important;
        }
    }

}

$baseLeft: -134px;
$baseTop: 21px;
$baseTopBetween: 31.5px; // was 65.5
$baseLeftCount1: 0px;
$baseLeftCount2: 20px;
$baseLeftCount3: 6px;
$baseLeftCount4: 20px;
$baseLeftCount5: 40px;
$baseLeftCount6: 40px;

$TealTop: 0px;
$TealLeft: 108px;
$TealTopCount1: 0px;
$TealTopCount2: 0px;
$TealTopCount3: 0px;
$TealTopCount4: 0px;
$TealTopCount5: 0px;
$TealTopCount6: 0px;
$TealLeftCount1: 0px;
$TealLeftCount2: 0px;
$TealLeftCount3: 0px;
$TealLeftCount4: 0px;
$TealLeftCount5: 0px;
$TealLeftCount6: 0px;

$YellowTop: 0px;
$YellowLeft: 344px;
$YellowTopCount1: 0px;
$YellowTopCount2: 0px;
$YellowTopCount3: 0px;
$YellowTopCount4: 0px;
$YellowTopCount5: 0px;
$YellowTopCount6: 0px;
$YellowLeftCount1: 0px;
$YellowLeftCount2: 0px;
$YellowLeftCount3: 0px;
$YellowLeftCount4: 0px;
$YellowLeftCount5: 0px;
$YellowLeftCount6: 0px;

$PinkTop: 318px;
$PinkLeft: 108px;
$PinkTopCount1: 0px;
$PinkTopCount2: 0px;
$PinkTopCount3: 0px;
$PinkTopCount4: 0px;
$PinkTopCount5: 0px;
$PinkTopCount6: 0px;
$PinkLeftCount1: 0px;
$PinkLeftCount2: 0px;
$PinkLeftCount3: 0px;
$PinkLeftCount4: 0px;
$PinkLeftCount5: 0px;
$PinkLeftCount6: 0px;

$PurpleTop: 318px;
$PurpleLeft: 344px;
$PurpleTopCount1: 0px;
$PurpleTopCount2: 0px;
$PurpleTopCount3: 0px;
$PurpleTopCount4: 0px;
$PurpleTopCount5: 0px;
$PurpleTopCount6: 0px;
$PurpleLeftCount1: 0px;
$PurpleLeftCount2: 0px;
$PurpleLeftCount3: 0px;
$PurpleLeftCount4: 0px;
$PurpleLeftCount5: 0px;
$PurpleLeftCount6: 0px;



.SessionGrid {

    $cardScale: .79;
    $cardScale: .77;

    .SessionThought {
        transform: scale($cardScale);
        position: absolute;
    }

    .SessionThought.ColorTeal {
        z-index: 100;

        .flat-content {
            .thought-label {
                color: $themeTealFontColor !important;
            }

            .explore-item .label {
                color: $themeTealFontColor !important;
            }

            .explore-item .number {
                color: $themeTealFontColor !important;
            }
        }
    }

    .SessionThought.ColorYellow {
        z-index: 99;

        .flat-content {
            .thought-label {
                color: $themeYellowFontColor !important;
            }

            .explore-item .label {
                color: $themeYellowFontColor !important;
            }

            .explore-item .number {
                color: $themeYellowFontColor !important;
            }
        }
    }

    .SessionThought.ColorPink {
        z-index: 98;

        .flat-content {
            .thought-label {
                color: $themePinkFontColor !important;
            }

            .explore-item .label {
                color: $themePinkFontColor !important;
            }

            .explore-item .number {
                color: $themePinkFontColor !important;
            }
        }
    }

    .SessionThought.ColorPurple {
        z-index: 97;

        .flat-content {
            .thought-label {
                color: $themePurpleFontColor !important;
            }

            .explore-item .label {
                color: $themePurpleFontColor !important;
            }

            .explore-item .number {
                color: $themePurpleFontColor !important;
            }
        }
    }

    .SessionThought.ColorTeal.ColorCount1 {
        top: $baseTop + $TealTop + $TealTopCount1;
        left: $baseLeft + $baseLeftCount1 + $TealLeft + $TealLeftCount1;
        transform: scale($cardScale) rotate(0deg);
    }

    .SessionThought.ColorTeal.ColorCount2 {
        top: $baseTop + $TealTop + $TealTopCount2 + ($baseTopBetween * 1);
        left: $baseLeft + $baseLeftCount2 + $TealLeft + $TealLeftCount2;
        transform: scale($cardScale) rotate(-1deg);
    }

    .SessionThought.ColorTeal.ColorCount3 {
        top: $baseTop + $TealTop + $TealTopCount3 + ($baseTopBetween * 2);
        left: $baseLeft + $baseLeftCount3 + $TealLeft + $TealLeftCount3;
        transform: scale($cardScale) rotate(1deg);
    }

    .SessionThought.ColorTeal.ColorCount4 {
        top: $baseTop + $TealTop + $TealTopCount4 + ($baseTopBetween * 3);
        left: $baseLeft + $baseLeftCount4 + $TealLeft + $TealLeftCount4;
    }

    .SessionThought.ColorTeal.ColorCount5 {
        top: $baseTop + $TealTop + $TealTopCount5 + ($baseTopBetween * 4);
        left: $baseLeft + $baseLeftCount5 + $TealLeft + $TealLeftCount5;
    }

    .SessionThought.ColorTeal.ColorCount6 {
        top: $baseTop + $TealTop + $TealTopCount6 + ($baseTopBetween * 5);
        left: $baseLeft + $baseLeftCount6 + $TealLeft + $TealLeftCount6;
    }

    .SessionThought.ColorYellow.ColorCount1 {
        top: $baseTop + $YellowTop + $YellowTopCount1;
        left: $baseLeft + $baseLeftCount1 + $YellowLeft + $YellowLeftCount1;
    }

    .SessionThought.ColorYellow.ColorCount2 {
        top: $baseTop + $YellowTop + $YellowTopCount2 + ($baseTopBetween * 1);
        left: $baseLeft + $baseLeftCount2 + $YellowLeft + $YellowLeftCount2;
    }

    .SessionThought.ColorYellow.ColorCount3 {
        top: $baseTop + $YellowTop + $YellowTopCount3 + ($baseTopBetween * 2);
        left: $baseLeft + $baseLeftCount3 + $YellowLeft + $YellowLeftCount3;
    }

    .SessionThought.ColorYellow.ColorCount4 {
        top: $baseTop + $YellowTop + $YellowTopCount4 + ($baseTopBetween * 3);
        left: $baseLeft + $baseLeftCount4 + $YellowLeft + $YellowLeftCount4;
    }

    .SessionThought.ColorYellow.ColorCount5 {
        top: $baseTop + $YellowTop + $YellowTopCount5 + ($baseTopBetween * 4);
        left: $baseLeft + $baseLeftCount5 + $YellowLeft + $YellowLeftCount5;
    }

    .SessionThought.ColorYellow.ColorCount6 {
        top: $baseTop + $YellowTop + $YellowTopCount6 + ($baseTopBetween * 5);
        left: $baseLeft + $baseLeftCount6 + $YellowLeft + $YellowLeftCount6;
    }

    .SessionThought.ColorPink.ColorCount1 {
        top: $baseTop + $PinkTop + $PinkTopCount1;
        left: $baseLeft + $baseLeftCount1 + $PinkLeft + $PinkLeftCount1;
    }

    .SessionThought.ColorPink.ColorCount2 {
        top: $baseTop + $PinkTop + $PinkTopCount2 + ($baseTopBetween * 1);
        left: $baseLeft + $baseLeftCount2 + $PinkLeft + $PinkLeftCount2;
    }

    .SessionThought.ColorPink.ColorCount3 {
        top: $baseTop + $PinkTop + $PinkTopCount3 + ($baseTopBetween * 2);
        left: $baseLeft + $baseLeftCount3 + $PinkLeft + $PinkLeftCount3;
    }

    .SessionThought.ColorPink.ColorCount4 {
        top: $baseTop + $PinkTop + $PinkTopCount4 + ($baseTopBetween * 3);
        left: $baseLeft + $baseLeftCount4 + $PinkLeft + $PinkLeftCount4;
    }

    .SessionThought.ColorPink.ColorCount5 {
        top: $baseTop + $PinkTop + $PinkTopCount5 + ($baseTopBetween * 4);
        left: $baseLeft + $baseLeftCount5 + $PinkLeft + $PinkLeftCount5;
    }

    .SessionThought.ColorPink.ColorCount6 {
        top: $baseTop + $PinkTop + $PinkTopCount6 + ($baseTopBetween * 5);
        left: $baseLeft + $baseLeftCount6 + $PinkLeft + $PinkLeftCount6;
    }

    .SessionThought.ColorPurple.ColorCount1 {
        top: $baseTop + $PurpleTop + $PurpleTopCount1;
        left: $baseLeft + $baseLeftCount1 + $PurpleLeft + $PurpleLeftCount1;
    }

    .SessionThought.ColorPurple.ColorCount2 {
        top: $baseTop + $PurpleTop + $PurpleTopCount2 + ($baseTopBetween * 1);
        left: $baseLeft + $baseLeftCount2 + $PurpleLeft + $PurpleLeftCount2;
    }

    .SessionThought.ColorPurple.ColorCount3 {
        top: $baseTop + $PurpleTop + $PurpleTopCount3 + ($baseTopBetween * 2);
        left: $baseLeft + $baseLeftCount3 + $PurpleLeft + $PurpleLeftCount3;
    }

    .SessionThought.ColorPurple.ColorCount4 {
        top: $baseTop + $PurpleTop + $PurpleTopCount4 + ($baseTopBetween * 3);
        left: $baseLeft + $baseLeftCount4 + $PurpleLeft + $PurpleLeftCount4;
    }

    .SessionThought.ColorPurple.ColorCount5 {
        top: $baseTop + $PurpleTop + $PurpleTopCount5 + ($baseTopBetween * 4);
        left: $baseLeft + $baseLeftCount5 + $PurpleLeft + $PurpleLeftCount5;
    }

    .SessionThought.ColorPurple.ColorCount6 {
        top: $baseTop + $PurpleTop + $PurpleTopCount6 + ($baseTopBetween * 5);
        left: $baseLeft + $baseLeftCount6 + $PurpleLeft + $PurpleLeftCount6;
    }
}

.SessionGrid {
    .group-headings {
        position: relative;
        //opacity: 0.5;

        .group-heading-row-bk {
            width: $dropColumnWidth + 2px;
            height: 40px;
            background-color: darken($dropBackgroundColor, 4%);
            position: absolute;


            top: 118px;
            left: 6px + $sidebarWidth - 78px;
            opacity: 0;
        }

        .group-heading-row-bk-bottom {
            top: 436px;
        }

        .group-heading {
            position: absolute;
            //background-color: #fff;
            //background-color: darken($dropBackgroundColor, 4%) !important;
            //background-color: #fff !important;
            box-shadow: 1px 1px 1px 1px rgba(207, 178, 0, 0.2) !important;

            border-radius: 0px;

            border-bottom: 2.5px solid darken($dropBackgroundColor, 8%);
            width: 208px;
            padding-bottom: 4px !important;

            background-color: transparent !important;
            box-shadow: none !important;

            //width: 200px;
            //height: 30px;

            .label {
                margin-top: 0px;
                padding: 12px 4px 3.0px 24px;
                padding-left: 12px !important;
                font-size: 11pt;
                font-weight: 600;
                text-align: left;
                //color: desaturate(lighten($themeYellowFontColor, 25%), 50%) !important;
                //color: $mainHeadingColor !important;
                //color: #333 !important;
                //opacity: .9;
            }

            .widget-heading {
                display: flex;
                padding: 12px 24px 1px 24px;
                padding-left: 12px !important;

                .icon {
                    width: 13px;
                    margin-top: 0px;
                    margin-left: 6px;
                    align-self: center;
                }

                .label {
                    padding: 0px 0 0 2px;
                    padding-left: 0px !important;

                    font-size: 11.25pt;
                    //color: lightsalmon;

                    font-family: $sansFamily;
                    font-weight: 600;
                    letter-spacing: 0;
                    text-transform: none;
                }

                .score {
                    display: none;
                    background-color: lighten(lightsalmon, 24%);
                    margin-left: auto;
                    margin-right: 16px;
                    padding: 3.5px 7px 0 7px;
                    border-radius: 100px;
                    color: lightsalmon;
                    letter-spacing: 0.5px;
                    transform: scale(.925);
                }
            }
        }

        $headingTop: -13px;
        $headingLeft: 48px;

        .group-heading-Teal {
            background-color: lighten($themeTealBackgroundColor, 2%);
            top: $baseTop + $TealTop + $headingTop;
            left: $baseLeft + $TealLeft + $headingLeft;

            .label {
                color: desaturate(lighten($themeTealFontColor, 17%), 20%);
            }
        }

        .group-heading-Yellow {
            background-color: lighten($themeYellowBackgroundColor, 1.5%);
            top: $baseTop + $YellowTop + $headingTop;
            left: $baseLeft + $YellowLeft + $headingLeft;

            .label {
                color: desaturate(lighten($themeYellowFontColor, 34%), 20%);
            }
        }

        .group-heading-Pink {
            background-color: lighten($themePinkBackgroundColor, .0%);
            top: $baseTop + $PinkTop + $headingTop;
            left: $baseLeft + $PinkLeft + $headingLeft;

            .label {
                color: desaturate(lighten($themePinkFontColor, 30%), 40%);
            }
        }

        .group-heading-Purple {
            background-color: lighten($themePurpleBackgroundColor, .5%);
            top: $baseTop + $PurpleTop + $headingTop;
            left: $baseLeft + $PurpleLeft + $headingLeft;

            .label {
                color: desaturate(lighten($themePurpleFontColor, 30%), 40%);
            }
        }
    }
}
@import 'global';

.StartMembershipPage {
    .custom-col {
        display: none;
    }
}

.StartMembership {
    .page-heading {
        color: darken(#ffc27d, 16%);
        color: lighten($negativeColor, 34%);
        font-size: 27pt;
        font-family: 'Merriweather', serif;
        font-weight: 700;
        text-align: left;
        padding: 0 0 0 18px;
    }

    .service-name {
        color: $negativeColor;
        font-size: 27.5pt;
        font-family: "Manrope", ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    }

    .sheet {
        width: 1160px;
        height: 540px;
        background-color: #fff;
        border-radius: 24px;
        margin: 8px 0 0 0;
    }

    .premium-img {
        width: 48px;
        margin: 16px 0 0 0;
    }

    .free-plan-img {
        display: none;
        width: 24px;
        margin: 20px 0 0 0;
        opacity: .8;
    }

    $planWidth: 300px;
    $priceColor: #ff7373;
    $priceColor: $positiveAccentColor;

    .plan-col:hover {
        .plan-cta {
            opacity: 1 !important;
            top: -30px !important;
        }
    }

    .plans {
        display: flex;
        height: 95%;

        .plan-col {
            width: $planWidth;
            min-width: $planWidth;
            max-width: $planWidth;
            border-right: 1.5px solid $backgroundColor;
            margin: 28px 0 0 0;
            height: 90%;
        }

        .plan-summary {
            height: 228px;
        }

        .plan-title {
            //height: 100px;
            padding: 0px 0 12px 0;
            color: darken(#ffc27d, 16%);
            color: lighten($negativeColor, 0%);
            font-size: 17.0pt;
            //font-family: 'Merriweather', serif;
            font-weight: 800;
            text-align: center;
        }

        .plan-price {
            display: flex;
            justify-content: center;

            color: $priceColor;
            font-size: 19pt;
            font-weight: 600;
            font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";

            .decimal {
                margin-top: 2px;
                font-size: 10pt;
            }
        }

        .plan-price-nudge {
            padding-left: 8px;
        }


        .plan-interval {
            padding: 2px 0 0 0;
            font-size: 9.5pt;
            font-weight: 600;
            color: $priceColor;
        }

        .plan-cta {
            width: 200px;
            background-color: #ffb400;
            padding: 12px;
            border-radius: 12px;
            margin: 0 auto;
            color: #fff;
            font-size: 13pt;
            font-weight: 500;
            transition: .25s;

            position: relative;
            top: -12px;
            box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

            opacity: 0;
            cursor: pointer;
        }

        .plan-cta:hover {
            //background-color: lighten($positiveAccentColor, 4%);
            box-shadow: 1px 2px 5px 2px darken(#e3e6f7, 4%);
            transform: scale(1.05);
            text-decoration: underline;
            //color: #ffedca;
        }

        .plan-savings {
            padding: 12px 0 0 0;
            color: darken($priceColor, 0%);
            font-sizE: 10.5pt;
            font-weight: 600;
        }

        .free-plan-col {
            .plan-title {
                color: #52bfeb;
                font-size: 19pt;
                padding-top: 58px;
                height: 92px;
            }
        }

        .features {
            border-bottom: 1.5px solid $backgroundColor;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px 0;
                border-top: 1.5px solid $backgroundColor;
                color: darken($negativeColor, 4%);
                font-size: 11pt;
                font-weight: 700;
            }

            .checkmark-img {
                width: 12px;
                align-self: center;
                margin: 0 8px 0 0;
            }

            .optional {
                font-size: 9pt;
                color: lighten($negativeColor, 40%);
            }
        }

        .free-plan-col .features {
            .item {
                font-weight: 600;
                color: darken(#52bfeb, 10%);
            }
        }
    }

    .coach-col {

        width: 236px;

        border-right: 1.5px solid $backgroundColor;
        border-right: 0;
        margin: 6px 0 0 0;
        height: 90%;
        background-color: #fff;

        justify-content: flex-start;
    }

    .coach-heading {
        padding: 40px 0 0 24px;
        font-size: 11pt;
        font-weight: 700;
        color: lighten($reinforceColor, 15%);
        text-align: left;
    }

    .coach-read-more {
        text-decoration: underline;
    }

    .CoachCard {
        padding-left: 24px;

        .coach-profile-img {
            width: 32px;
            margin: 0;
        }

        .name-label {
            font-size: 11pt
        }
    }

    .coach-intro {
        padding: 0px 4px 0 22px;
        font-family: 'Merriweather';
        font-size: 9.75pt;
        color: darken($reinforceColor, 10%);
        color: #444;

        font-weight: 500;
        text-align: left;
    }
}

.PostSessionScreen {
    margin-left: -32px;
    opacity: 1;
    transition: .6s;

    transform: scale(1.05);
    //transform: scale(.95);
    transform-origin: left;

    .sheet {
        width: 720px;
        height: 478px;
        margin: 7px auto 0px auto !important;
        padding: 0 !important;
        background-color: #fff;
        border-radius: 24px;

        margin-top: -25px !important;

        //transform: scale(1.0);
        transform: top left;
    }

    .heading-content {
        border-bottom: 2px solid $backgroundColor;
        border-bottom: 2px solid #fff;
        padding: 36px 0 0px 0;
    }

    .heading-scale {
        transform: scale(0.925);
        transform-origin: center;
    }

    .better-content {
        padding: 6px 0;


        .subheading-better {
            font-size: 10.5pt;
            font-weight: 800 !important;
            color: $positiveAccentColor;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            text-align: center;
        }

        .heading-row {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .heading-better {
            padding: 6px 0;
            font-size: 21.75pt;
            font-weight: 700;
            color: $negativeColor;
            text-align: center;
        }

        .session-completed-img {
            text-align: center;
            width: 28px;
            margin-right: 12px;
        }
    }

    .buttons-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        transform: scale(0.9);
        transform-origin: top;

        padding: 32px 20px 20px 20px;

        .button-label {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .high-five-img {
            width: 29px;
            margin: -4px 4px 0 -10px;
        }

        .like-img {
            width: 18px;
            margin: -3px 8px 0 -8px;
        }

        .big-button {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;

            width: 180px;
            height: 180px;
            margin-top: 40px;
            border-radius: 200px;
            transform: scale(1.4);
            transform-origin: center;

            background-color: lighten(#ffe7dd, 5%);
            border: 8px solid #fff;
            //background-color: #fff;

            box-shadow: 0.5px 0.5px 2px .5px darken(#ffe7dd, 6%);

            transition: .25s;
            cursor: pointer;

            .button-label {
                font-size: 19pt;
                font-weight: 500;
                color: darken(#ffe7dd, 30%);
                //color: $positiveAccentColor;
                //color: #333;
                text-align: center;
                //opacity: 0.8;
                //font-style: italic;
                transform: scale(0.95);
            }

            .label-text {
                font-weight: 700;
            }
        }

        .big-button.Firing {
            background-color: darken(#ffe7dd, 27%) !important;
            transition: .3s !important;
            transform: scale(1.59) !important;

            .button-label {
                color: lighten($positiveAccentColor, 42%);
            }
        }

        .big-button.Shrink {
            transition: .7s !important;
            transform: scale(.6) !important;
            opacity: 0 !important;
        }

        .big-button:hover {
            background-color: darken(#ffe7dd, 25%);
            border: 8px solid darken(#ffe7dd, 10%);
            transform: scale(1.5);

            box-shadow: 2.5px 2.5px 6px 1.5px darken(#ffe7dd, 6%);

            .button-label {
                color: lighten($positiveAccentColor, 45%);
            }

            .high-five-img {
                transition: .25s;
                transform: rotate(-4deg) scale(1.3);
            }
        }

        .small-buttons {
            position: relative;
            top: -228px;
            left: 248px;
            //display: flex;
            padding: 80px 0 0 0;
            transform: scale(1.0);
            transform-origin: top;
        }

        .small-button {
            width: 180px;
            height: 48px;
            border-radius: 12px;
            margin: 0 8px;
            margin-bottom: 12px;

            background-color: lighten(#eee, 6.5%);
            //background-color: #fff;
            cursor: pointer;

            box-shadow: 0.5px 0.5px 2px .5px darken(#eee, 6%);
            transition: .25s;

            .button-label {
                padding: 13px 0 0 0;
                font-size: 12pt;
                font-weight: 700;
                color: #25aee6;
            }
        }

        .somewhat-button.Firing {
            background-color: lighten(#25aee6, 46%) !important;
            transition: .3s !important;
            transform: scale(1.29) !important;
        }

        .small-button.Shrink {
            transition: .7s !important;
            transform: scale(.6) !important;
            opacity: 0 !important;
            background-color: lighten(#25aee6, 46%) !important;
        }

        .no-button.Firing {
            background-color: lighten(#25aee6, 46%) !important;
            transition: .3s !important;
            //transform: scale(1.29) !important;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
        }

        .small-button:hover {

            background-color: lighten(#25aee6, 44%);
            box-shadow: 2px 2px 3px 2px darken($backgroundColor, 4%);
            transform: scale(1.1);

            .like-img {
                transition: .25s;
                transform: rotate(-8deg) scale(1.3);
            }
        }
    }

    .buttons-content.Locked {
        pointer-events: none;
    }

    .skills-content {
        position: relative;
        top: -350px;
        left: 460px;
        //left: 40px;
        width: 224px;
        height: 300px;
        background-color: transparent;

        top: -292px;
        left: -4px;

        .skills-heading {
            display: flex;
            padding: 0 20px 8px 36px;
            font-size: 9pt;
            color: lighten($reinforceColor, 18%);
            //color: lighten(#25aee6, 14%);
            font-weight: 700;
            text-align: left;
            line-height: 1.5em;
        }

        .score-img {
            width: 28px;
            align-self: center;
            margin: 0 4px 0 -12px;
        }

        .skills-phrase {
            font-weight: 700;
            color: lighten($reinforceColor, 6%);
            text-decoration: underline;
        }

        .skills-list-content {
            transform: scale(0.85);
            transform-origin: top left;

            padding: 0 0 0 8px;
        }

        // SkillList overrides
        .SkillList {
            margin-top: 0px;

            .sidebar-heading {
                display: none;
            }

            .skills {
                margin-top: 0;
            }

            .SkillItem {
                border-bottom: 1.5px solid #fff;
            }
        }
    }

    .confetti-positions {
        position: relative;

        .confetti-yes-left {
            position: absolute;
            top: -600px;
            left: 360px;
        }

        .confetti-yes-right {
            position: absolute;
            top: -600px;
            left: 360px;
        }

        .confetti-somewhat {
            position: absolute;
            top: -640px;
            left: 540px;
        }
    }
}

.answered-content {

    .answered-heading {
        padding: 24px 0 16px 0;
        border-bottom: 2px solid $backgroundColor;

        .heading-label {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .heading-icon {
            width: 40px;
            align-self: center;
        }

        .heading-label-text {
            padding: 8px;
            font-size: 19pt;
            font-weight: 700;
            color: $negativeColor;
        }
    }

    .return-instructions {
        padding: 20px;
        font-size: 10.5pt;
        font-weight: 600;
        color: #333;
        text-align: left;
        color: lighten($reinforceColor, 15%);
    }

    .air-phrase {
        padding: 0 40px;
        font-weight: 700;
        font-size: 25pt;
        color: $negativeColor;
        text-align: left;
    }

    .em-phrase,
    .next-session-phrase {
        font-weight: 700;
        color: $reinforceColor;
        text-decoration: underline;
    }

    .url-phrase {
        width: 200px;
        padding: 12px 30px;
        border: 2px solid darken($backgroundColor, 5%);
        border-radius: 200px;
        text-align: left;
    }
}


.LoggedThought {
    .SessionThought {
        box-shadow: none;
    }
}

.PrepPostSessionEnter {
    .PostSessionScreen {
        margin-left: 32px !important;
        opacity: 0 !important;
    }

    .SessionGrid.IsPostSession {
        margin-top: 80px !important;
        opacity: 0 !important;
    }

    .expanded-chat-label {
        opacity: 0 !important;
    }
}

.SessionGrid.IsPostSession {
    transition: .6s !important;
}

.answered-content.AnswerSomewhat {
    .answered-heading {
        .heading-icon {
            width: 32px;
        }

        .heading-label-text {
            padding: 12px 8px 4px 12px;

            font-size: 15pt;
            font-weight: 700;
            color: #25aee6;
        }
    }
}

.answered-content.AnswerNo {
    .answered-heading {
        padding: 12px 80px;

        .heading-icon {
            width: 32px;
        }

        .heading-label-text {
            padding: 12px 8px 4px 12px;

            font-size: 13pt;
            font-weight: 700;
            color: #25aee6;
        }
    }
}

.better-content.Fade {
    opacity: 0 !important;
    transition: .8s;
}

.GatherEmailCard {
    display: none;
    position: absolute;
    margin-top: 12px;
    width: 340px;
    height: 160px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 1px 1px .5px darken(#e3e6f7, 0%);
    background-color: lighten(#fff8eb, 2%);

    .card-heading {
        text-align: left;
        padding: 18px 28px 6px 28px;
        font-size: 11.5pt;
        font-weight: 700;
        color: darken(#ff7100, 4%);
        text-decoration: underline;
    }

    .card-instructions,
    .card-disclaimer {
        text-align: left;
        padding: 0px 28px;
        font-size: 9.0pt;
        font-weight: 500;
        color: darken(#25aee6, 4%);
        //color: $negativeColor;
        //text-decoration: underline;
    }

    .card-form {
        display: flex;
        padding: 8px 26px;
    }

    .input-text {
        width: 210px;
        max-width: 210px;
        min-width: 210px;
        box-sizing: border-box;
        margin-bottom: 1px;
        padding: 1em 1em 1em 1em;
        color: #666;
        font-weight: 500;
        border: 1.5px solid lighten($positiveAccentColor, 28%);
        border-radius: 3px;
        font-size: 10.5pt;
        outline: none;
        font-family: 'Mulish';
    }

    .submit-button {
        background-color: #25aee6;
        border-radius: 8px;
        border: 0;
        outline: none;
        margin: 0 0 0 4px;
        width: 70px;
        height: 48px;
        color: #fff;
        font-weight: 700;
        font-size: 10pt;
        font-family: 'Mulish';
    }

    .input-text:focus {
        border: 1.5px solid lighten($positiveAccentColor, 24%);
        font-weight: 500;
        color: darken($positiveAccentColor, 2%);
        color: #444;
    }

    .input-text::placeholder {
        color: lighten($positiveAccentColor, 14%);
    }
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.AirPromise {
    position: fixed;
    display: none;
    top: 360px;
    left: 00%;

    transform: translate(-50%, 30%);

    width: 588px;
    height: 220px;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: .5px .5px 4px 2px darken(#e3e6f7, 0%);

    .heading-1 {
        padding: 20px 0 0 0;
        color: $positiveAccentColor;
        font-family: 'Merriweather';
        font-weight: 700;
        font-size: 18.5pt;
    }

    .heading-2 {
        display: flex;
        justify-content: center;
        font-size: 24pt;
        font-weight: 800;
        color: $negativeColor;
    }

    .promise-img {
        width: 38px;
        margin: 0 12px 0 0;
        align-self: center;
    }

    .promise {
        display: flex;
        align-items: center;
        background: lighten(#fcf6ed, 2.0%);
        background-color: lighten(#fff0f2, 2%);
        margin: 10px 18px 0 18px;
        padding: 24px;
        border: 1.5px solid darken(#fff0f2, 5%);
        border-radius: 24px;
    }

    .sign-box {
        width: 28px;
        min-width: 28px;
        max-width: 28px;
        height: 28px;
        border: 2.5px solid lighten($positiveAccentColor, 24%);
        background-color: #fff;
        border-radius: 7px;
    }

    .promise-clause {
        font-size: 11.5pt;
        font-weight: 600;
    }
}
.EmotionComparison {
    opacity: 0;
    padding-top: 40px;
    transition: 0.5s all;
    height: 68px;

    .meter-container {
        //zoom: .9;
        //zoom: 1.1;
    }

    .question-layout {
        display: flex;

        width: 700px;
        margin: 28px auto;
    }

    .question-label {
        width: 192px;

        color: lighten(#6a1fe5, 0%);
        font-weight: 600;
        font-size: 12pt;

        //text-align: right;

        padding: 24.5px 0px 0 0;
    }

    .improvement {
        width: 200px;

        border-radius: 100px;
        background-color: lighten(#0ea2df, 48%);
        padding-top: 14px;
        margin: 8px 0 0 20px;
        color: darken(#0ea2df, 0%);
        //color: lighten(#797DEA, 0%);
        font-weight: 600;
        font-size: 12pt;
    }

    .improvement-none {
        background-color: #fff;
        color: #bbb;
    }



    .ratings {
        padding: 10px 0 0 20px;
        display: flex;
        //width: 150px;
        justify-content: flex-end;
        // align-content: center;
        // justify-content: center;
        zoom: 0.95;

        .rating {
            width: 62px;
            min-width: 62px;
            height: 52px;
            color: #fff;
            //background: rgb(13,241,115);
            //background: linear-gradient(180deg, rgba(244,219,55,0.9) 0%, rgba(250,171,33,1) 100%);
            text-align: center;
            border-radius: 8px;

            font-size: 11.5pt;
            font-weight: 600;
            padding-top: 16px;
            box-sizing: border-box;
        }


        .old-rating {
            //zoom: 0.9;
        }

        .arrow {
            width: 24px;
            align-self: center;
            margin: 2px 2px 0 2px;

            .arrow-img {
                width: 16px;
                opacity: 0.2;
            }
        }
    }

    .rating {
        font-size: 13.5pt !important;
        padding-top: 14px !important;

        background-color: #f9f6fe;
        color: #6a1fe5 !important;
    }


    .ratings-new .rating {
        background: lighten(#e6b812, 46%) !important;
        color: lighten(#fd6f53, 6%) !important;
        font-size: 13.5pt;
        padding-top: 14px;
    }
}

.EmotionComparison.Visible {
    opacity: 1;
    padding-top: 0;
}
.ResponseLoadingGrid {
    margin-top: -6px;
    margin-left: -4px;

    .loading-grid {
        padding: 0;
        padding-top: 2px;

        .loading-placeholders {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            padding: 0px 0px 20px 0px;
        }

        .option {
            height: 102px;

            width: 260px;
            height: 113.5px;
        }

        .option-content {
            zoom: 0.9;
            opacity: 0.2;

            padding: 6px;
        }

        .loading-placeholder {

            width: 48%;
            //zoom: 0.95;

            box-sizing: border-box;

            //opacity: 0.7;
            margin-top: 0px;

            height: 22px;

            background-color: lighten(#d1eff7, 10%);
            background-color: #fff;
            //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

            border-radius: 12px;
            margin: 8px 0px 4px 0;
            //margin-left: -14px;
        }
    }

    .loading-position {
        position: relative;

        .loading-box {
            position: absolute;
            top: -358px;
            left: 150px;

            width: 240px;

            padding: 28px 0 12px 0;

            background-color: #fff;
            box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
            border-radius: 40px;

            zoom: 1.05;
        }

        .chatbot-img {
            display: none;
            width: 32px;
        }

        .label {
            padding: 12px 0;
            color: darken(#0ea5e4, 2%);
            font-size: 10pt;
            line-height: 1.4em;
        }
    }
}
@import 'global';

$progressBarHeight: 6px;

.ReframingList .item.IsMatch {
    .label {
        height: 48.5px;
        padding-right: 38px !important;
    }
}

.ReframingList {

    .instructions {
        padding: 8px 20px 26px 4px;
        text-align: left;
        font-size: 10.5pt;
        color: #666;
        //color: darken(#0ea5e4, 2%);
        font-weight: 600;
        line-height: 1.4em;
    }

    .completion-tag {
        float: left;
        margin: -12px 0 0 -8px;
        margin-bottom: 10px;
        //margin: 12px auto;
        padding: 0.7em 1.5em;
        background-color: #eee;

        font-size: 10pt;
        font-weight: 800;
        border-radius: 100px;

        background-color: #e2e3ff;
        color: lighten(#4447A1, 10%);

        background-color: lighten(#d7f3ff, 2%);
        color: darken(#29b0e7, 10%);

        background-color: darken(#29b0e7, 0%);
        zoom: 0.9;

        background-color: darken(#f2845a, 0%);
        background-color: $positiveAccentColor;

        background-color: #fff;
        color: $positiveAccentColor !important;
    }


    .list {
        //display: none;
    }

    .item {
        //float: left;
        background-color: lighten(#e6b812, 49%);
        //background-color: lighten(#e6b812, 50%);
        //background-color: #fff;
        //background-color: transparent;
        //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 3%);
        border-radius: 8px;



        display: flex;
        flex-direction: column;
        width: 280px;
        height: 121.25px;
        height: 114px;
        max-height: 114px;
        //height: 96px;
        //height: 92px;
        box-sizing: border-box;

        font-size: 12.5pt;
        //font-size: 14.5pt;
        font-weight: 500;
        padding: 1.0em 38px 12px 26px;
        padding-right: 24px;
        padding-top: 15px;
        //padding-top: 0.85em;

        text-align: left;
        //text-align: center;
        line-height: 1.45em;


        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        margin: 10px 16px -5px -8px;

        background-color: lighten(#d1eff7, 11%); // Was 10% for long time
        background-color: #fff;
        //background-color: lighten(#e6b812, 49.5%);
        //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 1%);

        transition: 0.175s;

        .checkmark-img {
            float: left;
            width: 15px;
            margin: 5px 6px 0 0px;
        }

        .label {
            padding-right: 20px;
            font-size: 12.75pt;
            font-size: 12pt;
            font-weight: 500;
            text-align: left;
            color: $reinforceColor;

            //opacity: 0.8;

        }
    }

    .item:hover {
        //text-decoration: underline;
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .item.IsSelectedHighlight {
        transform: scale(1.05) !important;
        margin-left: 8px !important;
        box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 2%);
    }

    .item.IsSelected {
        //background-color: transparent !important;
        margin-left: -4px;

        transform: scale(1.0) !important;
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .item.IsSelectedHidden {
        //height: 0 !important;
        opacity: 0 !important;
    }

    .stage-progressbar {
        width: 87%;
        height: $progressBarHeight;
        margin-top: 20px;
        margin-right: 6px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 35%);
        background-color: lighten($positiveAccentColor, 46%);
    }

    .stage-fillbar {
        width: 60%;
        height: $progressBarHeight;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 20%);
        background-color: lighten($positiveAccentColor, 24%);

        background: rgb(255, 202, 107);
        background: $progressGradient;

        transition: .5s;
    }

    .match-tag-container {
        position: relative;
        width: 0px;
    }

    .match-tag {
        position: absolute;
        left: 188px;
        //left: 166px;
        top: -21px;

        top: -46px;

        width: 40px;
        background-color: lighten($positiveAccentColor, 46%);
        border-radius: 12px;

        margin-left: 0px;
        padding: 0px 8px;
        font-size: 8.0pt;
        font-weight: 800;
        text-transform: uppercase;
        color: $positiveAccentColor;
    }

    .AreaTag.IsMatch {
        margin-top: -22px !important;

        .area-img {
            margin-left: 4px !important;
        }
    }

    .AreaTag {
        //opacity: 0;
        // width: auto;
        // min-width: auto;
        // max-width: auto;
        // flex-grow: 0;
        margin-top: auto;
        margin-left: auto;
        padding-right: 0px;

        width: 16px;
        min-width: 16px;
        max-width: 16px;

        //margin-left: -9px !important;

        .area-icon {
            margin-right: 0 !important;
            transform: scale(1.25);
        }

        .area-name {
            display: none;
            padding-top: 3.5px;
            padding-left: 0px !important;
            margin-left: -4px !important;
            font-size: 9pt !important;
            font-family: $sansFamily;
            font-weight: 600;
            color: $positiveAccentColor;
            //opacity: 0.7;
            letter-spacing: 0px;
        }
    }

    .AreaTag.AreaTagOpenness {
        .area-img {
            margin-left: 2px;
            //margin-left: 4px;
        }
    }

    .AreaTag.AreaTagMood {
        .area-img {
            margin-left: 3px;
            //margin-left: 4px;
        }
    }

    .AreaTag.AreaTagEase {
        .area-img {
            margin-left: 1px;
            //margin-left: 4px;
        }
    }
}

.ReframingList.IsLoading {
    .label {
        opacity: 0.5 !important;
    }

    .ReframingRefreshToolbar {
        opacity: 0;
    }
}

.ReframingList.IsCompleted {
    .item {
        display: none;
    }

    .item.IsSelected {
        display: block;
        background-color: #fff;
        float: left;
        clear: both;
        width: 460px;

        margin-left: -8px;

        transition: none;

        .checkmark-img {
            padding: 0 0 0 12px;
        }

        .label {
            padding-top: 3px;
            padding-right: 0;
        }
    }

    .item.IsGrown {
        background-color: #fff;
        width: 520px;
        height: 221px;
        border-radius: 18px;
        padding: 24px 36px;
        padding-top: 22px;
        padding-bottom: 42px;
        padding-left: 28px;

        margin-left: -8px;

        box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);

        transition: .3s !important;
    }

    .item.IsGrown:hover {
        cursor: default;
        transform: none;
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }


}
@import 'global';

.ThoughtLog {
    padding: 0px 2px 0 13.5px;

    //background-color: #eee;

    height: 460px;

    //display: none;


    .thoughts-flow {
        height: 480px;
        overflow-y: auto;

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        padding-bottom: 40px;

        transition: height 0.2s ease-in-out;
        /* Smooth transition */


    }

    .thoughts-flow.IsExpanded {
        height: 468px;
    }

    .thoughts-flow::-webkit-scrollbar {
        display: none;
    }

    .log-placeholder {
        background-color: lighten($backgroundColor, 2%);
        border-radius: 12px;
        margin-right: 18px;
        margin-left: 8px;
        height: 136px !important;
    }
}


.ThoughtLogSection {
    margin-top: 61px;
    margin-top: 70px;
    margin-top: 80.0px;

    margin-top: -11px;

    padding-top: 85px;
    padding-top: 77.0px; // was 83
    padding-top: 102px;
    border-top: 1.5px solid lighten($backgroundColor, 0%);
    border-top: 1.5px solid lighten($backgroundColor, 100%);

    padding-left: 4px;
    padding-bottom: 0px;
    padding-left: 6.5px;
}

.ThoughtLogCard {
    //transform: scale(0.85);
    zoom: 0.9;
    //zoom: 0.825;

    background-color: #fff;

    margin: 0 !important;
    //margin: 0 2px 0 0px !important;
    height: 138px !important;
    //height: 128px !important;

    position: relative;
    transform: translateY(0px);

    transition: .4s;
}

.THoughtLogCard.IsNewest {
    background-color: #ddd !important;


}

.ThoughtLogCard.Selected {
    opacity: 0 !important;
}

.ThoughtLogCard.ReinforceInProgress {
    background-color: transparent;
}

.ThoughtLogCard.SessionThoughtCompleted .ThoughtSetContent {

    height: 132px !important;
    //height: 124px !important;

    .flat-content {
        box-shadow: none;
        border: 1px solid lighten(#dcd2bd, 8.5%);
        box-shadow: 0.5px 0.5px 2px 0px lighten(#dcd2bd, 9%);
        // border: 1.5px solid darken($backgroundColor, 4%) !important;
        // border-top: 1.5px solid darken($backgroundColor, 1%) !important;
        // border-left: 1.5px solid darken($backgroundColor, 1%) !important;

        // border: 1.5px solid lighten($voiceColor, 46%) !important;
        // border-top: 1.5px solid lighten($voiceColor, 49%) !important;
        // border-left: 1.5px solid lighten($voiceColor, 49%) !important;

        // border: 2px solid darken(#fafafa, 4%) !important;
        // border-top: 2px solid darken(#fafafa, 2%) !important;
        // border-left: 2px solid darken(#fafafa, 2%) !important;

        //background-color: darken(#fffdfa, 1%) !important;
        //background-color: lighten($backgroundColor, 2%) !important;

        margin: 2px 12px 2px 0px !important;
        padding: 12px 0px 8px 24px;
    }

    .area-tags {
        //opacity: 0 !important;
    }

    .original-content {
        display: none !important;
    }

    .progress-content {
        //padding-top: 12px !important;
    }
}

.ThoughtLogCard.SessionThoughtCompleted .ThoughtSetContent:hover {
    .flat-content {
        cursor: pointer;
        transform: scale(1.025);
        box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 4%) !important;
        transition: .175s;
    }
}

.ThoughtLogCard.FadedOut,
.ThoughtLogCard.NewFadedOut {
    opacity: 0.0;
    /* Keep in normal flow */

    position: relative;
    transform: translateY(40px) !important;
    transition: .7s !important; // I believe this is being ignored right now
    /* Start below visible area */
    //animation: slideUp 0.5s ease-in-out forwards;
    /* Adjust animation as needed */
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.ThoughtLogCard.FadingIn .ThoughtSetContent {
    opacity: 1;
    position: absolute;
    top: 0px;
    transition: 1s !important; // I believe this is being ignored right now
}

// .fade-container {
//     position: relative;
//     /* Needed for pseudo-element positioning */
//     overflow: hidden;
//     /* Hide overflowing content by default */
//     padding-bottom: 50px;
//     /* Adjust padding to accommodate gradient */

//     height: 200px;
// }

// .fade-container::after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     /* Expands to fill container */
//     background-image: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 1) 100%);
// }

.fade-border {
    background-repeat: repeat-x;
    width: 100%;
    height: 40px;
    //border: 1px solid #333;

    position: relative;
    margin-top: -40px;
    z-index: 1000;
}

@media (max-height: 756px) {

    .ThoughtLog {
        height: 420px;
    }

    .thoughts-flow {
        height: 378px !important;

    }

    .thoughts-flow.IsExpanded {
        height: 428px !important;
    }
}

@media (max-height: 716px) {

    .ThoughtLog {
        height: 390px;
    }

    .thoughts-flow {
        height: 344px !important;

    }

    .thoughts-flow.IsExpanded {
        height: 428px !important;
    }
}

$notesColor: $voiceColor;

.ThoughtLogItem {
    margin: 2px 12px 0px 12px;
    padding: 8px 0 0px 20px;
    border-radius: 12px;
    border: 1.5px solid lighten($neutralColor, 48%);

    .label {
        padding: 8px 18px 4px 0px;
        font-size: 10.25pt !important; // Was 11.25pt
        line-height: 1.4em !important;
        font-family: $serifFamily;
        font-weight: 500;
        color: $neutralColor;
        text-align: left;
    }

    .notes {
        padding: 0px 12px 0 6px;

        .explore-item {
            display: flex;
            align-items: flex-start;

            .item-number {

                padding: 3px 0px;
                font-size: 9.5pt;
                font-weight: 500;

                color: $positiveAccentColor;
                color: $voiceColor;
                color: lighten($notesColor, 2%);
                opacity: .8;
            }

            .item-label {
                padding: 2px 6px;
                text-align: left;

                font-size: 9.5pt;
                font-weight: 500;

                color: $neutralColor;
                color: darken(#00c9e1, 2%);
                color: $voiceColor;
                color: darken($notesColor, 6%);
                font-family: $sansFamily;
                //font-family: 'Merriweather';
                //color: darken($negativeColor, 20%);
                line-height: 1.45em;
            }
        }


    }
}
.AiMessage {
    position: absolute;

    .julia-message {
        position: relative;
        top: -20px;
        left: 6px;
        width: 240px;
        background-color: #fff;
        background-color: lighten(#fcebcd, 8%);
        background-color: #fff;

        border-radius: 16px;

        transition: .3s all;

        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 4%);

        // animation-duration: .5s;
        // animation-name: jumpIn;
        // animation-iteration-count: 1;
        // animation-direction: normal;
        //animation-timing-function: ;

        padding: 20px 20px;

        display: inline-flex;
        align-items: center;

        opacity: 1;
        top: 100px;
        //display: none;

        .message-face-img {
            width: 28px;
            align-self: center;
        }

        .message-thumbs-up-img {
            width: 26px;
            align-self: center;
        }

        .label {
            //width: 220px;
            padding: 0 4px 0 10px;
            font-size: 11.0pt;
            font-weight: 600;
            color: darken(#0ea5e4, 1%);
            text-align: left;
        }

        .spinner {
            margin-left: auto;
            opacity: 0.5;
        }

        .up-arrow {
            opacity: 1 !important;
            margin-left: 6px;

            .arrow-img {
                width: 16px;
            }
        }
    }

    .ThoughtGridMessage {
        top: 130px;
        left: 138px;
    }

    .ShowStartingMessage {
        .julia-message {
            display: flex !important;
            top: -30px !important;
            opacity: 1 !important;
        }
    }
}

.ThoughtGrid2 {
    .julia-message {
        //display: none;
        // top: -80px;
        // opacity: 0;
        //pointer-events: none;
    }
}

// .IsLoading {
//     .julia-message {
//         display: flex !important;
//         top: 120px !important;
//         opacity: 1 !important;
//     }
// }

@keyframes jumpInStartSession {
    0% {
        //top: -2px;
        margin-top: 30px;
        opacity: 0;
    }

    100% {
        //top: -32px;
        margin-top: 0px;
        opacity: 1;

    }
}

@keyframes jumpInCustomThought {
    0% {
        top: 44px;
        opacity: 0;
    }

    100% {
        top: -4px;
        opacity: 1;
    }
}

.AiMessage.KeepGoingType {
    .julia-message {
        //width: 400px !important;
        top: 124px;
        width: 210px;
    }
}

.AiMessage.CustomThoughtType {
    .julia-message {
        width: 328px;
        left: 56px;
        top: -4px;
        animation: jumpInCustomThought 0.3s ease 0s 1;
    }
}

.AiMessage.StartSessionType,
.AiMessage.EndSessionType {
    .julia-message {
        width: 240px;
        left: 6px;
        top: -32px;
        animation: jumpInStartSession 0.3s ease 0s 1;
    }
}

.AiMessage.AboutMeWorkType {
    .julia-message {
        width: 240px;
        left: 6px;
        top: -32px;
        animation: jumpInStartSession 0.3s ease 0s 1;
        transition: .3s all;
    }
}

.AiMessage.JumpOut {
    .julia-message {
        margin-top: 30px !important;
        opacity: 0 !important;
    }
}

.AiMessage.ReframingType {
    .julia-message {
        width: 380px;
        left: 26px;
        top: -32px;
        animation: jumpInStartSession 0.3s ease 0s 1;
    }
}

.AiMessage.Hidden {
    .julia-message {
        opacity: 0;
        margin-top: 80px;
    }
}
@import 'global';

$purpleOffLabelColor: lighten(#6a1fe5, 30%);
$blueOffLabelColor: lighten(#16b0ff, 28%);
$orangeOffLabelColor: lighten(#f89200, 13%); // was f76148

$tealBackgroundColor: desaturate(darken(#f0ffff, 2.5%), 25%);
$tealFillColor: desaturate(darken(#3fefff, 0%), 25%);
$tealBubbleFillColor: desaturate(darken(#3fefff, 19%), 10%);
$tealFadedFontColor: desaturate(darken(#3fefff, 26%), 25%);


.EmotionMeter {

    padding: 0;
    zoom: 1.1;

    .meter-question {
        padding: 8px 20px 0 20px;
        text-align: center;
        font-size: 10.5pt;
        color: #666;
        font-weight: 700;
        line-height: 1.4em;
    }

    .meter-thought-layout {
        display: flex;
        display: none;
    }

    .meter-thought-col {
        width: 50%;
    }

    .meter-thought-repeat {
        padding: 18px 8px 8px 8px;
        //padding-bottom: 0;
        font-size: 11.5pt;
        font-weight: 700;
        color: darken(#797DEA, 5%);
        line-height: 1.4em;

        text-align: center;
    }

    .meter-thought-repeat-new {
        //padding-bottom: 0;
        //padding-top: 4px;
        //font-size: 10.5pt;
        font-weight: 700;
        color: lighten(#fd6f53, 3%);
        line-height: 1.4em;

        text-align: left;

        height: 40px;
    }

    .meter-numbers {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }

    .meter-thought-repeat-number {
        background-color: lighten(#d8d9f6, 7%);
        padding: 12px;
        border-radius: 10px;
        width: 40px;
        //margin: 4px auto;
        margin: 8px 4px;

        color: darken(#797DEA, 4%);
        color: #fff;
        font-weight: 700;
        float: right;
    }

    .meter-thought-repeat-number-new {
        float: left;
        background-color: lighten(#fbded9, 6%);
        background-color: darken(#fefbef, 1%);
        background-color: #ccf3be;
        background-color: #fff;
        color: lighten(#fd6f53, 3%);
        color: #fff;
        color: darken(#ccf3be, 20%);
        color: #555;

        padding-left: 12px;
    }

    .meter-fill {
        display: flex;
        flex-direction: row-reverse;
        width: 500px;
        width: 480px;
        height: 40px;
        border: 0px solid #fff;
        border-radius: 20px;
        margin: 14px auto;

        background: linear-gradient(90deg, lighten(#a2eaf2, 3%) 0%, lighten(#7bc0f4, 3%) 50%, lighten(#9295e7, 3%) 100%);
        background: lighten(#6a1fe5, 4%);

        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

        zoom: 0.95;
    }

    .meter-gauge {
        width: 27.4px;
        margin-left: -.5px;
    }

    .meter-gauge.HoverGauge {
        background-color: lighten(#10c77b, 0%);
        background-color: transparent;

        .label {
            color: #fff;
            font-weight: 600;
            text-align: center;
        }
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);

        .label {
            color: #999;
            color: lighten(#797DEA, 30%);
            //opacity: 0.6;
            font-weight: 700;
        }
    }

    .meter-gauge.NewThoughtHoverGauge {
        background-color: lighten(#ffe985, 20%);
        background: linear-gradient(90deg, rgba(254, 251, 239, 1) 0%, rgba(254, 246, 200, 1) 1%, lighten(#ffee8b, 10%) 66%, lighten(#ffee8b, 1%) 100%);
        //background: linear-gradient(90deg, rgba(254,251,239,1) 0%, rgba(254,246,200,1) 31%, lighten(#ffee8b, 10%) 100%);

        .label {

            color: lighten(#fd6f53, 13%);
            //opacity: 0.6;
            //font-weight: 700;
        }
    }

    .meter-gauge.ZeroHoverGauge {

        .label {
            color: #fff;
            font-weight: 700;
        }
    }

    .label {
        text-align: center;
    }

    .meter-gauge.OffGauge {
        background-color: #fff;
        //background-color: #fefbef !important;
    }

    .meter-gauge.HundredGauge {
        .label {
            left: 4.5px;
        }
    }


    .meter-gauge-top {
        //background-color: #ddd;
        border-radius: 0 18px 18px 0;
    }

    .meter-gauge-top.HoverGauge {
        background-color: #fff;
        //background-color: lighten(#ffe985, 20%);
        //background: linear-gradient(90deg, rgba(254,251,239,1) 0%, rgba(254,246,200,1) 1%, lighten(#ffee8b, 10%) 66%, lighten(#ffee8b, 1%) 100%);
        //background-color: #fefbef;
    }

    .meter-gauge-bottom {
        //background-color: #ddd;
        border-radius: 18px 0 0 18px;
    }

    .meter-reading {
        font-size: 13.5pt;
        font-weight: 700;
        color: #555;
        margin-top: -8px;
        text-align: center;
    }


    .label {
        position: relative;
        top: 12px;
        left: 8.5px;
        font-size: 10pt;
        //color: #bbb;
    }



}

.EmotionMeter.IsHoverNotActive {
    .meter-gauge {
        background-color: #fff !important;
        background: #fff !important;

        .label {
            //color: #bbb;
            opacity: 1 !important;
            font-weight: 400 !important;
        }
    }

    .floating-thought-new {
        opacity: 0 !important;
    }

    .floating-thought-bubble,
    .floating-thought-bubble-new {
        opacity: 0 !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillPurple {
    .meter-gauge {
        background-color: lighten(#f1f1ff, 1%) !important;
        background-color: lighten(#d9ebff, 5%) !important;
        background-color: lighten(#f1e9f9, 2%) !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillBlue {
    .meter-gauge {
        background-color: lighten(#d9ebff, 5%) !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillOrange {
    .meter-gauge {
        background-color: lighten(#fff5f1, .5%) !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillTeal {
    .meter-gauge {
        background-color: $tealBackgroundColor !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillPurple {
    .label {
        color: $purpleOffLabelColor;
        font-weight: 600 !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillBlue {
    .label {
        color: $blueOffLabelColor;
        font-weight: 600 !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillOrange {
    .label {
        color: $orangeOffLabelColor;
        font-weight: 600 !important;
    }
}

.EmotionMeter.IsHoverNotActive.FillTeal {
    .label {
        color: $tealFadedFontColor !important;
        font-weight: 600 !important;
    }
}

.EmotionMeter.FillPurple {
    .meter-gauge.OffGauge {
        background-color: lighten(#d9ebff, 5%) !important;
        background-color: lighten(#f1e9f9, 2%) !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: lighten(#d9ebff, 5%) !important;
        background-color: lighten(#f1e9f9, 2%) !important;
    }

    .meter-gauge.OffGauge,
    .meter-gauge.meter-gauge.ZeroHoverGauge {
        .label {
            color: $purpleOffLabelColor !important;
            font-weight: 600 !important;
        }
    }
}

.EmotionMeter.FillBlue {
    .meter-gauge.OffGauge {
        background-color: lighten(#d9ebff, 5%) !important;
        //background-color: lighten(#f1e9f9, 2%) !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: lighten(#d9ebff, 5%) !important;
        //background-color: lighten(#f1e9f9, 2%) !important;
    }

    .meter-fill {
        background-color: darken($neutralColor, 0%); // was f88300
    }

    .meter-gauge.OffGauge,
    .meter-gauge.meter-gauge.ZeroHoverGauge {
        .label {
            color: darken($blueOffLabelColor, 3%) !important;
            font-weight: 600 !important;
        }
    }
}

.EmotionMeter.FillOrange {
    .meter-gauge.OffGauge {
        background-color: lighten(#fff5f1, .5%) !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: lighten(#fff5f1, .5%) !important;
    }

    .meter-fill {
        background-color: darken(#ffb93f, 0%); // was f88300
    }

    .meter-gauge.OffGauge,
    .meter-gauge.meter-gauge.ZeroHoverGauge {
        .label {
            color: $orangeOffLabelColor !important;
            font-weight: 600 !important;
        }
    }
}

.EmotionMeter.FillTeal {
    .meter-gauge.OffGauge {
        background-color: $tealBackgroundColor !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: $tealBackgroundColor !important;
    }

    .meter-fill {
        background-color: $tealFillColor; // was f88300
    }

    .meter-gauge.OffGauge,
    .meter-gauge.meter-gauge.ZeroHoverGauge {
        .label {
            color: $tealFadedFontColor !important;
            font-weight: 600 !important;
        }
    }

    .number-bubble {
        background-color: $tealBubbleFillColor !important;
    }
}

.EmotionMeter.IsFirst {
    .meter-gauge.OffGauge {
        background-color: #fff !important;
    }

    .meter-gauge-top.HoverGauge {
        background-color: #fff !important;
    }
}

.EmotionMeter.IsFirst.IsHoverNotActive {
    .meter-gauge {
        background-color: #fff !important;
    }
}




.EmotionMeter {
    cursor: pointer;

    .floating-thoughts-container {
        // display: none;
        position: absolute;
        // cursor: pointer;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;

        pointer-events: none;

        z-index: 1000;
    }

    .floating-thought-bubble {
        width: 150px;
        height: 34px;
        position: relative;
        //top: -300px;
        //left: -176px;
        text-align: left;

        background-color: #fff;
        background-color: lighten(#dfe0f8, 5%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        border-radius: 40px;

        padding: 0px 16px;
        text-align: center;

        font-size: 11pt;
        font-weight: 600;
        color: #6368e7;
        //border: 2px solid #fafafa;

        height: 44px;
        line-height: 44px;
        text-align: center;
        padding-bottom: 4px;

        .thought-text {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
    }

    .floating-thought-bubble-new {
        left: 230px;
        text-align: center;
        color: #fd7c62;
        background-color: darken(#fefbef, 4%) !important;
        //border: 1px solid darken(#fefbef, 9%)
    }

    .floating-thought {
        position: relative;
        //top: -300px;
        top: -89px;
        top: -15px;
        top: -52px;
        top: -57px;
        //background-color: lighten(#6a1fe5, 9%);

        //top: -51px;
        left: 10px;
        text-align: left;
        //width: 120px;

        //box-shadow: 1px 2px 3px 1px darken(#eee, 0%);

        z-index: 100;

        display: flex;
        justify-content: center;

        .floating-checkmark-img {
            width: 10px;
            margin-right: 2px;
            align-self: center;
        }

        .bubble {
            position: relative;
        }

        .number-bubble {

            top: 0;
            left: -4px;

            width: 40px;
            background-color: lighten($voiceColor, 2%);

            text-align: center;

            font-size: 9.5pt;
            font-weight: 600;
            color: #111;

            //opacity: 0.5;

            padding: 0px 0px;

            font-size: 11.5pt;
            padding: 12px 10px 12px 10px;
            padding: 14px 13px;
            //padding: 9px 10px;
            //padding: 11px 6px;
            border-radius: 10px;
            border-radius: 12px;
            color: #fff;
            color: #6a1fe5;
            color: #fff;
        }

        .more-bubble {
            //opacity: 0;
            top: 52px;
            left: -128px;

            width: 160px;

            //width: 88px;
            //background-color: lighten($voiceColor, 46%);
            border-radius: 12px;

            font-size: 10.5pt;
            color: $voiceColor;
            font-weight: 600;
            padding: 2px 13px;
            text-align: center;

            .rating-phrase {
                font-weight: 700 !important;
            }
        }
    }

    .floating-thought-new {
        left: 170px;
        color: #fd7c62;
        background-color: #fefbef !important;
        background-color: darken(#fefbef, 5%) !important;
        //border: 1px solid darken(#fefbef, 9%)
        z-index: 100;
    }

    .labels-content {
        display: flex;

        .label {
            padding-left: 24px;
            margin-top: -12px;
            font-size: 10.0pt;
            font-weight: 500;
            color: #888;
            //color: #fff;
            //color: darken(#d9ebff, 16%);
            //color: #9cd5ed;
            //            opacity: 0.3;
            z-index: 0;
        }

        .label-end {
            padding-right: 32px;
            margin-left: auto;
        }
    }
}

.EmotionMeter.IsHoverNotActive {
    .floating-thought {
        opacity: 0;
    }
}

.EmotionMeter.IsCompleted {
    cursor: default !important;

    .floating-thought {
        transition: 0.075s ease-in-out;
        top: -54.5px !important;
        //zoom: 1.15;
    }
}

.EmotionMeter.FillPurple {}


.EmotionMeter {

    // .rating-0 {
    //     background: linear-gradient(0deg, darken(#b1ee99, 17%) 0%, lighten(#b1ee99, 3%) 100%);
    // }

    // .rating-5 {
    //     background: linear-gradient(0deg, darken(#b1ee99, 17%) 0%, lighten(#b1ee99, 3%) 100%);
    // }

    // .rating-10 {
    //     background: linear-gradient(0deg, darken(#b1ee99, 17%) 0%, lighten(#b1ee99, 3%) 100%);
    // }

    // .rating-15 {
    //     background: linear-gradient(0deg, darken(#b7ec95, 17%) 0%, lighten(#b7ec95, 3%) 100%);
    // }

    // .rating-20 {
    //     background: linear-gradient(0deg, darken(#beec8e, 17%) 0%, lighten(#beec8e, 3%) 100%);
    // }

    // .rating-25 {
    //     background: linear-gradient(0deg, darken(#c5e988, 17%) 0%, lighten(#c5e988, 3%) 100%);
    // }

    // .rating-30 {
    //     background: linear-gradient(0deg, darken(#cae985, 17%) 0%, lighten(#cae985, 3%) 100%);
    // }

    // .rating-35 {
    //     background: linear-gradient(0deg, darken(#d1e881, 17%) 0%, lighten(#d1e881, 3%) 100%);
    // }

    // .rating-40 {
    //     background: linear-gradient(0deg, darken(#d7e67e, 17%) 0%, lighten(#d7e67e, 3%) 100%);
    // }

    // .rating-45 {
    //     background: linear-gradient(0deg, darken(#dde477, 17%) 0%, lighten(#dde477, 3%) 100%);
    // }

    // .rating-50 {
    //     background: linear-gradient(0deg, darken(#e3e273, 17%) 0%, lighten(#e3e273, 3%) 100%);
    // }

    // .rating-55 {
    //     background: linear-gradient(0deg, darken(#ebdf6e, 17%) 0%, lighten(#ebdf6e, 3%) 100%);
    // }

    // .rating-60 {
    //     background: linear-gradient(0deg, darken(#f1de6a, 17%) 0%, lighten(#f1de6a, 3%) 100%);
    // }

    // .rating-65 {
    //     background: linear-gradient(0deg, darken(#f0d361, 17%) 0%, lighten(#f0d361, 3%) 100%);
    // }

    // .rating-70 {
    //     background: linear-gradient(0deg, darken(#f0c859, 14%) 0%, lighten(#f0c859, 3%) 100%);
    // }

    // .rating-75 {
    //     background: linear-gradient(0deg, darken(#efc354, 12%) 0%, lighten(#efc354, 3%) 100%);
    // }

    // .rating-80 {
    //     background: linear-gradient(0deg, darken(#eebb4e, 11%) 0%, lighten(#eebb4e, 3%) 100%);
    // }

    // .rating-85 {
    //     background: linear-gradient(0deg, darken(#eeaf46, 10%) 0%, lighten(#eeaf46, 3%) 100%);
    // }

    // .rating-90 {
    //     background: linear-gradient(0deg, darken(#efa441, 9%) 0%, lighten(#efa441, 3%) 100%);
    // }

    // .rating-95 {
    //     background: linear-gradient(0deg, darken(#eb913c, 8%) 0%, lighten(#eb913c, 6%) 100%);
    // }

    // .rating-100 {
    //     background: linear-gradient(0deg, darken(#e97d35, 7%) 0%, lighten(#e97d35, 7%) 100%);
    // }
}

.EmotionMeter.FillBlue {
    .floating-thought {
        background-color: darken($blueOffLabelColor, 22%);
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);

        .label {
            color: #999;
            color: darken(#e97d35, 22%);
            color: $neutralColor;
            //opacity: 0.6;
            font-weight: 700;
        }
    }
}

.EmotionMeter.FillOrange {
    .floating-thought {
        background-color: darken($orangeOffLabelColor, 4%);
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);

        .label {
            color: #999;
            color: darken(#e97d35, 22%);
            //opacity: 0.6;
            font-weight: 700;
        }
    }
}

.EmotionMeter.FillTeal {
    .floating-thought {
        background-color: transparent; // Because of the two floating bubbles that we do for QuestionCards.
    }

    .meter-gauge.CurrentHoverGauge {
        //background-color: lighten(#10c77b, 0%);
        //background-color: #333 !important;

        .label {
            color: #999;
            color: $tealFadedFontColor !important;
            //opacity: 0.6;
            font-weight: 700;
        }
    }
}

.EmotionMeter.ShowCheck {
    .floating-thought {
        transform: scale(1.125);
        top: -65px !important;
        padding: 9.6px 13px;
        background-color: lighten(#6a1fe5, 14%);
        transition: .2s;
    }

    .floating-thought {
        .floating-label {
            font-size: 10.5pt;
        }
    }

    .number-bubble {
        display: flex;
        background-color: darken($tealBubbleFillColor, 4%) !important;
    }

    .more-bubble {
        opacity: 0 !important;
    }
}

.EmotionMeter.ShowCheck.FillBlue {
    .floating-thought {
        background-color: darken($blueOffLabelColor, 8%) !important;
    }
}

.EmotionMeter.ShowCheck.FillOrange {
    .floating-thought {
        background-color: lighten($orangeOffLabelColor, 12%) !important;
    }
}

.EmotionMeter.ShowCheck.FillTeal {
    .floating-thought {
        background-color: lighten(#3fe2ff, 12%) !important;
        background-color: transparent !important;
    }
}


.EmotionMeter.IsShort {
    .meter-fill {
        width: 406px;
    }
}
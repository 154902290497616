@import 'global';

$borderColor: lighten(#fdf4f0, 0%);
;

.SkillList {
    margin: 0 0 0 4px;

    margin-top: 106px;
    margin-top: 78px;
    margin-top: 82px;
    margin-top: 67px;
    margin-top: 69px;
    //margin-top: 54px;

    margin-top: 32px;

    //margin-top: 108px;




    .skills {
        transform: scale(.965);
        transform-origin: top left;
        margin-top: 6px;
        border-top: 0px solid $borderColor;
    }
}

.SkillItem.Openness {
    display: none;
}

.SkillItem:first-child {
    //border-top: 2px solid $borderColor;
}

.SkillItem {
    display: flex;

    //background-color: #fff;
    border-bottom: 1.5px solid $borderColor;
    // border-left: 2px solid $borderColor;
    // border-right: 2px solid $borderColor;
    //border-radius: 50px;
    margin: 0 18px 0 18px;

    padding: 7.5px 8px 7.5px 8px;

    .icon {
        //opacity: 0;

        //background-color: #fff;
        border-radius: 6px;
        width: 26px;
        height: 24px;
        text-align: center;

        font-size: 10.5pt;
        font-weight: 700;
        color: $negativeColor;
        color: lighten(#1797cb, 8%);
        color: $positiveAccentColor;
        //color: lighten(#fe4180, 14%);

        padding: 6px 0 0 0;
        box-sizing: border-box;

        transform: scale(0.95);

        margin: -4px 2px 0 -2px;
        //border: 1.5px solid lighten(#ffa542, 28%);
        //box-shadow: 0.5px 0.5px 2px 0px darken(#e3e6f7, 4%);
        // box-shadow: none;
        // border: 2px solid darken($backgroundColor, 6%);
        // box-sizing: border-box;



        .icon-img {
            width: 18px;
        }
    }

    .label {
        padding-top: 1px;
        padding-left: 2px;
        font-size: 9.75pt;
        font-weight: 700;
        color: darken(#ff8d0d, 10%);
        color: darken(#ffa542, 2%);
        text-align: left;
    }

    .percentage {
        //margin-top: -.5px;
        margin-left: auto;
        padding-right: 4px;
        font-size: 10.25pt;
        font-weight: 500;

        text-align: left;

        letter-spacing: 0.5px;
    }

    .add-floater-position {
        opacity: 0;
        position: relative;
        transition: .35s;
        transform: scale(1.5);

        .add-floater {
            position: absolute;
            top: -4px;
            left: -96px;
            width: 36px;
            height: 28px;
            border-radius: 8px;
            transition: .35s;

            color: #0fcbed;

            font-size: 10.25pt;
            font-weight: 700;
            top: 0px;
        }
    }
}

.SkillItem.IsIncreasing {
    .add-floater-position {
        opacity: 1;
        transform: scale(1.2);

        .add-floater {
            left: -76px;
        }
    }
}

.SkillItem.IsFading {
    .add-floater-position {
        opacity: 0;
        transform: scale(1.2);

        .add-floater {
            left: -36px;
        }
    }
}

.SkillItem.None {
    .percentage {
        color: #ddd;
    }
}

.SkillItem.Low {
    .percentage {
        color: lighten(#ebab00, 8%);
    }
}

.SkillItem.Mid {
    .percentage {
        color: lighten(#0ebfdf, 3%);
        color: lighten(#ebab00, 8%);
    }
}

.SkillItem.High {
    .percentage {
        color: lighten(#16c797, 3%);
        //font-weight: 500;
    }
}